import React, { useMemo, useState } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { Form, Select, Input, Button } from "antd";
import { addCounsellor } from "redux/actions/counsellor_actions";
import { useDispatch } from "react-redux";
import ImageUploader from "components/ImageUploader/ImageUploader";
import countryLists from "react-select-country-list";
import { countryList } from "utils/CountryList";
const AddCounsellor = (props) => {
  const [loading, setLoading] = useState(false);
  const { handleAddCounsellor, isAddCounsellorOpen, getFireStoreCounselor } =
    props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  //const options = useMemo(() => countryList().getData(), []);
 // console.log("options", options);
  const onFinish = async (values) => {
    setLoading(true);
    let data = { ...values, createdAt: new Date() };
    let user = await dispatch(addCounsellor(data));
    if (user) {
      setLoading(false);
      handleAddCounsellor(false);
      getFireStoreCounselor();
    } else {
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  const toggle = () => {
    handleAddCounsellor(false);
  };
  return (
    <>
      <Modal
        isOpen={isAddCounsellorOpen}
        toggle={toggle}
        className="popup-modal"
      >
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Add Counsellor</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  label="Name"
                  className="form-control-label"
                  name="name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                >
                  <Input placeholder="Counsellor Name..." />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter counsellor email",
                    },
                  ]}
                >
                  <Input placeholder="Counsellor email...." />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter counsellor phone numnber",
                    },
                  ]}
                >
                  <Input placeholder="Counsellor phone number" />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter counsellor Password",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Counsellor password..."
                  />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Nationality"
                  name="nationality"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter counsellor nationality",
                    },
                  ]}
                >
                  <Select placeholder="Counsellor nationality" showSearch>
                    {countryList &&
                      countryList?.map((item, i) => {
                        return (
                          <Option value={item.value} key={i}>
                            {item.value}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Types"
                  name="type"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter counsellor type",
                    },
                  ]}
                >
                  <Input placeholder="Counsellor type.." />
                </Form.Item>
              </Col>
              <Col lg="12">
                <Form.Item
                  className="form-control-label"
                  name="coverage"
                  label="Country Coverage"
                  rules={[
                    {
                      required: true,
                      message: "Please select counsellor coverage",
                      type: "array",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select recruiting companies"
                    className="custom-drop-down"
                    showSearch
                  >
                    {countryList &&
                      countryList?.map((item, i) => {
                        return (
                          <Select.Option value={item.value} key={i}>
                            {item.value}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  name="coverage"
                  label="Country Coverage"
                  rules={[
                    {
                      required: true,
                      message: "Please select counsellor coverage",
                      type: "string",
                    },
                  ]}
                >
                  <Select placeholder="Counsellor coverage">
                    <Option value="nepal">Nepal</Option>
                    <Option value="india">India</Option>
                    <Option value="bangladesh">Bangladesh</Option>
                  </Select>
                </Form.Item>
              </Col> */}

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Upload Image"
                  name="imageURL"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please upload the counsellor image",
                    },
                  ]}
                >
                  <ImageUploader
                    form={form}
                    formField="imageURL"
                    folder="counsellor"
                  />
                </Form.Item>
              </Col>
              <Col lg="12" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Description"
                  name="desc"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "please enter counsellor description",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Counsellor description..."
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="primary" loading={loading} onClick={onSubmit}>
            Add Counsellor
          </Button>
          <Button type="secondary" onClick={() => handleAddCounsellor(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default React.memo(AddCounsellor);
