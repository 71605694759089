import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";
import { Form, Input, Button, Select } from "antd";
import {
  addProgram,
  getLevels,
  getStreams,
} from "redux/actions/courses_action";
import TextEditor from "../../components/TextEditor";

const AddCourse = ({ fetchCourses, isAddModalOpen, setIsAddModalOpen }) => {
  const [loading, setLoading] = useState(false);

  const [fetchedLevels, setFetchedLevels] = useState([]); //original data
  const [fetchedStreams, setFetchedStreams] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;

  const fetchLevels = async () => {
    let data = await dispatch(getLevels());
    setFetchedLevels(data);
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  const onSelectLevel = async (level) => {
    form.setFieldsValue({ coursestream: null });
    let data = await dispatch(getStreams(level));
    setFetchedStreams(data);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let response = await dispatch(addProgram(values));
    if (response) {
      form.resetFields();
      setIsAddModalOpen(false);
      fetchCourses && fetchCourses();
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  function makeData(){
    let newLabel = []
    fetchedLevels.map((l) =>{
      if(l.name == "diploma"){
        newLabel[0] = l;
      }
      else if(l.name == "undergraduate"){
        newLabel[1] = l;
      }
      else if(l.name == "postgraduate"){
        newLabel[2] = l;
      }
      else if(l.name == "phd"){
        newLabel[3] = l;
      }
    })
    let labels = newLabel.map((l) => {
      return (
        <Option
          key={l._id}
          value={l._id}
          style={{ textTransform: "capitalize" }}
        >
          <div style={{ textTransform: "capitalize" }}>
            {l.name === "postgraduate"
              ? "Post Graduate"
              : l.name === "undergraduate"
              ? "under graduate"
              : l.name}
          </div>
        </Option>
      );
    })
    return labels
  }
  return (
    <div>
      <Modal
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(false)}
        className="popup-modal"
      >
        <ModalHeader
          toggle={() => setIsAddModalOpen(false)}
          className="modal--header"
        >
          <div className="modal--title">Add Program</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: "relative" }}>
                <Form.Item
                  className="form-control-label"
                  label="Level"
                  name="courselevel"
                  rules={[
                    {
                      required: true,
                      message: "Level is required",
                    },
                  ]}
                >
                  
                  <Select 
                  onSelect={onSelectLevel} 
                  placeholder="Select Level" 
                  getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }>
                    {makeData()}
                  </Select>
                 
                </Form.Item>
                </div>
              </Col>
              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: "relative" }}>
                <Form.Item
                  className="form-control-label"
                  label="Stream"
                  name="coursestream"
                  rules={[
                    {
                      required: true,
                      message: "Stream is required",
                    },
                  ]}
                >
                 
                  <Select placeholder="Select Stream" getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }>
                    {fetchedStreams?.sort(dynamicSort("name")).map((l) => {
                      return (
                        <Option
                          key={l._id}
                          value={l._id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
               
                </Form.Item>
                </div>
              </Col>

              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Program"
                  name="name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Program is required",
                    },
                  ]}
                >
                  <Input placeholder="BBA" />
                </Form.Item>
              </Col>

              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Program Code"
                  name="program_code"
                  // rules={[
                  //   {
                  //     type: "string",
                  //     // required: true,
                  //     message: "Program is required",
                  //   },
                  // ]}
                >
                  <Input placeholder="Program code" />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: "relative" }}>
                <Form.Item
                  className="form-control-label"
                  label="Duration"
                  name="duration"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "duration is required",
                    },
                  ]}
                >
                 
                  <Select placeholder="Select course duration"  getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }>
                    <Option value="6 Months">6 Months</Option>
                    <Option value="1 Year">1 Year</Option>
                    <Option value="2 Year">2 Years</Option>
                    <Option value="3 Year">3 Years</Option>
                    <Option value="4 Year">4 Years</Option>
                    <Option value="5 Year">5 Years</Option>
                    <Option value="6 Year">6 Years</Option>
                  </Select>
                
                </Form.Item>
                </div>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Exam Type"
                  name="exam_type"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Exam Type is required",
                    },
                  ]}
                >
                  <Select placeholder="Select exam type">
                    <Option value="Semester">Semester</Option>
                    <Option value="Year">Year</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Average Salary"
                  name="average_salary"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Average Salary is required",
                    },
                  ]}
                >
                  <Input placeholder="INR 5 To 2 Lacs Per Annum" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Selection Process"
                  name="selection_process"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Selection Process is required",
                    },
                  ]}
                >
                  <Input placeholder="Entrance Exam" />
                </Form.Item>
              </Col>

              {/* <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Minimum Score"
                  name="minimum_score"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Minimum Score is required",
                    },
                  ]}
                >
                  <Input placeholder="55%" />
                </Form.Item>
              </Col> */}

              <Col lg="12">
                <div style={{ height: "295px" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Overview"
                    name="overview"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Overview is required",
                      },
                    ]}
                  >
                    <TextEditor
                      onChange={(html) =>
                        form.setFieldsValue({
                          overview: html,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col lg="12">
                <div style={{ height: "295px" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Course Outline"
                    name="course_outline"
                    // rules={[
                    //   {
                    //     type: "string",
                    //     required: true,
                    //     message: "Overview is required",
                    //   },
                    // ]}
                  >
                    <TextEditor
                      onChange={(html) =>
                        form.setFieldsValue({
                          course_outline: html,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col lg="12">
                <div style={{ height: "295px" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Career Options"
                    name="carrer_options"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Career options is required",
                      },
                    ]}
                  >
                    <TextEditor
                      onChange={(html) =>
                        form.setFieldsValue({
                          carrer_options: html,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button loading={loading} type="primary" onClick={onSubmit}>
            Add Program
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCourse;
