import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";
import { Form, Input, Button, message } from "antd";

import { editCollege } from "redux/actions/college_actions";

const AdmissionProcess = ({ setIsAdmissionComplete }) => {
  const college = useSelector((state) => state.college.college);
  const [loader, setLoader] = React.useState(false);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoader(true);
    let _college = {};
    _college.id = college._id;
    _college.isAdmissionCompleted = true; //required in order to show college completed status in frontend and admin panel as well
    _college.addmission_process = values;

    await dispatch(editCollege(_college, college.college_slug));
    setLoader(false);
  };

  useEffect(() => {
    const { addmission_process } = college;
    college &&
      form.setFieldsValue({
        title: addmission_process?.title,
        start_date: college?.addmission_process?.start_date,

        description: addmission_process?.description,
      });
  }, [college, form]);

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
    message.error("Failed to save. please check missing field");
  };

  const onSubmit = () => {
    form.submit();
  };
  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Admission Process</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    label="Title"
                    className="form-control-label"
                    name="title"
                    rules={[
                      {
                        type: "string",

                        message: "Please write admission process title",
                      },
                    ]}
                  >
                    <Input placeholder="Admission process title" />
                  </Form.Item>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    label="Start Date and End Date"
                    className="form-control-label"
                    name="start_date"
                    rules={[
                      {
                        // type: "object",
                        required: true,
                        message: "Please select admission start date",
                      },
                    ]}
                  >
                    <Input placeholder="course starting date / end date" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="description"
                    label="Description"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please input admission description.",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Admission Description"
                      autoSize={{ minRows: 5, maxRows: 10 }}
                    />
                  </Form.Item>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button type="primary" onClick={onSubmit} loading={loader}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AdmissionProcess;
