import React from "react";
import TeamMemberDetails from "./TeamMemberDetails";

// core components
import Header from "components/Headers/Header.js";
const Counsellor = () => {
  return (
    <>
      <Header />
      <TeamMemberDetails />
    </>
  );
};

export default Counsellor;
