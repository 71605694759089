import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editCollege } from "redux/actions/college_actions";
import ConfirmationBox from "components/ConfirmationBox";
import moment from "moment";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Form, Input, Select, InputNumber, Space, Button } from "antd";
import { getRank } from "redux/actions/rank_action";
import { getUniversityRank } from "redux/actions/university_rank_action";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const UniversityRanking = ({ setIsRankComplete }) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [rankAuthorites, setRankAuthorites] = useState([]);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);
  const rank = useSelector((state) => state.universityrank.universityranks);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    values.id = college._id;

    await dispatch(editCollege(values, college.college_slug));
    setLoading(false);
  };

  const fetchRank = async () => {
    await dispatch(getUniversityRank());
  };

  React.useEffect(() => {
    fetchRank();
  }, []);

  React.useEffect(() => {
    if (rank.length) {
      setRankAuthorites(
        rank.map((item) => ({
          rank: item?._id,
          label: item?.title,
          value: item?._id,
        }))
      );
    }
  }, [rank]);

  useEffect(() => {
    const { rankings } = college;
    let api_ranking = rankings?.map((item) => ({
      rank: item.rank?._id,
      label: item.rank?.title,
      value: item.rank?._id,
      values: item?.values,
    }));
    form.setFieldsValue({
      rankings: api_ranking,
    });
  }, [college, form]);

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">University Ranking</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.List name="rankings">
              {(fields, { add: addRank, remove: removeRank }) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.key}>
                      <Col lg="6">
                        <div id="testPosition" style={{ position: "relative" }}>
                          <Form.Item
                            {...field}
                            label="Title"
                            name={[field.name, "rank"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing your title",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={() =>
                                document.getElementById("testPosition")
                              }
                              placeholder="Select"
                              showSearch
                              // style={{ width: 200 }}
                              optionFilterProp="children"
                              options={rankAuthorites}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg="5">
                        <Form.Item
                          {...field}
                          name={[field.name, "values"]}
                          label="Values"
                          fieldKey={[field.fieldKey, "values"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing values",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter values"
                            onChange={()=>setCount (count+1)
                            }
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg="1">
                        <MinusCircleOutlined
                          onClick={() => {
                            setCount((prev) => prev - 1);
                            setConformObj({
                              title: `Are you sure you want to delete ?`,
                              msg: "This process cannot be undone.",
                              onSuccess: () => {
                                removeRank(field.name);
                                setIsConformModal(false);
                              },
                            });
                            setIsConformModal(true);
                          }}
                        />
                      </Col>
                    </Row>
                    // </Space>
                  ))}
                  <Row>
                    <Col lg="12">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            addRank();
                            setCount((prev) => prev + 1);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add University Rank
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container">
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
               disabled={count === 0}
            >
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UniversityRanking;
