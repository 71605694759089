import { GET_RANK_TYPES } from "../types";
import { RankService } from "redux/api/rank_api";
import { message } from "antd";
import { storage } from "../../firebase";

const rankService = new RankService();
export const addRank = (data) => async (dispatch) => {
  const res = await rankService.addRank(data);
  if (res.isSuccess) {
    message.success("Rank Added");
    return "Succesfully added";
  } else {
    message.error("Failed to add rank");
    return res.data;
  }
};

export const getRank = () => async (dispatch) => {
  dispatch({
    type: GET_RANK_TYPES.RANK_BEGIN,
  });
  const res = await rankService.getRank();

  if (res.isSuccess) {
    dispatch({
      type: GET_RANK_TYPES.GET_RANK,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_RANK_TYPES.RANK_END,
    });
    return res.errorMessage;
  }
};

export const deleteRank = (id, image) => async (dispatch) => {
  try {
    const res = await rankService.deleteRank(id);
    if (res.isSuccess) {
      await storage.refFromURL(image).delete();
      message.success("Rank deleted");
    } else {
      message.error("Failed to delete");
    }
  } catch (error) {
    // console.log(error);
    message.error("Server error");
  }
};

