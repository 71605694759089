import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Form, Input, Modal, Button } from "antd";
import ConfirmationBox from "components/ConfirmationBox";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { addUniversityRank } from "redux/actions/university_rank_action";

const UniversityRank = ({ addModalOpen, setAddModalOpen, fetchUniversityRanking }) => {
  const [addCount, setAddCount] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [uploadedRankLogos, setUploadedRankLogos] = useState([]);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);

    await dispatch(addUniversityRank(values.rank));
    fetchUniversityRanking();
    setAddCount(0);
    form.resetFields();
    setLoading(false);
    toggle();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    const { rank } = fields;

    if (rank.length) {
      uploadedRankLogos.forEach((logo) => {
        Object.assign(rank[logo.index], {
          ["logo"]: logo.logo,
        });
      });
      form.setFieldsValue({ rank });
      form.submit();
    }
  };

  const saveLogoUrl = (url) => {
    setUploadedRankLogos([...uploadedRankLogos, url]);
  };

  const removeLogoUrl = (index) => {
    setUploadedRankLogos(uploadedRankLogos.filter((u) => u.index !== index));
  };

  const toggle = () => {
    setAddModalOpen(false);
  };

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Modal
        visible={addModalOpen}
        onCancel={toggle}
        // className="popup-modal"
        footer={null}
        title="Add University Rank"
      >
        <div className="mt-4 mb-4">
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.List name="rank">
              {(fields, { add: addRank, remove: removeRank }) => (
                <div key={fields.index}>
                  {fields.map((field, i) => {
                    return (
                      <Row key={i}>
                        <Col lg="6">
                          <Form.Item
                            {...field}
                            name={[field.name, "title"]}
                            label="Title"
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing your title",
                              },
                            ]}
                          >
                            <Input placeholder="title" />
                          </Form.Item>
                        </Col>
                        <Col lg="3">
                          <Form.Item
                            {...field}
                            name={[field.name, "logo"]}
                            label="Logo"
                            fieldKey={[field.fieldKey, "logo"]}
                            rules={[
                              {
                                required: true,
                                message:
                                  "please upload image ranking_authority_logo",
                              },
                            ]}
                          >
                            <ImageUploader
                              form={form}
                              isInFormList={true} //on dynamic form
                              fieldKey={field.name} //on dynamic form
                              saveUrl={saveLogoUrl} //on dynamic form
                              removeUrl={removeLogoUrl} //on dynamic form
                              formField={`logo`}
                              folder="college-ranking-authorities"
                              url={
                                form?.getFieldsValue()?.rank[field.key]?.logo
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col lg="3">
                          <MinusCircleOutlined
                            onClick={() => {
                              setIsConformModal(true);
                              setConformObj({
                                title: `Are you sure you want to delete?`,
                                msg: "This process cannot be undone.",
                                onSuccess: () => {
                                  setAddCount((prev) => prev - 1);
                                  removeRank(field.name);
                                  removeLogoUrl(field.name);

                                  setIsConformModal(false);
                                },
                              });
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col lg="12">
                      <Button
                        type="dashed"
                        onClick={() => {
                          addRank();
                          setAddCount((prev) => prev + 1);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add University Rank
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container" style={{ marginTop: "30px" }}>
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              disabled={addCount === 0}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UniversityRank;
