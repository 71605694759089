import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  getCounsellorData,
  deleteCounsellor,
} from "redux/actions/counsellor_actions";
import { Card, CardHeader, Container, Row } from "reactstrap";
import ConfirmationBox from "components/ConfirmationBox";
import { Table as AntdTable, Input, Button } from "antd";
import AddCounsellor from "./AddCounsellor";
import EditCounsellor from "./EditCounsellor";

const CounsellorDetails = () => {
  const dispatch = useDispatch();
  const [counsellorArray, setCounsellorArray] = React.useState([]);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [counsellor, setCounsellor] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [counsellorId, setCounsellorId] = React.useState("");
  const [isAddCounsellorOpen, setisAddCounsellorOpen] = React.useState(false);
  const [isEditCounsellorOpen, setisEditCounsellorOpen] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const getFireStoreCounselor = async () => {
    setLoading(true);

    let counsellors = await dispatch(getCounsellorData());
    console.log('counsellors',counsellors)
    setCounsellorArray([...counsellors]);
    setLoading(false);
  };
  React.useEffect(() => {
    getFireStoreCounselor();
  }, []);

  const handleAddCounsellor = (res) => {
    setisAddCounsellorOpen(res);
  };

  const handleEditCounsellor = async (res, id) => {
    setCounsellorId(id);
    setisEditCounsellorOpen(res);
  };

  const onDelete = async (id, image) => {
    setIsConformModal(true);

    let isDeleted = await dispatch(deleteCounsellor(id, image));
    if (isDeleted) {
      getFireStoreCounselor();
    }
  };

  const getNameWithImage = () => ({
    render: (counsellor) => {
      return (
        <>
          <div className="image-container-with-name">
            <div className="image-container">
              <img alt="..." src={counsellor.imageURL} />
            </div>
            <div className="title-meta">
              <span className="mb-0 tecounsellorIdt-sm">{counsellor.name}</span>
            </div>
          </div>
        </>
      );
    },
  });

  const columns = React.useMemo(() => [
    {
      title: "Name",
      dataIndex: "",
      width: "15%",
      ...getNameWithImage(""),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Coverage",
      dataIndex: "coverage",
      width: "35%",
    },
    {
      title: "Description",
      dataIndex: "desc",
      width: "20%",
      render: (desc) => `${desc.substring(0, 20)}...`,
    },
    {
      title: "Action",
      dataIndex: "",
      width: "8%",
      align: "right",
      render: (counsellor) => (
        <div className="text-right site-button-ghost-wrapper">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setCounsellorId(counsellor.id);
              setisEditCounsellorOpen(true);
            }}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => {
              setIsConformModal(true);
              setConformObj({
                title: `Are you sure you want to delete ${counsellor?.name}?`,
                msg: "This process cannot be undone.",
                onSuccess: async () => {
                  setDeleteLoader(true);
                  await dispatch(
                    deleteCounsellor(counsellor.id, counsellor.imageURL)
                  );
                  getFireStoreCounselor();
                  setIsConformModal(false);
                  setDeleteLoader(false);
                },
              });
            }}
          />
        </div>
      ),
    },
  ]);

  const FilteredCounselor = counsellorArray
    ?.sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds)
    .filter((counsellor) => {
      return (
        counsellor?.name.toLowerCase().includes(searchField) ||
        counsellor?.email.toLowerCase().includes(searchField) ||
        counsellor?.coverage.toLowerCase().includes(searchField)
      );
    });

  return (
    <Container className="mt--7" fluid>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
        loader={deleteLoader}
      />
      <AddCounsellor
        getFireStoreCounselor={getFireStoreCounselor}
        isAddCounsellorOpen={isAddCounsellorOpen}
        handleAddCounsellor={handleAddCounsellor}
      />

      <EditCounsellor
        isEditCounsellorOpen={isEditCounsellorOpen}
        getFireStoreCounselor={getFireStoreCounselor}
        id={counsellorId}
        handleEditCounsellor={handleEditCounsellor}
      />

      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader>
              <div className="card-header-content">
                <div className="card-title-content">
                  <Input.Search
                    placeholder="Search by name, email and coverage"
                    enterButton
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
                <Button
                  onClick={() => handleAddCounsellor(true)}
                  type="primary"
                >
                  Add
                </Button>
              </div>
            </CardHeader>

            <AntdTable
              columns={columns}
              rowKey={(record) => {
                return record.id;
              }}
              // className="table-striped-rows"
              dataSource={FilteredCounselor}
              pagination={pagination}
              loading={loading}
              // onChange={handleTableChange}
              size="small"
              stripes="even"
              scroll={{ x: true }}
            />
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default CounsellorDetails;
