import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Checkbox, Button } from "antd";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { editCollege } from "redux/actions/college_actions";
// import { getCollegeFacilities } from "redux/actions/college_actions";
import { getFacilities } from "redux/actions/facilities_action";

const Facilities = ({ setIsFacilitiesComplete }) => {
  const [options, setOptions] = useState([]);
  const [facilityArray, setFacilityArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);
  const facilities = useSelector((state) => state.facilities.facilities);
  const [form] = Form.useForm();

  const fetchFacilities = async () => {
    await dispatch(getFacilities());
  };
  React.useEffect(() => {
    fetchFacilities();
  }, []);

  React.useEffect(() => {
    let _facilityArray = [];
    if (facilities.length) {
      facilities.forEach((item) => {
        _facilityArray.push({
          id: item._id,
          label: item.title,
          values: item.logo,
        });
      });
      setFacilityArray(_facilityArray);
      setOptions(facilities.map((f) => f.title));
    }
  }, [facilities]);

  useEffect(() => {
    const { facilities } = college;

    form.setFieldsValue({
      facilities: facilities?.map((f) => f.title),
    });
  }, [college, form]);


  const onFinish = async (values) => {
    setLoading(true);
    let _facilities = [];
    let _college = {};
    _college.id = college?._id;

    facilityArray.forEach((facility) => {
      values.facilities.filter((f) =>
        f === facility.label ? _facilities.push(facility.id) : ""
      );
    });

    _college.facilities = _facilities;
    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Facilities</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="pl-lg-4">
              <Row>
                <label className="form-control-label">
                  Select Facilities provided by college
                </label>
              </Row>
              <Row>
                <Col lg="12">
                  <Form.Item
                    name="facilities"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Checkbox.Group options={options} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
          <div className="form-btn-container">
            <Button disabled={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Facilities;
