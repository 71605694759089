import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationBox from "components/ConfirmationBox";
import { Row, Col } from "reactstrap";
import {
  addScholarship,
  getScholarship,
} from "redux/actions/scholarship_actions";
import { Form, Input, Button, Space, message } from "antd";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const AddScholarship = () => {
  const [scholarshipLogo, setScholarshipLogo] = useState([]);
  const [scholarshipsCount, setScholarshipCount] = useState(0);
  const [loader, setLoader] = React.useState(false);

  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoader(true);

    await dispatch(addScholarship(values.scholarships));
    await dispatch(getScholarship());
    setLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    let { scholarships } = fields;
    if (scholarships && scholarships.length) {
      scholarshipLogo.forEach((icon) => {
        Object.assign(scholarships[icon.index], {
          ["logo"]: icon.logo,
        });
      });
      form.setFieldsValue({ scholarships });
      form.submit();
    } else {
      message.error("Please check fields unable to add");
    }
  };

  const saveLogoUrl = (url) => {
    setScholarshipLogo([...scholarshipLogo, url]);
  };

  const removeLogoUrl = (index) => {
    setScholarshipLogo(scholarshipLogo.filter((u) => u.index !== index));
  };
  return (
    <div className="mt-5">
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div>
          <Form.List name="scholarships">
            {(fields, { add, remove }) => (
              <Row key={fields.index}>
                {fields.map((field, i) => {
                  return (
                    <Space key={i} align="baseline">
                      <Col lg="12">
                        <label className="form-control-label">Title</label>
                        <Form.Item
                          {...field}
                          name={[field.name, "title"]}
                          fieldKey={[field.fieldKey, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "Title name cannot be empty",
                            },
                          ]}
                        >
                          <Input placeholder="Title Name" />
                        </Form.Item>
                      </Col>
                      <Col lg="12">
                        <label className="form-control-label">Logo</label>
                        <Form.Item
                          {...field}
                          name={[field.name, "logo"]}
                          fieldKey={[field.fieldKey, "logo"]}
                          rules={[
                            {
                              required: true,
                              message: "please upload image logo",
                            },
                          ]}
                        >
                          <ImageUploader
                            form={form}
                            isInFormList={true} //on dynamic form
                            fieldKey={field.name} //on dynamic form
                            saveUrl={saveLogoUrl} //on dynamic form
                            removeUrl={removeLogoUrl} //on dynamic form
                            formField={`logo`}
                            folder="scholarships"
                            url={
                              form.getFieldsValue()?.scholarships[field.key]
                                ?.logo
                            }
                          />
                        </Form.Item>
                      </Col>

                      <MinusCircleOutlined
                        onClick={() => {
                          setConformObj({
                            title: `Are you sure you want to delete ?`,
                            msg: "This process cannot be undone.",
                            onSuccess: () => {
                              remove(field.name);
                              removeLogoUrl(field.name);
                              setScholarshipCount((prev) => prev - 1);
                              setIsConformModal(false);
                            },
                          });
                          setIsConformModal(true);
                        }}
                      />
                    </Space>
                  );
                })}
                <Col lg="12">
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        setScholarshipCount((prev) => prev + 1);
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Scholarship
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.List>
        </div>
      </Form>
      <div className="form-btn-container">
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={scholarshipsCount === 0}
          loading={loader}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddScholarship;
