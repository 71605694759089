import { GET_UNIVERSITY_RANKS } from "../types";
import { UniversityRankService } from "redux/api/universityrank_api";
import { message } from "antd";
import { storage } from "../../firebase";



const universityRankService = new UniversityRankService();


export const addUniversityRank = (data) => async (dispatch) => {
  const res = await universityRankService.addUniversityRank(data);
  if (res.isSuccess) {
    message.success("University Rank is Added");
    return "Succesfully added";
  } else {
    message.error("Failed to University add rank");
    return res.data;
  }
};


export const getUniversityRank = () => async (dispatch) => {
  dispatch({
    type: GET_UNIVERSITY_RANKS.UNIVERSITY_RANK_BEGIN,
  });
  const res = await universityRankService.getUniversityRank();   
  if (res.isSuccess) {
    dispatch({
      type: GET_UNIVERSITY_RANKS.UNIVERSITY_GET_RANK,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_UNIVERSITY_RANKS.UNIVERSITY_RANK_END,
    });
    message.error(res.errorMessage);
  }
};


export const deleteUniversityRank = (id, image) => async (dispatch) => {
  try {
    const res = await universityRankService.deleteUniversityRank(id);
    if (res.isSuccess) {
      await storage.refFromURL(image).delete();
      message.success("University Rank deleted");
    } else {
      message.error("Failed to delete");
    }
  } catch (error) {
    message.error("Server error");
  }
};

