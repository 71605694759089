import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button,Image } from "antd";
import { MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { Card, CardHeader, CardBody, Row, Col, ModalFooter } from "reactstrap";
import ConfirmationBox from "components/ConfirmationBox";
import { getGallery } from "redux/actions/gallery_action";
import { addGallery } from "redux/actions/gallery_action";
import FileUpload from "components/ImageUploader/FileUpload";
import { storage } from "../../../firebase";

const Gallery = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const college = useSelector((state) => state.college.college);
  const gallery = useSelector((state) => state.gallery.gallery);
  const [uploadedCollab, setUploadedCollab] = useState([]);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const imageArray = gallery?.images || [];
    const galleryImages = imageArray?.map((item,index) => {
        return {"index":index,"logo":item}
    })
    form.setFieldsValue({
      image: galleryImages,
    });
  }, [gallery, form]);

  useEffect(() => {
    dispatch(getGallery(college._id));
  }, [college,reload]);

  const saveLogoUrl = (url) => {
    setUploadedCollab([...uploadedCollab, url]);
  };

  const removeLogoUrl = (index) => {
    setUploadedCollab(uploadedCollab.filter((u) => u.index !== index));
  };

  const deleteImage=async (url)=>{
    const desertRef = storage.refFromURL(url);
    await desertRef
      .delete()
      .then(async() => {
       
      })
      .catch((error) => {
        return false;
      });
      let images= gallery.images.filter((item) =>url !== item);
      await dispatch(addGallery({college_id:gallery.college_id,images},true));
      setReload(!reload);
  }

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Gallery</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
            <div className="pl-lg-4">
             {
              gallery?.images?.map(img=>{
               return (
                <>
                  <Image
                    width={120}
                    height={120}
                    src={img}
                    style={{ marginRight:'10px' }}
                   />
                   <MinusCircleOutlined
                    onClick={() => {
                      setCount((prev) => prev - 1);
                      setConformObj({
                        title: "Are you sure you want to delete ?",
                        msg: "This process cannot be undone.",
                        onSuccess: () => {
                          deleteImage(img)
                          setIsConformModal(false);
                        },
                      });
                      setIsConformModal(true);
                    }}
                  />
                    </>
               )
              })
             }
              <Col lg="12">
                <FileUpload 
                  form={form}
                  isInFormList={true} //on dynamic form
                  saveUrl={saveLogoUrl} //on dynamic form
                  removeUrl={removeLogoUrl} //on dynamic form
                  formField={`logo`}
                  folder="international-collab"
                  setReload={setReload}
                  reload={reload}
                  gallery={gallery}
                  collegeId={college._id}
                />
              </Col>
            </div>
        </CardBody>
      </Card>
    </>
  );
};
export default Gallery;