import React from "react";
import { auth, db } from "../../firebase";
import moment from "moment";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Button, Input } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import AppointmentUiCard from "./AppointmentUiCard";
import SendMailModal from "./SendMailModal";

const AppointmentDetails = (props) => {
  const [appointmentArray, setAppointmentArray] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [uiCardLoader, setUiCardLoader] = React.useState(false);
  const [selectedStudents, setSelecetedStudents] = React.useState([]);
  const [counsellorInfo, setCounsellorInfo] = React.useState(null);
  const [selectedAppointmentDetail, setSelectedAppointmentDetail] =
    React.useState([]);
  const [isSendMailModalOpen, setIsSendMailModalOpen] = React.useState(false);
  const [isAppointmentModalOpen, setisAppointmentModalOpen] =
    React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });
  const getCounsellor = async (id) => {
    
    return await db
      .collection("counsellor")
      .doc(id)
      //  .orderBy("createdAt", "desc")
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          return doc.data();
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
          return null;
        }
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
        return null;
      });
  };

  const getFireStoreAppointment = async () => {
    const appointment = [];
    let query = db.collection("appointment");
    if (auth.currentUser.isCounsellor) {
      query = query.where("counsellor", "==", auth.currentUser.uid);
    }
    let querySnapshot = await query.orderBy("createdAt","desc").get();
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      appointment.push({
        id: doc.id,
        contact_medium: data.contact_medium,
        contact_id: data.contact_id,
        counsellor: data.counsellor,
        country_code: data.country_code,
        createdAt: data.createdAt,
        course: data.course,
        date: data.date,
        description: data.description,
        email: data.email,
        home_country: data.home_country,
        name: data.name,
        phone: data.phone,
        time: data.time,
      });
    });

    setAppointmentArray([...appointment]);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreAppointment();
  }, []);

  const filterAppointmentDetails = async (id, counsellor_id) => {
    setUiCardLoader(true);
    const counsellor = await getCounsellor(counsellor_id);

    const AppointmentDetail = appointmentArray.filter(
      (appointmentArray) => appointmentArray.id === id
    );
    setCounsellorInfo(counsellor);
    setSelectedAppointmentDetail([...AppointmentDetail]);
    setUiCardLoader(false);
  };

  const columns = React.useMemo(() => [
    {
      title: "Time",
      render: (appointment) =>
        `${moment.unix(appointment.date.seconds).format("DD MMM YYYY")} ${
          appointment.time
        }`,
      width: "15%",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Country",
      dataIndex: "home_country",
      width: "15%",
    },
    {
      title: "Contact Number",
      dataIndex: "phone",
      width: "15%",
    },
    {
      title: "Course",
      dataIndex: "course",
      width: "10%",
    },
    {
      title: "Action",
      align: "right",
      dataIndex: "",
      width: "8%",
      render: (appointment) => (
        <div
          className="text-right site-button-ghost-wrapper"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => {
              filterAppointmentDetails(appointment.id, appointment.counsellor);
              setisAppointmentModalOpen(true);
            }}
          />
        </div>
      ),
    },
  ]);

  const rowSelection = {
    onChange: (selectedRows) => {
      let selectedEmail = [];
      selectedRows.forEach((id) => {
        appointmentArray.forEach((item) => {
          if (item.id === id) {
            selectedEmail.push(item.email);
          }
        });
      });

      setSelecetedStudents(selectedEmail);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const filteredData = appointmentArray
    ?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
    .filter((item) => {
      return (
        item?.email.toLowerCase().includes(searchField.toLowerCase()) 
      );
    });

  return (
    <>
      <Container className="mt--7" fluid>
        <SendMailModal
          selectedStudents={selectedStudents}
          isSendMailModalOpen={isSendMailModalOpen}
          setIsSendMailModalOpen={setIsSendMailModalOpen}
          appointmentArray={appointmentArray}
        />
        <AppointmentUiCard
          isAppointmentModalOpen={isAppointmentModalOpen}
          counsellorInfo={counsellorInfo}
          setisAppointmentModalOpen={setisAppointmentModalOpen}
          data={selectedAppointmentDetail}
          loader={uiCardLoader}
          setIsSendMailModalOpen={setIsSendMailModalOpen}
          appointmentArray={appointmentArray}

        />
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    {" "}
                    <Input.Search
                      placeholder="Search by name, email, country and course"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                  {/* {!!appointmentArray.length && (
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsSendMailModalOpen(true);
                      }}
                    >
                      <span className="btn-inner--icon">Send mail</span>
                    </Button>
                  )} */}
                </div>
              </CardHeader>

              <AntdTable
                rowSelection={
                  appointmentArray.length
                    ? {
                        type: "checkbox",
                        ...rowSelection,
                      }
                    : null
                }
                // className="align-items-center table-flush"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={filteredData}
                pagination={pagination}
                loading={loading}
                size="small"
                sticky
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AppointmentDetails;
