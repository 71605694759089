import { GET_STREAM } from "../types";
import { StreamService } from "redux/api/stream_api";
import { message } from "antd";

const streamService = new StreamService();

export const addStream = (data) => async (dispatch) => {
  const res = await streamService.addStream(data);
  if (res.isSuccess) {
    message.success("Stream is Added");
    return "Succesfully added";
  } else {
    message.error("Failed to add stream");
    return res.data;
  }
};

export const getStream = () => async (dispatch) => {
    dispatch({
        type:GET_STREAM.STREAM_BEGIN,
    });
    const res = await streamService.getStream();
    if(res.isSuccess){
      dispatch({
        type:GET_STREAM.STREAM_GET,
        payload:res.data,

      });
    } else {
      dispatch({
        type:GET_STREAM.STREAM_END
      });
      message.error(res.errorMessage);
    }
};

export const deleteStream = (id) => async(dispatch) =>{
  try{
    const res = await streamService.deleteStream(id);
    if(res.isSuccess){
      message.success("Stream deleted");
    }else{
      message.error("Failed to delete")
    }
  }catch(error){
    message.error("Server error");
  }
}

