import React from "react";
import RankInfo from "./RankInfo";
import Header from "components/Headers/Header.js";

const CollegeRanking = () => {
  return (
    <>
      <Header />
      <RankInfo />
    </>
  );
};

export default CollegeRanking;
