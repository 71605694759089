import { GET_GALLERY_TYPES } from "../types";

const initialState ={
    gallery: {},
    loader:false,
};

export default (state = initialState,action) => {
    const { type, payload } = action;
    switch(type) {
        case GET_GALLERY_TYPES.GALLERY_BEGIN:
            return{
                ...state,
                loader:true,
            };
    case GET_GALLERY_TYPES.GET_GALLERY:
        return{
            ...state,
            gallery:payload,
            loader:false,
        };
        case GET_GALLERY_TYPES.GALLERY_END:
            return{
                ...state,
                loader:false
            };
            default:
                return state;
        }
};