import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editCollege } from "redux/actions/college_actions";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Form, Input, Button, InputNumber, Space } from "antd";
import ConfirmationBox from "components/ConfirmationBox";

const Faculity = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);

  useEffect(() => {
    form.setFieldsValue({
      total_faculty: college?.faculty?.total_faculty,
      major_faculty: college?.faculty?.major_faculty,
    });
  }, [college, form]);
  const onFinish = async (values) => {
    setLoading(true);
    let faculty = { ...values };
    console.log("values",values)
    values = { faculty, id: college._id };
    // values.id = college._id;
    await dispatch(editCollege(values, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Faculty</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    label="Total Faculty"
                    name="total_faculty"
                    rules={[
                      {
                        type: "number",
                        required: true,
                        message: "Total Faculity should not be empty",
                      },
                    ]}
                  >
                    <InputNumber placeholder="500" style={{ width: "100%" }} />
                  </Form.Item>
                </FormGroup>
              </Col>
            </Row>

            <Form.List name="major_faculty">
              {(fields, { add, remove }) => {
                if (fields.length > 2) {
                  setIsDisabled(true);
                }
                if (fields.length <= 2) {
                  setIsDisabled(false);
                }
                return (
                  <>
                    {fields.map((field) => (
                      <Row key={field.key} className="mt-4">
                        <Col lg="6">
                          <Form.Item
                            {...field}
                            label="Name"
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Faculty name shouldnt be empty",
                              },
                            ]}
                          >
                            <Input placeholder="Faculty name" />
                          </Form.Item>
                        </Col>
                        <Col lg="5">
                          <Form.Item
                            {...field}
                            label="Percentage"
                            name={[field.name, "percentage"]}
                            fieldKey={[field.fieldKey, "percentage"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing percentage",
                              },
                            ]}
                          >
                            <Input placeholder="Faculty Percentage" />
                          </Form.Item>
                        </Col>
                        <Col lg="1">
                          <MinusCircleOutlined
                            onClick={() => {
                              setConformObj({
                                title: `Are you sure you want to delete ?`,
                                msg: "This process cannot be undone.",
                                onSuccess: () => {
                                  remove(field.name);
                                  setIsConformModal(false);
                                },
                              });
                              setIsConformModal(true);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Col lg="12" className="mt-2">
                        <Form.Item>
                          <Button
                            disabled={isDisabled}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Major Faculty
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                );
              }}
            </Form.List>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Faculity;
