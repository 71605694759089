import React from "react";
import { singleTeammember, editTeammember } from "redux/actions/team_member";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { useDispatch } from "react-redux";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { Form, Input, Button } from "antd";

const EditTeammember = ({
  setEditTeamMember,
  editTeamMember,
  id,
  fetchTeammember,
}) => {
  const [loader, setLoader] = React.useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [teamMemberImage, setTeamMemberImage] = React.useState("");

  const onFinish = async (values) => {
    setLoader(true);

    await dispatch(editTeammember(id, values));
    await fetchTeammember();
    setLoader(false);
    setEditTeamMember(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const toggle = () => {
    setEditTeamMember(false);
  };

  const onSubmit = () => {
    form.submit();
  };
  const getOneTeamMember = async () => {
    const res = await dispatch(singleTeammember(id));
    form.setFieldsValue({ ...res });

    setTeamMemberImage(res.image);
  };

  React.useEffect(() => {
    getOneTeamMember();
  }, [id]);

  return (
    <Modal isOpen={editTeamMember} toggle={toggle} className="popup-modal">
      <ModalHeader toggle={toggle} className="modal--header">
        <div className="modal--title">Edit Team Member</div>
      </ModalHeader>
      <ModalBody>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row>
            <Col lg="6" xs="12">
              <Form.Item
                label="Name"
                className="form-control-label"
                name="name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter team member name!",
                  },
                ]}
              >
                <Input placeholder="Team member Name..." />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Email"
                name="email"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter team member Email",
                  },
                ]}
              >
                <Input placeholder="Team member email...." />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Types"
                name="type"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter Team member Type...",
                  },
                ]}
              >
                <Input placeholder="Team member type.." />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Facebook"
                name="facebook_link"
               
              >
                <Input placeholder="Team member facebook link" />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Instagram"
                name="insta_link"
                
              >
                <Input placeholder="Team member instagram link" />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Twitter"
                name="twitter_link"
                
              >
                <Input placeholder="Team member twitter link" />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Medium"
                name="medium_link"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "please enter team member medium link",
                  },
                ]}
              >
                <Input placeholder="Team member platform" />
              </Form.Item>
            </Col>
             <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Youtube"
                name="youtube_link"
                
              >
                <Input placeholder="Youtube link" />
              </Form.Item>
            </Col>
             <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="LinkedIn"
                name="linkedin_link"
                
              >
                <Input placeholder="LinkedIn link" />
              </Form.Item>
            </Col>
            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Upload Image"
                name="imageURL"
              >
                {/* url only on edit */}
                <ImageUploader
                  form={form}
                  formField="imageURL"
                  folder="team-member"
                  url={teamMemberImage}
                />
              </Form.Item>
            </Col>
            <Col lg="12" xs="12">
              <Form.Item
                className="form-control-label"
                label="Description"
                name="description"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "please enter team member description",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Team member Description..."
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="primary" onClick={onSubmit} loading={loader}>
          Update
        </Button>
        <Button disabled={loader} color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTeammember;
