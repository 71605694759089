import React from "react";
import ContactQueries from "./ContactQueries";
// core components
import Header from "components/Headers/Header.js"
const Counsellor = () => {
  return (
    <>
      <Header />
      <ContactQueries />
    </>
  );
};

export default Counsellor;
