import React from "react";
import { useDispatch } from "react-redux";
import { getCounsellorData } from "redux/actions/counsellor_actions";
import { getAllUsers } from "redux/actions/auth_action";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Input } from "antd";
import { firebase } from '../../firebase';
import { get } from "jquery";

const Admin = () => {
  const dispatch = useDispatch();
  const [userArray, setUserArray] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 20,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const getFireStoreUsers = async () => {
    setLoading(true);

    let getUsers = await dispatch(getAllUsers());
    setUserArray([...getUsers]);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreUsers();
  }, []);

  const columns = React.useMemo(() => [
    {
      title: "Date",
      dataIndex: "",
      width: "15%",
      render: (usersLists) => usersLists.date.toDate().toString()
      // render: (desc) => `${desc.substring(0, 20)}...`,
    },
    {
      title: "Nationality",
      dataIndex: "",
      width: "15%",
      render: (usersLists) => usersLists.country,
      // render: (desc) => `${desc.substring(0, 20)}...`,
    },
    {
      title: "Full Name",
      dataIndex: "",
      width: "20%",
      // ...getUserData(""),
      render: (usersLists) => usersLists.fullName,
    },
    {
      title: "Email",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.email,
    },
    {
      title: "Phone Number",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.phoneNumber,
    }
  ]);

  const filteredUser = userArray
    ?.sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds)
    .filter((usersLists) => {
      return (
        usersLists?.fullName.toLowerCase().includes(searchField) ||
        usersLists?.email.toLowerCase().includes(searchField) ||
        usersLists?.country.toLowerCase().includes(searchField)
      );
    });

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader>
              <div className="card-header-content">
                <div className="card-title-content">
                  <Input.Search
                    placeholder="Search by name, email and coverage"
                    enterButton
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
              </div>
            </CardHeader>

            <AntdTable
              columns={columns}
              rowKey={(record) => {
                return record.id;
              }}
              dataSource={filteredUser}
              pagination={pagination}
              // loading={loading}
              size="small"
              stripes="even"
              scroll={{ x: true }}
            />
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default Admin;
