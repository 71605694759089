import React from "react";
import Header from "components/Headers/Header.js";

const index = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default index;
