import React from "react";
import { useDispatch } from "react-redux";
import { getCounsellorData } from "redux/actions/counsellor_actions";
import { getAllUsers } from "redux/actions/auth_action";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Input } from "antd";
import { firebase } from "../../firebase";
import { get } from "jquery";
import {
  getAllStudentApplication,
  getStudentApplication,
} from "../../redux/actions/studentapplication_action";

import { getAllContacts } from "../../redux/actions/contact_action";

const ContactsTable = () => {
  const dispatch = useDispatch();
  const [userArray, setUserArray] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 20,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const getFireStoreUsers = async () => {
    setLoading(true);

    let getUsers = await dispatch(getAllStudentApplication());
    console.log("getUsers", getUsers);
    setUserArray([...getUsers]);
    setLoading(false);
  };

  const getFireStoreContacts = async () => {
    setLoading(true);

    let getContacts = await dispatch(getAllContacts());
    console.log("getContacts", getContacts);
    setContacts([...getContacts]);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreUsers();
    getFireStoreContacts();
  }, []);

  const columns = React.useMemo(() => [
    {
      title: "Timestamp",
      dataIndex: "",
      width: "15%",
      filters: [
        {
          text: "Local Date",
          value: "localDate",
        },
        {
          text: "Time",
          value: "time",
        },
      ],
      render: (usersLists) => usersLists.createdAt.toDate().toString(),
      // render: (desc) => `${desc.substring(0, 20)}...`,
    },
    {
      title: "Type",
      dataIndex: "",
      width: "15%",
      filters: [
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Associate",
          value: "associate",
        },
        {
          text: "Parent",
          value: "parent",
        },
        {
          text: "ABC",
          value: "abc",
        },
      ],
      //   render: (usersLists) => usersLists.country,
      // render: (desc) => `${desc.substring(0, 20)}...`,
    },
    {
      title: "Source",
      dataIndex: "",
      width: "20%",
      // ...getUserData(""),
      filters: [
        {
          text: "Website",
          value: "website",
        },
        {
          text: "Social Media",
          value: "socialMedia",
        },
        {
          text: "Manual",
          value: "manual",
        },
        {
          text: "Walk In",
          value: "walkIn",
        },
        {
          text: "Abc",
          value: "abc",
        },
      ],
      //   render: (usersLists) => usersLists.fullName,
    },
    {
      title: "First Name",
      dataIndex: "",
      width: "25%",
      render: (usersLists) =>
        usersLists.basicInformation.fullName.split(" ")[0],
    },
    {
      title: "Last Name",
      dataIndex: "",
      width: "25%",
      render: (usersLists) =>
        usersLists.basicInformation.fullName.split(" ")[1],
    },
    {
      title: "Nationality",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.basicInformation.nationality,
    },
    {
      title: "City",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.basicInformation.guardianCity,
    },
    {
      title: "Email",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.basicInformation.email,
    },
    {
      title: "Phone",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.basicInformation.phoneNumber,
    },
    {
      title: "Interested Country",
      dataIndex: "",
      width: "25%",
      filters: [
        {
          text: "USA",
          value: "student",
        },
        {
          text: "Canada",
          value: "associate",
        },
        {
          text: "Australia",
          value: "parent",
        },
        {
          text: "India",
          value: "abc",
        },
        {
          text: "UK",
          value: "uk",
        },
        {
          text: "New Zealand",
          value: "newZealand",
        },
        {
          text: "Dubai",
          value: "dubai",
        },
      ],
      //   render: (usersLists) => usersLists.phoneNumber,
    },
    {
      title: "Program Level",
      dataIndex: "",
      width: "25%",
      filters: [
        {
          text: "Diploma",
          value: "diploma",
        },
        {
          text: "Under Graduate",
          value: "undergraduate",
        },
        {
          text: "Post Graduate",
          value: "postgraduate",
        },
        {
          text: "PhD",
          value: "phd",
        },
      ],
      render: (usersLists) => usersLists.basicInformation.selectedLevel.label,
    },
    {
      title: "Program",
      dataIndex: "",
      width: "25%",
      filters: [
        {
          text: "Allied Health Science",
          value: "alliedHealthScience",
        },
        {
          text: "Applied Sciences",
          value: "appliedSciences",
        },
        {
          text: "Architecture and Design",
          value: "architectureAndDesign",
        },
        {
          text: "Business and Management",
          value: "busniessAndManagement",
        },
        {
          text: "Engineering and Technology",
          value: "engineeringAndTechnology",
        },
        {
          text: "Humanity and Social Sciences",
          value: "humanityAndSocialSciences",
        },
        {
          text: "Law and Legal Studies",
          value: "lawAndLegalStudies",
        },
        {
          text: "Medical and Pharmacy",
          value: "medicalAndPharmacy",
        },
      ],
      render: (usersLists) => usersLists.selectedChoice[0].collegeStream,
    },
    {
      title: "Interested Institution",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.selectedChoice[0].collegeName,
    },
    {
      title: "Query",
      dataIndex: "",
      width: "25%",
      render: (usersLists) => usersLists.query,
    },
  ]);

  const filteredUser = userArray
    ?.sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds)
    .filter((usersLists) => {
      return (
        usersLists?.basicInformation.fullName
          .toLowerCase()
          .includes(searchField) ||
        usersLists?.basicInformation.email
          .toLowerCase()
          .includes(searchField) ||
        usersLists?.basicInformation.country.toLowerCase().includes(searchField)
      );
    })
    .map((user) => {
      const contact = contacts.find(
        (c) => c.email === user.basicInformation.email
      );
      return {
        ...user,
        query: contact ? contact.query : "",
      };
    });

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader>
              <div className="card-header-content">
                <div className="card-title-content">
                  <Input.Search
                    placeholder="Search by name, email and coverage"
                    enterButton
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
              </div>
            </CardHeader>

            <AntdTable
              columns={columns}
              rowKey={(record) => {
                return record.id;
              }}
              dataSource={filteredUser}
              pagination={pagination}
              // loading={loading}
              size="small"
              stripes="even"
              scroll={{ x: true }}
            />
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default ContactsTable;
