import { api } from "./api/api";
const apiResponse = (isSuccess, data, errorMessage) => ({
  isSuccess,
  data,
  errorMessage,
});

export const postService = async (url, body, method = "POST") => {
  try {
    const resp = await api(url, {
      method,
      data: body,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return apiResponse(true, resp.data, null);
    } else {
      return apiResponse(false, resp.data, null);
    }
  } catch (err) {
    return apiResponse(false, null, err);
  }
};

export const getService = async (url) => {
  try {
    const resp = await api(url, {
      method: "GET",
    });

    if (resp.status >= 200 && resp.status < 300) {
      return apiResponse(true, resp.data, null);
    } else {
      return apiResponse(false, null, resp.data);
    }
  } catch (err) {
    return apiResponse(false, null, err);
  }
};
