import React from "react";
import { useDispatch } from "react-redux";
import {
  getTestimonials,
  addTestimonials,
  editTestimonails,
} from "../../redux/actions/testimonial_action";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { Form, Input, Button, Select } from "antd";

import ImageUploader from "components/ImageUploader/ImageUploader";

const AddEditTestimonialModal = (props) => {
  const {
    isAddEditModalOpen,
    handleAddEditModal,
    testimonialId,
    fetchTestimonial,
    setTestimonialId,
  } = props;
  const [imageUrl, setImageUrl] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();
  const { Option } = Select;

  const [form] = Form.useForm();
  const { TextArea } = Input;

  React.useEffect(() => {
    (async () => {
      if (testimonialId && isAddEditModalOpen) {
        let fetchOneTestimonial = await dispatch(
          getTestimonials(testimonialId)
        );
        fetchOneTestimonial = { ...fetchOneTestimonial?.testimonial };
        setImageUrl(fetchOneTestimonial?.image);
        form.setFieldsValue({
          ...fetchOneTestimonial,
        });
      } else {
        setImageUrl("");
        form.resetFields();
      }
    })();
  }, [testimonialId, isAddEditModalOpen]);

  const onFinish = async (values) => {
    values.id = testimonialId;
    if (testimonialId) {
      setLoader(true);
      await dispatch(editTestimonails(testimonialId, values));
      setLoader(false);
      handleAddEditModal(false);
      setTestimonialId("");
      await fetchTestimonial();
    } else {
      setLoader(true);
      await dispatch(addTestimonials(values));
      setLoader(false);
      handleAddEditModal(false);
      await fetchTestimonial();
    }
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  const toggle = () => {
    handleAddEditModal(false);
  };
  return (
    <>
      <Modal
        isOpen={isAddEditModalOpen}
        toggle={toggle}
        className="popup-modal"
      >
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">
            {testimonialId ? "Edit Testimonial" : "Add Testimonial"}{" "}
          </div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  label="Name"
                  className="form-control-label"
                  name="name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Student name cannot be empty",
                    },
                  ]}
                >
                  <Input placeholder="Student Name..." />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <div id="testPosition" style={{ position: "relative" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Testimonial Type"
                    name="testimonial_type"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Testimonial Type is required",
                      },
                    ]}
                  >
                    <Select placeholder="Select Testimonial Type">
                      <Option value="University">University</Option>
                      <Option value="Student">Student</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Year"
                  name="year"
                >
                  <Input placeholder="Year" />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Image"
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Image field cannot be empty",
                    },
                  ]}
                >
                  <ImageUploader
                    form={form}
                    formField="image"
                    folder="testimonial"
                    name="image"
                    url={imageUrl ? imageUrl : null}
                  />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Video ID"
                  name="video_url"
                >
                  <Input placeholder="Video url is optional" />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Course"
                  name="course"
                >
                  <Input placeholder="Course" />
                </Form.Item>
              </Col>
             
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Subject"
                  name="subject"
                >
                  <Input placeholder="Subject" />
                </Form.Item>
              </Col>
               <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="University Name"
                  name="university_name"
                  //  rules={[
                  //   {
                  //     type: "string",
                  //     required: true,
                  //     message: "This field cannot be empty",
                  //   },
                  // ]}
                >
                  
                  <Input placeholder="University Name " />
                </Form.Item>
              </Col>

              <Col lg="12" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Description"
                  name="description"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "This field cannot be empty",
                    },
                  ]}
                >
                  <TextArea placeholder="Write something..." rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="primary" onClick={onSubmit} loading={loader}>
            {testimonialId ? (loader ? "Updating" : "Update") : "Add"}
          </Button>
          <Button
            type="danger"
            onClick={() => {
              toggle(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddEditTestimonialModal;
