import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import { storage, firebase } from "../../firebase";
import { useDispatch } from "react-redux";
import { addGallery } from "redux/actions/gallery_action";

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const FileUpload = (props) => {
  const dispatch = useDispatch();
  const { folder, setReload, reload, gallery, collegeId } = props;
  const [localReload, setLocalReload] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setReload(!reload);
  }, [localReload]);

  const beforeUpload = file => {
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      message.error(`${file.name} is not a valid image type`, 2);
      return null;
    }
    return false;
  };

  const handleChange = async ({ fileList }) => {
    setFileList(fileList.filter(file => file.status !== "error"));
    try {
      const img = []
      let _college = {};
      console.log(gallery.images);
      _college.college_id = collegeId;
      _college.images = gallery?.images || [];

      await Promise.all(
        fileList.map(async file => {
          const storageRef = storage.ref(folder);
          const fileName = `${folder}-${Math.random().toString(36).slice(2)}`; //a unique name for the image
          const fileRef = storageRef.child(fileName);
          try {
            const designFile = await fileRef.put(file.originFileObj);
            const downloadUrl = await designFile.ref.getDownloadURL();
            const item = {
              url: downloadUrl,
              path: fileName,
              uploadedAt: firebase.firestore.Timestamp.now(),
            };
            img.push(downloadUrl)
            console.log(item);

            _college.images = [..._college.images, downloadUrl];
          } catch (e) {
            console.log(e);
          }
        })
      );
      await dispatch(addGallery(_college));
      setLocalReload(!localReload)
    } catch (err) {
      console.log(err);
      message.error(`Error adding images.`, 2);
    } finally {
    }
  }

  return (
    <div className="mediaFormContainer">
      <div className="header">Upload Images</div>
      <div className="uploadContainer">
        <Upload.Dragger
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          multiple={true}
          maxCount={9}
        >
          <div className="uploadText">
            <p>Drag and drop here</p>
            <p>OR</p>
            <p>Click</p>
          </div>
        </Upload.Dragger>
      </div>
    </div>
  );
};

export default FileUpload;