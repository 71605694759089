import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, message, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { storage } from "../../firebase";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { updateOfferLetter } from "redux/actions/studentapplication_action";

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "application/pdf" || file.type === "application/docx";
  if (!isJpgOrPng) {
    message.error("You can only upload PDF/DOCX file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
function getBase64(file, callback) {
  // return new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);

  // reader.onload = () => resolve(reader.result);
  // reader.onerror = (error) => reject(error);
  // });
}

const ImageUploader = ({ form, formField, folder, url, disable, id , setDocument}) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState([]);

  useEffect(() => {
    if (image[0]?.response || image[0]?.url) {
      form &&
        form({
          [formField]: image.map((image) => {
            return image?.response || image?.url;
          }),
        });
    }
  }, [image, form]);

  useEffect(() => {
    if (url?.length) {
      const images = url.map((_url) => ({
        name: _url?.substring(0, 50) + "...",
        uid: Math.random().toString(36).slice(2),
        status: "done",
        url: _url,
      }));
      setImage(images);
    }
  }, [url]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      info.fileList.forEach((item) => {
        if (item.status === "done") {
          let urls = info.fileList.map((image) => {
            return image?.response || image?.url;
          });
          dispatch(updateOfferLetter(urls, id));
          setImage(info.fileList);
        }
      });
    }
  };

  const customUpload = async ({ onProgress, onError, onSuccess, file }) => {
    const storageRef = storage.ref(folder);
    const imageName = `${folder}-${Math.random().toString(36).slice(2)}`; //a unique name for the image
    const imgFile = storageRef.child(imageName);
    
    const uploadTask = imgFile.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (100 * snapshot.bytesTransferred) / snapshot.totalBytes
        );
        onProgress(progress);
      },
      (error) => {
        // Handle error during the upload
        setImage([]);
        onError(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          // setImage(url)
          setDocument(url)
          // console.log("url",url)
          onSuccess(url);
        });
      }
    );
  };

  const deleteImage = async (image) => {
    const desertRef = storage.refFromURL(image.response || image.url);
    const isDeleted = await desertRef
      .delete()
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });
    return isDeleted;
  };

  return (
    <>
      <Upload
        name="image"
        listType="picture"
        fileList={image}
        multiple={false}
        className="upload-list-inline"
        customRequest={customUpload}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onRemove={async (image) => {
          return await deleteImage(image);
        }}
        progress={{
          strokeColor: {
            "0%": "#108ee9",
            "100%": "#87d068",
          },
          strokeWidth: 3,
          format: (percent) => {
            return `${percent}%`;
          },
        }}
        disabled={disable}
      >
        <Button icon={<UploadOutlined />}>
          {" "}
          Upload
          {image.length < 1}
        </Button>
      </Upload>
    </>
  );
};

export default ImageUploader;