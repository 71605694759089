import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import { Modal } from "reactstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { storage } from "../../firebase";
import { useSelector } from "react-redux";
import axios from "axios";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function getBase64(file, callback) {
  // return new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);

  // reader.onload = () => resolve(reader.result);
  // reader.onerror = (error) => reject(error);
  // });
}

const MultiImageUploader = ({ form, formField, folder, urls }) => {
  const [image, setImage] = useState([]);
  const [loader, setLoader] = useState(false);

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
  });

  const college = useSelector((state) => state.college.college);

  useEffect(() => {
    form &&
      form.setFieldsValue({
        [formField]: image?.response || image?.url,
        // [formField]: image.map((image) => {
        //   return image?.response || image?.url;
        // }),
      });
  }, [image, form]);

  useEffect(() => {
    if (urls?.length) {
      const images = urls.map((url) => ({
        uid: Math.random().toString(36).slice(2),
        status: "done",
        url: url,
      }));

      setImage(images);
    }
  }, [urls]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoader(true);
      return;
    }
    if (info.file.status === "done") {
      setLoader(true);
      setImage(info.fileList);
    }
  };

  const customUpload = async ({ onProgress, onError, onSuccess, file }) => {
    const storageRef = storage.ref(folder);
    const imageName = `${folder}-${Math.random().toString(36).slice(2)}`; //a unique name for the image
    const imgFile = storageRef.child(imageName);
    const uploadTask = imgFile.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (100 * snapshot.bytesTransferred) / snapshot.totalBytes
        );
        onProgress(progress);
      },
      (error) => {
        // Handle error during the upload
        setImage([])
        onError(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          // setImage(url)
          onSuccess(url);
        });
      }
    );
  };

  const deleteImage = async (imageInstance) => {
    const desertRef = storage.refFromURL(
      imageInstance.response || imageInstance.url
    );

    await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/college/deleteCollegePhoto`,
      {
        data: {
          _id: college._id,
          photoSrc: imageInstance.url,
        },
      }
    );

    setImage(image.filter((item) => item.url !== imageInstance.url));

    const isDeleted = await desertRef
      .delete()
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });

    return isDeleted;
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const toggle = () => {
    setPreview({ ...preview, previewVisible: !preview.previewVisible });
  };

  return (
    <>
      <div>Upload</div>
    </>
  );
};

export default MultiImageUploader;
