import React from "react";
import AppointmentDetails from "./AppointmentDetails";
import Layout from "../../layouts/index";
const Counsellor = () => {
  return (
    <Layout>
      <AppointmentDetails />
    </Layout>
  );
};

export default Counsellor;
