import { message } from "antd";
import { db, storage, auth, functions } from "../../firebase";

export const getAllContacts = () => async (dispatch) => {
  let contacts = [];
  try {
    let querySnapShot = await db.collection("contact").get();

    querySnapShot.forEach(function (doc) {
      const data = doc.data();
      data.id = doc.id;
      contacts.push(data);
    });
    return contacts;
  } catch (error) {
    return [];
  }
};
