import React from "react";
import CollegesDetail from "./CollegesDetail";
// core components
import Header from "components/Headers/Header.js";
const Colleges = () => {
  return (
    <>
      <Header />
      <CollegesDetail />
    </>
  );
};

export default Colleges;
