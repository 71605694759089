import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editCollege } from "redux/actions/college_actions";
import ConfirmationBox from "components/ConfirmationBox";
import moment from "moment";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Form, Input, Select, InputNumber, Space, Button, message } from "antd";
import { getRank } from "redux/actions/rank_action";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const CollegeRanking = ({ setIsRankComplete }) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [countRank, setCountRank] = useState(0);

  const [rankAuthorites, setRankAuthorites] = useState([]);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);
  const rank = useSelector((state) => state.rank.rank);
  const [allowSubmit,setAllowSubmit]=useState(false);
  // const collegeRankings = useSelector((state) => state.college.college.college_rankings);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    values.id = college._id;

    if(allowSubmit)
      await dispatch(editCollege(values , college.college_slug));
    setLoading(false);
  };

  const fetchRank = async () => {
    await dispatch(getRank());        
  };

  const handleFormChange = () => { 
    const formValue = form.getFieldsValue()
  }
  
  React.useEffect(() => {
    fetchRank();
  }, []);

  React.useEffect(() => {
    if (rank.length) {
      setRankAuthorites(
        rank.map((item) => ({
          rank_id: item._id,
          label: item.title,
          value: item._id,
          ranking_authority_logo: item.logo,
        }))
      );
    }
  }, [rank]);

  let yearOptions = useMemo(() => {
    return [
      {
        label: moment().subtract(0, "year").year(),
        value: moment().subtract(0, "year").year(),
      },
      {
        label: moment().subtract(1, "year").year(),
        value: moment().subtract(1, "year").year(),
      },
      {
        label: moment().subtract(2, "year").year(),
        value: moment().subtract(2, "year").year(),
      },
      {
        label: moment().subtract(3, "year").year(),
        value: moment().subtract(3, "year").year(),
      },
    ];
  }, []);

  useEffect(() => {
    const { college_rankings } = college;
    let api_ranking = college_rankings?.map((item) => ({
      rank_id: item.rank_id?._id,
      label: item.rank_id?.title,
      value: item.rank_id?._id,
      ranking_authority_logo: item.rank_id?.logo,
      year:item.year,
      rank:item.rank
    }))
    console.log(api_ranking);
    form.setFieldsValue({
      college_rankings: api_ranking
    });
  }, [college, form]);

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo); 
  };

  const onSubmit = () => {
    console.log(allowSubmit);
    if(allowSubmit){
      form.submit();
      setTimeout(() => {
        setAllowSubmit(false);
      }, 1000);
    }
    else 
      message.error('Year for the title is already taken');
  };

  const handleValueChange=(changedValues, allValues)=>{
    const changedRank=allValues.college_rankings[changedValues.college_rankings.length-1];
      // CHECKING IF THE TITLE AND YEAR ALREADY EXISTS
      if((changedRank && (changedRank.rank_id || changedRank.value)) && changedRank.year){
        const rankId= changedRank.value || changedRank.rank_id;
        let count =0;
        allValues.college_rankings.forEach((rank,index)=>{
          if(index!==changedValues.college_rankings.length-1){
            if((rank.value==rankId) && (rank.year==changedRank.year))
              count++;
          }
          console.log(count);
        })
        if(count==0) setAllowSubmit(true);
      }
      console.log(allowSubmit)
  }

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">College Ranking</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={handleFormChange}
            onValuesChange={handleValueChange}
            layout="vertical"
          >
            <Form.List name="college_rankings">
              {(fields, { add: addRank, remove: removeRank }) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.key}>
                      <Col lg="4">
                        <div id="testPosition" style={{ position: "relative" }}>
                          <Form.Item
                            {...field}
                            label="Title"
                            name={[field.name, "rank_id"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing your title",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={() =>
                                document.getElementById("testPosition")
                              }
                              placeholder="Select Authorities"
                              showSearch
                              // style={{ width: 200 }}
                              optionFilterProp="children"
                              options={rankAuthorites}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg="4">
                        <div id="testPosition" style={{ position: "relative" }}>
                          <Form.Item
                            {...field}
                            label="Year"
                            name={[field.name, "year"]}
                            fieldKey={[field.fieldKey, "year"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing  year",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={() =>
                                document.getElementById("testPosition")
                              }
                              placeholder="Select Year"
                              optionFilterProp="children"
                              options={yearOptions}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg="3">
                        <Form.Item
                          {...field}
                          name={[field.name, "rank"]}
                          label="Rank"
                          fieldKey={[field.fieldKey, "rank"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing  rank",
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="2"
                            style={{ width: "100%" }}
                            onChange={()=>setCountRank(countRank+1)}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg="1">
                        <MinusCircleOutlined
                          onClick={() => {
                            setCountRank((prev) => prev - 1);
                            setConformObj({
                              title: `Are you sure you want to delete ?`,
                              msg: "This process cannot be undone.",
                              onSuccess: () => {
                                removeRank(field.name);
                                setIsConformModal(false);
                              },
                            });
                            setIsConformModal(true);
                          }}
                        />
                      </Col>
                    </Row>
                    // </Space>
                  ))}
                  <Row>
                    <Col lg="12">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            addRank();
                            setCountRank((prev) => prev + 1);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add College Rank
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container">
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
               disabled={countRank === 0}
            >
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default CollegeRanking;
