import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { Form, Select, Button, Input } from "antd";
import Loader from "components/Loader";
import ImageUploader from "components/ImageUploader/ImageUploader";
import {
  editCounsellor,
  getSingleCounsellor,
} from "redux/actions/counsellor_actions";
import countryLists from "react-select-country-list";
import { countryList } from "utils/CountryList";

const EditCounsellor = (props) => {
  const {
    handleEditCounsellor,
    isEditCounsellorOpen,
    id,
    getFireStoreCounselor,
  } = props;

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [counsellor, setCounsellor] = useState({});
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { Option } = Select;
  const options = useMemo(() => countryLists().getData(), []);

  const getCounsellor = async () => {
    let counsellor = await dispatch(getSingleCounsellor(id));
    setCounsellor(counsellor);
    setLoader(false);
  };

  React.useEffect(() => {
    getCounsellor();
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      ...counsellor,
    });
  }, [counsellor]);

  const toggle = () => {
    handleEditCounsellor(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let _counsellor = await dispatch(editCounsellor(values, counsellor.id));
    if (_counsellor) {
      handleEditCounsellor(false);
      getFireStoreCounselor();
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Modal
        isOpen={isEditCounsellorOpen}
        toggle={toggle}
        className="popup-modal"
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <ModalHeader toggle={toggle} className="modal--header">
              <div className="modal--title">Edit Counsellor</div>
            </ModalHeader>
            <ModalBody>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Row>
                  <Col lg="6" xs="12">
                    <Form.Item
                      label="Name"
                      className="form-control-label"
                      name="name"
                      rules={[
                        {
                          type: "string",
                          // required: true,
                          message: "Please enter your name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Counsellor Name..."
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg="6" xs="12">
                    <Form.Item
                      className="form-control-label"
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "string",
                          // required: true,
                          message: "Please enter your Email",
                        },
                      ]}
                    >
                      <Input placeholder="your email...." disabled={loading} />
                    </Form.Item>
                  </Col>
                  <Col lg="6" xs="12">
                    <Form.Item
                      label="Phone"
                      className="form-control-label"
                      name="phone"
                      rules={[
                        {
                          type: "string",
                          // required: true,
                          message: "Please enter phone number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Counsellor phone number"
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg="6" xs="12">
                    <Form.Item
                      className="form-control-label"
                      label="New Password"
                      name="password"
                      rules={[
                        {
                          type: "string",
                          // required: true,
                          message: "Please enter your Password",
                        },
                      ]}
                    >
                      <Input
                        type="password"
                        value="helloworld1"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg="6" xs="12">
                    <Form.Item
                      className="form-control-label"
                      label="Nationality"
                      name="nationality"
                      rules={[
                        {
                          // required: true,
                          message: "Nationality is required",
                        },
                      ]}
                    >
                      <Select placeholder="Counsellor nationality" showSearch>
                        {countryList &&
                          countryList?.map((item, i) => {
                            return (
                              <Option value={item.value} key={i}>
                                {item.value}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg="6" xs="12">
                    <Form.Item
                      className="form-control-label"
                      label="Types"
                      name="type"
                      rules={[
                        {
                          type: "string",
                          // required: true,
                          message: "Consellor Type",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Consellor type.."
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg="6" xs="12">
                    <Form.Item
                      name="coverage"
                      label="Country Coverage"
                      // rules={[
                      //   {
                      //     // required: true,
                      //     message: "Please select the list of country",
                      //     type: "string",
                      //   },
                      // ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Please select recruiting companies"
                        className="custom-drop-down"
                        showSearch
                      >
                        {countryList &&
                          countryList?.map((item, i) => {
                            return (
                              <Select.Option value={item.value} key={i}>
                                {item.value}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg="6" xs="12">
                    <Form.Item
                      className="form-control-label"
                      label="Upload Image"
                      name="imageURL"
                    >
                      {/* url only on edit */}
                      <ImageUploader
                        form={form}
                        formField="imageURL"
                        folder="counsellor"
                        url={counsellor?.imageURL}
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg="12" xs="12">
                    <Form.Item
                      className="form-control-label"
                      label="Description"
                      name="desc"
                      rules={[
                        {
                          type: "string",
                          // required: true,
                          message: "please enter description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={4}
                        placeholder="Description..."
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="form-btn-container">
                <Button loading={loading} type="primary" onClick={onSubmit}>
                  Update Counsellor
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    handleEditCounsellor(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  );
};

export default React.memo(EditCounsellor);
