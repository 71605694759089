import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import AdminLayout from "layouts/Admin.js";
import "react-toastify/dist/ReactToastify.css";
import Login from "./views/examples/Login";
import { Spin } from "antd";
import CounsellorLayout from "layouts/Counsellor.js";
import AuthLayout from "layouts/Auth.js";
import { db, auth } from "./firebase";
import { ToastContainer } from "react-toastify";
import "./styles/main.css";

export default () => {
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [lastVisitedRoute, setLastVisitedRoute] = React.useState("");

  useEffect(() => {
    const lastVisit = localStorage.getItem("current-route");
    // console.log(lastVisit);
    if (lastVisit) {
      const textSplit = lastVisit?.split("/");
      setLastVisitedRoute(textSplit[4]);
    } else {
      setLastVisitedRoute("index");
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user.admin = idTokenResult.claims.admin;
          user.isCounsellor = idTokenResult.claims.isCounsellor;

          setUser(user);

          setLoader(false);
        });
      } else {
        // console.log("Invalid User");
        setUser({});
        setLoader(false);
      }
    });
  }, []);

  if (loader) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="medium" />
      </div>
    );
  }

  // console.log(lastVisitedRoute);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Switch>
          <Route
            path="/admin"
            render={(props) => {
              return user.admin || user.isCounsellor ? (
                <AdminLayout user={user} {...props} />
              ) : (
                <Redirect to="/auth" />
              );
            }}
          />
          <Route
            path="/auth"
            render={(props) => {
              return !user.admin && !user.isCounsellor ? (
                <AuthLayout user={user} {...props} />
              ) : (
                // <Redirect to="/admin/index" />
                <Redirect to={`/admin/${lastVisitedRoute}`} />
                // <Redirect
                //   to={`${lastVisitedRoute} ? /admin/index : /admin/${lastVisitedRoute}  `}
                // />
              );
            }}
          />
          <Route path="/login" component={Login} />

          <Redirect from="*" to="/admin/index" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};
