import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Input,
  Row,
  Col,
} from "reactstrap";
import { Form, Select, Button, Input, message } from "antd";
import {
  updateProgram,
  getLevels,
  getStreams,
  getAllPrograms,
  updateCollegeCourse,
} from "redux/actions/courses_action";
const { TextArea } = Input;
const EditCourse = (props) => {
  const [loading, setLoading] = useState(false);
  const [fetchedLevels, setFetchedLevels] = useState([]); //original data
  const [fetchedStreams, setFetchedStreams] = useState([]);
  const [fetchedPrograms, setFetchedPrograms] = useState([]);
  const { course, setIsEditModalOpen, isEditModalOpen, fetchCourses, college } =
    props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;

  const fetchLevels = async () => {
    let data = await dispatch(getLevels());
    setFetchedLevels(data);
  };

  useEffect(() => {
    isEditModalOpen && fetchLevels();
  }, [isEditModalOpen]);

  const onSelectLevel = async (level) => {
    form.setFieldsValue({ coursestream: null });
    let data = await dispatch(getStreams(level));
    setFetchedStreams(data);
  };

  const fetchCourseData = async (course) => {
    // fetchLevels()
    let data = await dispatch(getStreams(course?.courselevel._id));
    setFetchedStreams(data);
    let programs = await dispatch(
      getAllPrograms(course?.courselevel?._id, course?.coursestream?._id)
    );
    setFetchedPrograms(programs);
    form.setFieldsValue({
      courselevel: course?.courselevel?._id,
      coursestream: course?.coursestream?._id,
      courseprogram: course?.courseprogram._id,
      // eligibility: course?.eligibility,
      how_to_apply: course?.how_to_apply,
      fee_per_sem: course?.fee_per_sem,
      course_duration: course?.course_duration,
    });
  };

  useEffect(() => {
    isEditModalOpen && fetchCourseData(course);
  }, [course, isEditModalOpen]);

  const toggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const onselectStream = async (level, option) => {
    form.setFieldsValue({ courseprogram: null });
    let data = await dispatch(getAllPrograms(option.courselevel, option.value));
    setFetchedPrograms(data);
  };

  const onFinish = async (values) => {
    values.id = course._id;
    values.college = college._id;

    setLoading(true);
    let response = await dispatch(updateCollegeCourse(values));
    if (response) {
      fetchCourses();
      setIsEditModalOpen(false);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Modal isOpen={isEditModalOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Edit Program</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Level"
                  name="courselevel"
                  rules={[
                    {
                      required: true,
                      message: "Level is required",
                    },
                  ]}
                >
                  <Select
                    onSelect={onSelectLevel}
                    placeholder="Select Level"
                    //disabled
                  >
                    {fetchedLevels.map((l) => {
                      return (
                        <Option key={l._id} value={l._id}>
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Stream"
                  name="coursestream"
                  rules={[
                    {
                      required: true,
                      message: "Stream is required",
                    },
                  ]}
                >
                  <Select
                    onSelect={onselectStream}
                    placeholder="Select Stream"
                   // disabled
                  >
                    {fetchedStreams.map((l) => {
                      return (
                        <Option
                          key={l._id}
                          courselevel={l.courselevel}
                          value={l._id}
                        >
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Program"
                  name="courseprogram"
                  rules={[
                    {
                      required: true,
                      message: "Program is required",
                    },
                  ]}
                >
                  <Select placeholder="Select Program" 
                  //disabled
                  >
                    {fetchedPrograms.map((l) => {
                      return (
                        <Option key={l._id} value={l._id}>
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Fee Per Sem"
                  name="fee_per_sem"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "fee_per_sem is required",
                    },
                  ]}
                >
                  <Input placeholder="50k" />
                </Form.Item>
              </Col>
              {/* <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Eligibility"
                  name="eligibility"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "eligibility is required",
                    },
                  ]}
                >
                  <Input placeholder="+2" />
                </Form.Item>
              </Col> */}
              
              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Course Duration"
                  name="course_duration"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "course_duration is required",
                    },
                  ]}
                >
                  <Input placeholder="3 years" />
                </Form.Item>
              </Col>
              <Col lg="12">
                <Form.Item
                  className="form-control-label"
                  label="How to apply"
                  name="how_to_apply"
                  // rules={[
                  //   {
                  //     type: "string",
                  //     required: true,
                  //     message: "how_to_apply is required",
                  //   },
                  // ]}
                >
                  <TextArea rows={4} placeholder="how_to_apply" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Update Course
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setIsEditModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default React.memo(EditCourse);
