import { postService, getService } from "../commonServices";
export class CollegeService {
  getColleges() {
    let url = "/college";
    let data = getService(url);
    return data;
  }

  getCollege(college_slug) {
    let url = `/college/getOneCollege/${college_slug}`; //temporarly slug is id
    let data = getService(url);
    return data;
  }

  addCollege(college) {
    let body = JSON.stringify(college);

    let url = `/college/addCollege`;
    let data = postService(url, body);
    return data;
  }

  deleteCollege(college_id) {
    let url = `/college/deleteCollege/?id=${college_id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }

  editCollege(college) {
    let body = JSON.stringify(college);
    let url = `/college/updateCollege`;
    let data = postService(url, body, "PUT");
    return data;
  }
}
