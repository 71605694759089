import { postService, getService } from "../commonServices";

export class RecruitService {
  getRecruit() {
    let url = "/recruit/getRecruitCompany";
    let data = getService(url);
    return data;
  }

  addRecruit(recruit_data) {
    let url = "/recruit/addRecruitCompany";
    let body = JSON.stringify(recruit_data);

    let data = postService(url, body);
    return data;
  }

  deleteRecruit(id) {
    let url = `/recruit/deleteRecruitCompany/${id}`;

    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
