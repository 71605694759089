import React from "react";
import Layout from "../../layouts/index";
import ScholarshipFormTable from "./ScholarshipFormTable";

const Scholarship = () => {
  return (
    <Layout>
      <ScholarshipFormTable />
    </Layout>
  );
};

export default Scholarship;
