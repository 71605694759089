import React from "react";
import {
  getFacilities,
  deleteFacilities,
} from "redux/actions/facilities_action";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationBox from "components/ConfirmationBox";
import { Input, Table, Button } from "antd";
import { CardHeader } from "reactstrap";
import { DeleteOutlined } from "@ant-design/icons";
import AddFacilities from "./AddFacilities";
import moment from "moment";

const FacilitiesTable = () => {
  const [searchField, setSearchField] = React.useState("");
  const [addModalOpen, setAddModalOpen] = React.useState(false);

  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [isConformModal, setIsConformModal] = React.useState(false);
  //redux state
  const facilities = useSelector((state) => state.facilities.facilities);
  const loader = useSelector((state) => state.facilities.loader);

  const dispatch = useDispatch();

  const fetchFacilities = async () => {
    await dispatch(getFacilities());
  };
  React.useEffect(() => {
    fetchFacilities();
  }, []);

  const pagination = {
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const columns = React.useMemo(() => [
    {
      title: "Logo",
      dataIndex: "",
      key: "_id",
      className: "table-image-container",
      width: "20%",
      render: (logo) => {
        return <img src={`${logo.logo}`} alt="..." />;
      },
    },
    {
      title: "Facilities Title",
      dataIndex: "title",
      width: "72%",
    },
    {
      title: "Action",
      dataIndex: "",
      width: "8%",
      className: "table-title",
      render: (data) => (
        <div className="text-right site-button-ghost-wrapper">
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => {
              setIsConformModal(true);
              setConformObj({
                title: `Are you sure you want to delete ${data?.title}?`,
                msg: "This process cannot be undone.",
                onSuccess: async () => {
                  setDeleteLoader(true);
                  await dispatch(deleteFacilities(data._id, data.logo));
                  setDeleteLoader(false);
                  fetchFacilities();
                  setIsConformModal(false);
                },
              });
            }}
          />
        </div>
      ),
    },
  ]);
  const filterData = facilities
    ?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
    .filter((item) => {
      return item?.title.toLowerCase().includes(searchField.toLowerCase());
    });

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
        loader={deleteLoader}
      />
      <AddFacilities
        setAddModalOpen={setAddModalOpen}
        addModalOpen={addModalOpen}
        fetchFacilities={fetchFacilities}
      />
      <CardHeader>
        <div className="card-header-content">
          <div className="card-title-content">Facilities</div>
          <Button onClick={() => setAddModalOpen(true)} type="primary">
            Add
          </Button>
        </div>
      </CardHeader>
      <div style={{ padding: "20px 8px 0px" }}>
        <div className="search-contianer">
          <Input.Search
            placeholder="Search by name, email and coverage"
            enterButton
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(i) => i._id}
        dataSource={filterData}
        loading={loader}
        pagination={pagination}
        size="small"
      />
    </>
  );
};
export default FacilitiesTable;
