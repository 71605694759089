import React from "react";
import { Card, Container, Row, Col } from "reactstrap";
import RankTable from "./RankTable";
import FacilitiesTable from "./FacilitiesTable";

const SharedInfos = () => {
  return (
    <>
      <Container className="mt--7 pb-2" fluid>
        <Row>
          <Col>
            <Card>
              <RankTable />
            </Card>
          </Col>
          <Col>
            <Card>
              <FacilitiesTable />
            </Card>
          </Col>
          
        </Row>
       
      </Container>
    </>
  );
};

export default SharedInfos;
