import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { Upload, message, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { storage } from "../../firebase";
import _ from "lodash";
import { useSelector } from "react-redux";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
function getBase64(file, callback) {
  // return new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);

  // reader.onload = () => resolve(reader.result);
  // reader.onerror = (error) => reject(error);
  // });
}

const ImageUploader = ({
  form,
  formField,
  folder,
  url,
  isInFormList = false,
  fieldKey,
  saveUrl,
  removeUrl,
  disabled,
}) => {
  const [image, setImage] = useState([]);
  const [loader, setLoader] = useState(false);

  const college = useSelector((state) => state.college.college);

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
  });
  // console.log("aaaaa", form.getFieldValue(formField));

  useEffect(() => {
    if (isInFormList && (image[0]?.response || image[0]?.url)) {
      saveUrl({
        [formField]: image[0]?.response || image[0]?.url,
        index: fieldKey,
      });
    } else {
      form &&
        form.setFieldsValue({
          [formField]: image[0]?.response || image[0]?.url,
        });
    }
  }, [image, form]);

  useEffect(() => {
    // console.log(url);
    if (url) {
      let previousURL = {
        uid: Math.random().toString(36).slice(2),
        name: "image.png",
        status: "done",
        url,
      };
      setImage([previousURL]);
    }
  }, [url]);

  const handleChange = (info) => {
    console.log({ info });

    if (info.file.status === "uploading") {
      setLoader(true);
      return;
    }

    if (info.file.status === "done") {
      info.fileList.forEach((item) => {
        if (item.status === "done") {
          setLoader(false);
          setImage([item]);
        }
      });

      // if (info.fileList.status === "done") {
      //   console.log(info.fileList);

      //   setLoader(false);
      //   // setImage(info.fileList);
      // } else {
      //   setLoader(false);
      //   console.log("zzzzzzzzz");
      // }
    }
  };

  const customUpload = async ({ onError, onSuccess, file }) => {
    const storageRef = storage.ref(folder);
    const imageName = `${folder}-${Math.random().toString(36).slice(2)}`; //a unique name for the image
    const imgFile = storageRef.child(imageName);
    const uploadTask = imgFile.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // setProgress(progress);
      },
      (error) => {
        // Handle error during the upload
        setImage([]);
        onError(error);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("downloadURL", downloadURL);
          setImage([
            { uid: Math.random().toString(36).slice(2), url: downloadURL },
          ]);
          onSuccess(downloadURL);
        });
      }
    );
  };

  const deleteImage = async (image) => {
    const desertRef = storage.refFromURL(image.response || image.url);
    const isDeleted = await desertRef
      .delete()
      .then(() => {
        //remove from the form as well
        if (isInFormList) {
          removeUrl(fieldKey);
        }
        setImage([]);

        return true;
      })
      .catch((error) => {
        return false;
      });
    return isDeleted;
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const toggle = () => {
    setPreview({ ...preview, previewVisible: !preview.previewVisible });
  };
  return (
    <>
      <Upload
        // defaultFileList={image}
        name="image"
        listType="picture-card"
        fileList={image}
        multiple={false}
        className="avatar-uploader"
        onPreview={onPreview}
        customRequest={customUpload}
        disabled={loader}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        //disabled={loader}
        onRemove={async (image) => {
          await deleteImage(image);
          setImage([]);
          form.setFieldsValue({
            [formField]: "",
          });
        }}
      >
        {image.length < 1 && (loader ? <LoadingOutlined /> : "Upload")}
      </Upload>
      <Modal
        isOpen={preview.previewVisible}
        toggle={toggle}
        style={{
          maxWidth: "30%",
          maxHeight: "25%",
        }}
      >
        <img
          src={preview.previewImage}
          alt="avatar"
          style={{ objectFit: "cover" }}
        />
      </Modal>
    </>
  );
};

export default ImageUploader;
