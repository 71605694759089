import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addRecruit,
  getRecruit,
  deleteRecruit,
} from "redux/actions/recruit_actions";
import { Row, Col } from "reactstrap";
import { Form, Input, Button, Modal } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ConfirmationBox from "components/ConfirmationBox";
import ImageUploader from "components/ImageUploader/ImageUploader";

const RecrutingCompanies = ({
  setAddModalOpen,
  addModalOpen,
  fetchRecruitCompanies,
}) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [recruitingCompany, setRecruitingCompany] = useState([]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  React.useEffect(() => {
    dispatch(getRecruit());
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(addRecruit(values.top_recruiting_companies));
    fetchRecruitCompanies();
    toggle();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    let { top_recruiting_companies } = fields;
    if (top_recruiting_companies.length) {
      recruitingCompany.forEach((icon) => {
        Object.assign(top_recruiting_companies[icon.index], {
          ["logo"]: icon.logo,
        });
      });
      form.setFieldsValue({ top_recruiting_companies });
      form.submit();
    }
  };

  const saveLogoUrl = (url) => {
    setRecruitingCompany([...recruitingCompany, url]);
  };

  const removeLogoUrl = (index) => {
    setRecruitingCompany(recruitingCompany.filter((u) => u.index !== index));
  };

  const toggle = () => {
    setAddModalOpen(false);
  };
  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Modal
        visible={addModalOpen}
        onCancel={toggle}
        // className="popup-modal"
        footer={null}
        title="Add Recruiting Companies"
      >
        <div className="mt-4 mb-4">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.List name="top_recruiting_companies">
              {(fields, { add, remove }) => (
                <div key={fields.index}>
                  {fields.map((field, i) => {
                    return (
                      <Row key={i}>
                        <Col lg="6">
                          <Form.Item
                            {...field}
                            label="Title"
                            name={[field.name, "title"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Title name cannot be empty",
                              },
                            ]}
                          >
                            <Input placeholder="Title Name" />
                          </Form.Item>
                        </Col>
                        <Col lg="3">
                          <Form.Item
                            {...field}
                            label="logo"
                            name={[field.name, "logo"]}
                            fieldKey={[field.fieldKey, "logo"]}
                            rules={[
                              {
                                required: true,
                                message: "please upload image logo",
                              },
                            ]}
                          >
                            <ImageUploader
                              form={form}
                              isInFormList={true} //on dynamic form
                              fieldKey={field.name} //on dynamic form
                              saveUrl={saveLogoUrl} //on dynamic form
                              removeUrl={removeLogoUrl} //on dynamic form
                              formField={`logo`}
                              folder="top-recruiting-companies"
                              url={
                                form.getFieldsValue()?.top_recruiting_companies[
                                  field.key
                                ]?.logo
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col lg="3">
                          <MinusCircleOutlined
                            onClick={() => {
                              setConformObj({
                                title: `Are you sure you want to delete ?`,
                                msg: "This process cannot be undone.",
                                onSuccess: () => {
                                  setCount((prev) => prev - 1);
                                  remove(field.name);
                                  removeLogoUrl(field.name);
                                  setIsConformModal(false);
                                },
                              });
                              setIsConformModal(true);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}

                  <Row>
                    <Col lg="12" className="mt-2">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                            setCount((prev) => prev + 1);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Recruiting Companies
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container">
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              disabled={count === 0}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RecrutingCompanies;
