import { message } from "antd";
import { PopUp } from "components/Toast/PopUp";
import { db, auth } from "../../firebase";
import moment from "moment";
export const getAllStudentApplication = () => async (dispatch) => {
  let studentAppliation = [];
  try {
    let querySnapshot = await db
      .collection("students-application")
      .orderBy("createdAt", "desc")
      .get();

    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      data.id = doc.id;

      studentAppliation.push(data);
    });

    if (auth.currentUser.isCounsellor) {
      let doc = await db
        .collection("counsellor")
        .doc(auth.currentUser.uid)
        .get();
      if (doc.exists) {
        let counsellor = doc.data();
        let coverage = counsellor?.coverage.toLowerCase();

        studentAppliation = studentAppliation.filter(
          (a) => a.basicInformation.nationality.toLowerCase() === coverage
        );
      }
    }
    return studentAppliation;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getStudentApplication = (id) => {
  return async (dispatch) => {
    try {
      let doc = await db.collection("students-application").doc(id).get();

      if (doc.exists) {
        let data = doc.data();
        let studentAppliation = {
          id: doc.id,
          ...data,
        };
        return studentAppliation;
      } else {
        throw Error("studentAppliation not found");
      }
    } catch (error) {
      // console.log(error);
      return {};
    }
  };
};

export const updateStudentApplication =
  ({ status, remark, offer_letter }, id) =>
  async () => {
    try {
      await db.collection("students-application").doc(id).update({
        status,
        offer_letter,
        remarks: remark,
      });
      // await db
      //   .collection("students-application")
      //   .doc(id)
      //   .collection("remarks")
      //   .add({
      //     createdAt: moment().format(),
      //     remark: remark,
      //   });

      message.success("Success");
      return true;
    } catch (error) {
      message.error("Server error please try again later", "error");
    }
  };

export const updateOfferLetter = (offer_letter, id) => {
  return async (dispatch) => {
    try {
      await db.collection("students-application").doc(id).update({
        // status,
        offer_letter,
        // remark,
      });
      dispatch(getStudentApplication(id));
      PopUp("Updated offer letter successfully", "success");
    } catch (error) {
      PopUp("Server error please try again later", "error");
    }
  };
};

export const saveEmailSentToCollege = (formdata, id) => {
  return async (dispatch) => {
    try {
      await db.collection("students-application").doc(id).update({
        emailSentToCollege: formdata,
      });
      PopUp("Email was sent successfully", "success");
      return true;
    } catch (error) {
      // console.log(error);
      PopUp("Something went wrong", "error");
      return false;
    }
  };
};
