import React from "react";
import BlogsDetail from "./BlogsDetail";
import "./style.css";
// core components
import Layout from "../../layouts/index";
const Colleges = () => {
  return (
    <>
      <Layout>
        <BlogsDetail />
      </Layout>
    </>
  );
};

export default Colleges;
