import React from "react";
import ProfileDetails from "./CoursesDetail";
const index = ({ setIsCourseComplete}) => {
  return (
    <>
      <ProfileDetails setIsCourseComplete={setIsCourseComplete} />
    </>
  );
};

export default index;
