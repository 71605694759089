import React from "react";
import StudentApplicationDetails from "./StudentApplicationDetails";
// core components
import Header from "components/Headers/Header.js"
const Counsellor = () => {
  return (
    <>
      <Header />
      <StudentApplicationDetails />
    </>
  );
};

export default Counsellor;
