import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Row, Col } from "reactstrap";
import ConfirmationBox from "components/ConfirmationBox";

import {
  addScholarship,
  getScholarship,
} from "redux/actions/scholarship_actions";
import { Form, Input, Button, Modal } from "antd";

const AddScholarship = ({ addModalOpen, setAddModalOpen }) => {
  const dispatch = useDispatch();
  const [count, setCount] = React.useState(0);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [scholarshipLogo, setScholarshipLogo] = useState([]);

  //redux state
  const scholarship = useSelector((state) => state.scholarship.scholarship);
  const loader = useSelector((state) => state.scholarship.loader);

  React.useEffect(() => {
    dispatch(getScholarship());
  }, []);

  const onFinish = async (values) => {
    await dispatch(addScholarship(values.scholarship));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const [form] = Form.useForm();

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    let { scholarship } = fields;
    if (scholarship.length) {
      scholarshipLogo.forEach((icon) => {
        Object.assign(scholarship[icon.index], {
          ["logo"]: icon.logo,
        });
      });
      form.setFieldsValue({ scholarship });
      form.submit();
    }
  };

  const saveLogoUrl = (url) => {
    setScholarshipLogo([...scholarshipLogo, url]);
  };

  const removeLogoUrl = (index) => {
    setScholarshipLogo(scholarshipLogo.filter((u) => u.index !== index));
  };

  const toggle = () => {
    setAddModalOpen(false);
  };

  return (
    <>
      <Modal
        visible={addModalOpen}
        onCancel={toggle}
        // className="popup-modal"
        footer={null}
        title="Add Scholarship"
      >
        <ConfirmationBox
          data={conformObj}
          setIsConformModal={setIsConformModal}
          isConformModal={isConformModal}
        />
        <div className="mt-4 mb-4">
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.List name="scholarship">
              {(fields, { add, remove }) => (
                <div key={fields.index}>
                  {fields.map((field, i) => {
                    return (
                      <Row key={i}>
                        <Col lg="6">
                          <Form.Item
                            {...field}
                            label="Title"
                            name={[field.name, "title"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Title name cannot be empty",
                              },
                            ]}
                          >
                            <Input placeholder="Title Name" />
                          </Form.Item>
                        </Col>
                        <Col lg="3">
                          <Form.Item
                            {...field}
                            name={[field.name, "logo"]}
                            label="Logo"
                            fieldKey={[field.fieldKey, "logo"]}
                            rules={[
                              {
                                required: true,
                                message: "please upload image logo",
                              },
                            ]}
                          >
                            <ImageUploader
                              form={form}
                              isInFormList={true} //on dynamic form
                              fieldKey={field.name} //on dynamic form
                              saveUrl={saveLogoUrl} //on dynamic form
                              removeUrl={removeLogoUrl} //on dynamic form
                              formField={`logo`}
                              folder="scholarship"
                              url={
                                form.getFieldsValue()?.scholarship[field.key]
                                  ?.logo
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col lg="3">
                          <MinusCircleOutlined
                            onClick={() => {
                              setCount((prev) => prev - 1);
                              setConformObj({
                                title: `Are you sure you want to delete ?`,
                                msg: "This process cannot be undone.",

                                onSuccess: () => {
                                  remove(field.name);
                                  removeLogoUrl(field.name);
                                  setIsConformModal(false);
                                },
                              });
                              setIsConformModal(true);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col lg="12">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                            setCount((prev) => prev + 1);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Scholarship
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}
            </Form.List>
            <div className="form-btn-container">
              <Button
                loading={loader}
                type="primary"
                onClick={onSubmit}
                disabled={count === 0}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddScholarship;
