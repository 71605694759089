import React from "react";
import moment from "moment";

// reactstrap components
import { ModalHeader, ModalBody, FormGroup, Row, Col, Modal } from "reactstrap";

const TeamMemberCard = (props) => {
  const { viewModal, data } = props;  
  console.log('data',data.createdAt);

  const toggle = () => {
    viewModal(false);
  };
  return (
    <>
      <Modal isOpen toggle={toggle} style={{ marginTop: "5%" }}>
        <ModalHeader toggle={toggle} className="modal--header">
          <h3 className="mb-0">Contact Queries</h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Date:
                </label>
                <label className="ml-2 form-control-label">
                  {moment(data.createdAt.seconds*1000).format('MMMM Do YYYY')}
{                  console.log('date',data.createdAt)
}                {/* {moment(data?.createdAt).fromNow()} */}
                  {/* {new Date().getUTCFullYear() +
                    "/" +
                    new Date().getUTCMonth() +
                    1 +
                    "/" +
                    new Date().getUTCDate()} */}
                </label>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Name:</label>
                <label className="ml-2 form-control-label">{data.name}</label>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Email:</label>
                <label className="ml-2 form-control-label">{data.email}</label>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Subject:</label>
                <label className="ml-2 form-control-label">
                  {data.subject}
                </label>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Queries:</label>
                <label className="ml-2 form-control-label">{data.query}</label>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TeamMemberCard;
