import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { Form, Input, Modal, Button } from "antd";
import ConfirmationBox from "components/ConfirmationBox";
import {
  FieldStringOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { addFacilities } from "redux/actions/facilities_action";

const Facilities = ({ setAddModalOpen, addModalOpen, fetchFacilities }) => {
  const [loading, setLoading] = useState(false);
  const [addCount, setAddCount] = React.useState(0);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const dispatch = useDispatch();
  const [uploadedFacilitiesIcons, setUploadedFacilitiesIcons] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(addFacilities(values.facilities));
    fetchFacilities();
    form.resetFields();
    setAddCount(0);
    toggle();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    const { facilities } = fields;
    if (facilities.length) {
      uploadedFacilitiesIcons.forEach((icon) => {
        Object.assign(facilities[icon.index], {
          ["logo"]: icon.logo,
        });
      });
      form.setFieldsValue({ facilities });
      form.submit();
    }
  };

  const saveLogoUrl = (url) => {
    setUploadedFacilitiesIcons([...uploadedFacilitiesIcons, url]);
  };

  const removeLogoUrl = (index) => {
    setUploadedFacilitiesIcons(
      uploadedFacilitiesIcons.filter((u) => u.index !== index)
    );
  };

  const toggle = () => {
    setAddModalOpen(false);
  };

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Modal
        visible={addModalOpen}
        onCancel={toggle}
        // className="popup-modal"
        footer={null}
        title="Add Rank"
      >
        <div className="mt-4 mb-4">
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.List name="facilities">
              {(fields, { add: addFacilities, remove: removeFacilities }) => (
                <>
                  <div key={FieldStringOutlined.index}>
                    {fields.map((field, i) => {
                      return (
                        <Row key={i}>
                          <Col lg="6">
                            <Form.Item
                              {...field}
                              name={[field.name, "title"]}
                              label="Title"
                              fieldKey={[field.fieldKey, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing facility title",
                                },
                              ]}
                            >
                              <Input placeholder="title" />
                            </Form.Item>
                          </Col>
                          <Col lg="3">
                            <Form.Item
                              {...field}
                              name={[field.name, "logo"]}
                              label="Facility Icon"
                              fieldKey={[field.fieldKey, "logo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "please upload image facility_icon",
                                },
                              ]}
                            >
                              <ImageUploader
                                form={form}
                                isInFormList={true} //on dynamic form
                                fieldKey={field.name} //on dynamic form
                                saveUrl={saveLogoUrl} //on dynamic form
                                removeUrl={removeLogoUrl} //on dynamic form
                                formField={`logo`}
                                folder="facility_icon"
                                url={
                                  form.getFieldsValue()?.facilities[field.key]
                                    ?.logo
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg="3">
                            <MinusCircleOutlined
                              onClick={() => {
                                setIsConformModal(true);
                                setConformObj({
                                  title: `Are you sure you want to delete?`,
                                  msg: "This process cannot be undone.",
                                  onSuccess: () => {
                                    removeFacilities(field.name);
                                    removeLogoUrl(field.name);
                                    setAddCount((prev) => prev - 1);

                                    setIsConformModal(false);
                                  },
                                });
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Col lg="12">
                      <Button
                        type="dashed"
                        onClick={() => {
                          addFacilities();
                          setAddCount((prev) => prev + 1);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add College Facilities
                      </Button>
                    </Col>
                  </div>
                </>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container" style={{ marginTop: "30px" }}>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={loading}
              disabled={addCount === 0}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Facilities;
