import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, Space,InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, CardHeader, CardBody, Row, Col, ModalFooter } from "reactstrap";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { editCollege } from "redux/actions/college_actions";
import ConfirmationBox from "components/ConfirmationBox";
const NotableAlumni = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const college = useSelector((state) => state.college.college);
  const [uploadedAlumni, setUploadedAlumni] = useState([]);
  const [form] = Form.useForm();
  //const { Option } = Select;
  useEffect(() => {
    form.setFieldsValue({
      alumni: college?.alumni,
    });
  }, [college, form]);

  const onFinish = async (values) => {
    setLoading(true);
    let _college = {};
    _college.id = college._id;
    _college.alumni = values.alumni;
    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  const saveLogoUrl = (url) => {
    setUploadedAlumni([...uploadedAlumni, url]);
  };

  const removeLogoUrl = (index) => {
    setUploadedAlumni(uploadedAlumni.filter((u) => u.index !== index));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    let { alumni } = fields;

    if (!alumni.includes(undefined)) {
      uploadedAlumni.forEach((icon) => {
        if (alumni[icon.index])
          Object.assign(alumni[icon.index], {
            image: icon.image,
          });
      });
      form.setFieldsValue(alumni);
    }

    form.submit();
  };
  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Our Notable Alumni</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.List name="alumni">
              {(fields, { add, remove }) => (
                <>
                  <Row>
                    {fields.map((field) => {
                      /* console.log(form.getFieldsValue().international_collaboration[field.key].logo) */

                      return (
                        <>
                          <Space
                            key={field.key}
                            // style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Col lg="12">
                              <label className="form-control-label">Name</label>
                              <Form.Item
                                {...field}
                                name={[field.name, "name"]}
                                fieldKey={[field.fieldKey, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Name name cannot be empty",
                                  },
                                ]}
                              >
                                <Input placeholder="Name" 
                                     onChange={()=>setCount (count+1)
                                     }
                                />
                              </Form.Item>
                            </Col>
                            <Col lg="12">
                              <label className="form-control-label">
                                Batch Year
                              </label>
                              <Form.Item
                                {...field}
                                name={[field.name, "batch_year"]}
                                fieldKey={[field.fieldKey, "batch_year"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Batch year cannot be empty",
                                  },
                                ]}
                              >
                                <InputNumber placeholder="Batch year example: 2016" />
                              </Form.Item>
                            </Col>
                            <Col lg="12">
                              <label className="form-control-label">
                                Image
                              </label>
                              <Form.Item
                                {...field}
                                name={[field.name, "image"]}
                                fieldKey={[field.fieldKey, "image"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "please upload image",
                                  },
                                ]}
                              >
                                <ImageUploader
                                  form={form}
                                  isInFormList={true} //on dynamic form
                                  fieldKey={field.name} //on dynamic form
                                  saveUrl={saveLogoUrl} //on dynamic form
                                  removeUrl={removeLogoUrl} //on dynamic form
                                  formField={`image`}
                                  folder="Notable-alumni"
                                  url={
                                    form.getFieldsValue()?.alumni[field.key]
                                      ?.image
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <MinusCircleOutlined
                              onClick={() => {
                                setCount((prev) => prev - 1);
                                setConformObj({
                                  title: `Are you sure you want to delete ?`,
                                  msg: "This process cannot be undone.",
                                  onSuccess: () => {
                                    remove(field.name);
                                    setIsConformModal(false);
                                  },
                                });
                                setIsConformModal(true);
                              }}
                            />
                          </Space>
                        </>
                      );
                    })}
                    <Col lg="12">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                            setCount((prev) => prev + 1);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Notable Alumni
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container">
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
               disabled={count === 0}
            >
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default NotableAlumni;
