import React from "react";
import { Card, Container, Row, Col } from "reactstrap";
import CollegeRank from "./RankTable";


const SharedInfos = () => {
  return (
    <>
      <Container className="mt--7 pb-2" fluid>
        <Row>
          <Col>
            <Card>
              <CollegeRank />
            </Card>
          </Col>
         
          
        </Row>
       
      </Container>
    </>
  );
};

export default SharedInfos;
