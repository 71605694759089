import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import {
  Form,
  Select,
  InputNumber as AntdInputNumber,
  Button,
  Input,
} from "antd";
import IndiaCityList from "../../utils/IndiaCityList";

import { useDispatch } from "react-redux";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { addCollege } from "redux/actions/college_actions";

const BasicDetails = (props) => {
  const { openAddCollege, isAddCollegeModalOpen } = props;
  const [stateOption, setStateOption] = React.useState([]);
  const [cityOption, setCityOption] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [formValues, setFormValues] = React.useState({});
  const [loader, setLoader] = React.useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoader(true);
    await dispatch(addCollege(values));
    setLoader(false);
    openAddCollege(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };


  const toggle = () => {
    openAddCollege(false);
  };
  const onCountryChange = value => {
    // console.log("change",value)
    setCountry(value);
  };

  // React.useEffect(() => {
  //   let _stateOption = [];
  //   const selectedCountry = country === "india" ? IndiaCityList : [];
  //   selectedCountry.forEach((state) => {
  //     if (_stateOption.includes(state.state)) {
  //     } else {
  //       _stateOption.push(state.state);
  //     }
  //   });
    
  //   setStateOption(_stateOption);

  //   if (formValues.country !== undefined) {
  //     form.setFieldsValue({ state: "", city: "" });
  //   }
  // }, [country]);

  // React.useEffect(() => {
  //   let _cityOption = [];
  //   IndiaCityList.forEach((state) => {
  //     if (state.state === form.getFieldsValue().state) {
  //       _cityOption.push(state.name);
  //     }
  //   });
  //   setCityOption(_cityOption);
  //   if (formValues.state !== undefined) {
  //     form.setFieldsValue({ city: "" });
  //   }
  // }, [formValues.state]);
  return (
    <>
      <Modal
        isOpen={isAddCollegeModalOpen}
        toggle={toggle}
        style={{ minWidth: "800px" }}
      >
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">College Basic Details</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            onValuesChange={(values) => setFormValues(values)}
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  label="College Name"
                  className="form-control-label"
                  name="name"
                  initialValue=""
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter College Name!",
                    },
                  ]}
                >
                  <Input placeholder="College Name..." />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="College Email"
                  name="email"
                  rules={[
                    {
                      type: "email",

                      message: "Please enter College Email",
                    },
                  ]}
                >
                  <Input placeholder="email" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: "relative" }}  >
                <Form.Item
                  className="form-control-label"
                  label="Select Country"
                  name="country"
                  rules={[
                    {
                      //type: "string",
                      required: true,
                      message: "Please select the country",
                    },
                  ]}
                >
                 
                  <Select value={country} onChange={onCountryChange}  style={{ width: "100%" }}  getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }>
                    <Select.Option value="india">India</Select.Option>
                    <Select.Option value="france">France</Select.Option>
                    <Select.Option value="canda">Canada</Select.Option>
                    <Select.Option value="australia">Australia</Select.Option>
                    <Select.Option value="uae">UAE (Dubai)</Select.Option>
                    <Select.Option value="uk">United Kingdom (UK)</Select.Option>
                    <Select.Option value="usa">United States of America (USA)</Select.Option>
                  </Select>
                
                
                </Form.Item>
                </div>
              </Col>

              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: "relative" }}>
                <Form.Item
                  className="form-control-label"
                  label="Select State"
                  name="state"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please select the state",
                    },
                  ]}
                >
                  <Input placeholder="state" />
                  
                  {/* <Select style={{ width: "100%" }} getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }>
                    {stateOption.map((state, i) => {
                      return (
                        <Select.Option value={state} key={i}>
                          {state}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
               
                </Form.Item>
                </div>
              </Col>

              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: "relative" }}>
                <Form.Item
                  className="form-control-label"
                  label="Select City"
                  name="city"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please select the city",
                    },
                  ]}
                >
                  <Input placeholder="city" />
                  
                  {/* <Select style={{ width: "100%" }} getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }>
                    {cityOption.map((city, i) => {
                      return (
                        <Select.Option value={city} key={i}>
                          {city}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
                 
                </Form.Item>
                </div>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="College Address"
                  name="address"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter College Address",
                    },
                  ]}
                >
                  <Input placeholder="address" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Estd_year"
                  name="estd_year"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "estd_year cannot be empty",
                    },
                  ]}
                >
                  <AntdInputNumber
                    placeholder="1915"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Total Course"
                  name="total_course"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "total course should be in number",
                    },
                  ]}
                >
                  <AntdInputNumber
                    placeholder="100"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="College Logo"
                  name="college_logo"
                  //onChange={handleChange}
                  rules={[
                    {
                      type: "url",
                      required: true,
                      message: "Please Upload college photo",
                    },
                  ]}
                >
                  <ImageUploader
                    form={form}
                    formField="college_logo"
                    folder="college-logo"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button type="primary" onClick={onSubmit} loading={loader}>
              Add College
            </Button>
            <Button
              type="danger"
              onClick={() => openAddCollege(false)}
              disabled={loader}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BasicDetails;
