import { message } from "antd";
import { db, storage, auth, functions } from "../../firebase";
export const addCounsellor = (values) => async (dispatch) => {
  try {
    const makeCounsellorAuthenticated = functions.httpsCallable(
      "makeCounsellorAuthenticated"
    );
    let result = await makeCounsellorAuthenticated({
      email: values.email,
      password: values.password,
    });
    // values.password = undefined
    const { password, ...data } = values;

    if (result.data.errorInfo) {
      message.error(result.data.errorInfo.message);
    } else {
      let user = await db
        .collection("counsellor")
        .doc(result.data.User.uid)
        .set(data);
      message.success("Counsellor created");
      return true;
    }
  } catch (error) {
    message.error("Failed to create the counsellor");
    // console.log(error);
    return false;
  }
};

export const getCounsellorData = () => async (dispatch) => {
  let counsellors = [];
  try {
    let querySnapshot = await db.collection("counsellor").get();
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      counsellors.push({
        id: doc.id,
        ...data,
      });
    });
    // console.log(counsellors)
    return counsellors;
  } catch (error) {
    return counsellors;
  }
};
// export const getSingleCounsellor = (id)=>{
//   return async dispatch=>{
//     console.log(id)
//   }
// }
export const getSingleCounsellor = (id) => {
  return async (dispatch) => {
    try {
      let doc = await db.collection("counsellor").doc(id).get();

      if (doc.exists) {
        let data = doc.data();
        let counsellor = {
          id: doc.id,
          ...data,
        };
        return counsellor;
      } else {
        throw Error("Counsellor not found");
      }
    } catch (error) {
      // console.log(error);
      return null;
    }
  };
};
export const editCounsellor = (values, id) => async (dispatch) => {
  try {
    const updateCounsellorAuth = functions.httpsCallable(
      "updateCounsellorAuth"
    );
    let result = await updateCounsellorAuth({
      email: values.email,
      password: values?.password,
      uid: id,
    });

    await db.collection(`counsellor`).doc(id).update({
      name: values.name,
      email: values.email,
      phone:values.phone,
      desc: values.desc,
      nationality: values.nationality,
      type: values.type,
      coverage: values.coverage,
      imageURL: values.imageURL,
    });
    return true;
  } catch (error) {
    // console.log(error);
    return false;
  }
};

export const deleteCounsellor = (id, image) => async (dispatch) => {
  try {
    const removeCounsellorFromAuth = functions.httpsCallable(
      "removeCounsellorFromAuth"
    );
    let result = await removeCounsellorFromAuth({
      uid: id,
    });

    if (result.data.message) {
      await db.collection("counsellor").doc(id).delete();
      storage.refFromURL(image).delete();
    }
    return true;
  } catch (error) {
    // console.log(error);
    return false;
  }
};
