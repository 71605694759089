import { NEWSLETTER_TYPES } from "../types";
import { NewsLetterService } from "../api/newsletter_api";
import { finish, init, success, error } from "../commonActions";

const newsLetterService = new NewsLetterService();
export const getNewsletter = () => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_TYPES.GET_NEWSLETTER,
  });
};
export const getAllNewsletter = (newsLetter) => async (dispatch) => {
  dispatch(init(NEWSLETTER_TYPES.GET_ALL_NEWSLETTER));

  const response = await newsLetterService.getAllNewsLetterList(newsLetter);
  console.log('res',response)
  dispatch(finish(NEWSLETTER_TYPES.GET_ALL_NEWSLETTER));

  if (response.isSuccess) {
    dispatch(success(NEWSLETTER_TYPES.GET_ALL_NEWSLETTER, response.data.newsletters));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};
