import React from "react";
// import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
const index = (props) => {
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
    };
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    // toolbar: {
    //   ["link", "image"]
    //   handlers: {
    //     image: imageHandler,
    //   },
    // },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      onChange={props.onChange}
      value={props?.value || ""}
    />
  );
};

export default index;
