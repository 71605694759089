import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editCollege } from "redux/actions/college_actions";
import ImageUploader from "components/ImageUploader/ImageUploader";
import ConfirmationBox from "components/ConfirmationBox";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Form, Input, Button, Space, message } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const CollegeAward = ({ setIsAwardComplete }) => {
  const [loading, setLoading] = useState(false);
  const [collegeAwardCount, setCollegeAwardCount] = useState(0);
  const dispatch = useDispatch();
  const [awardLogo, setAwardLogo] = useState([]);
  const college = useSelector((state) => state.college.college);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const { college_awards } = college;
    form.setFieldsValue({
      college_awards: college_awards,
    });
  }, [college, form]);

  const onFinish = async (values) => {
    setLoading(true);
    let _college = {};
    _college.id = college._id;
    _college.college_awards = values?.college_awards || [];
    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {};

  const onSubmit = () => {
    const fields = form.getFieldsValue();
 
   
    let { college_awards } = fields;

    if (college_awards.length) {
      awardLogo.forEach((icon) => {
        Object.assign(college_awards[icon.index], {
          ["logo"]: icon.logo,
        });
      });
      form.setFieldsValue({ college_awards });
    }
    form.submit();
  };

  const saveLogoUrl = (url) => {
    setAwardLogo([...awardLogo, url]);
  };

  const removeLogoUrl = (index) => {
    setAwardLogo(awardLogo.filter((u) => u.index !== index));
  };
  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Awards Section</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.List name="college_awards">
              {(fields, { add, remove }) => (
                <Row key={fields.index}>
                  {fields.map((field, i) => {
                    return (
                      <Space key={i} align="baseline">
                        <Col>
                          <label className="form-control-label">Title</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "title"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Title name cannot be empty",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Title name"
                              onChange={() =>
                                setCollegeAwardCount(collegeAwardCount + 1)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <label className="form-control-label">Logo</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "logo"]}
                            fieldKey={[field.fieldKey, "logo"]}
                            rules={[
                              {
                                required: true,
                                message: "please upload image logo",
                              },
                            ]}
                          >
                            <ImageUploader
                              form={form}
                              isInFormList={true} //on dynamic form
                              fieldKey={field.name} //on dynamic form
                              saveUrl={saveLogoUrl} //on dynamic form
                              removeUrl={removeLogoUrl} //on dynamic form
                              formField={`logo`}
                              folder="award"
                              url={
                                form.getFieldsValue()?.college_awards[field.key]
                                  ?.logo
                              }
                            />
                          </Form.Item>
                        </Col>

                        <MinusCircleOutlined
                          onClick={() => {
                            setCollegeAwardCount((prev) => prev - 1);
                            setConformObj({
                              title: `Are you sure you want to delete ?`,
                              msg: "This process cannot be undone.",
                              onSuccess: () => {
                                remove(field.name);
                                setIsConformModal(false);
                              },
                            });
                            setIsConformModal(true);
                          }}
                        />
                      </Space>
                    );
                  })}
                  <Col lg="12">
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          setCollegeAwardCount((prev) => prev + 1);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Awards
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Form>
          <div className="form-btn-container">
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              disabled={collegeAwardCount === 0}
            >
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default CollegeAward;
