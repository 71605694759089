import { postService, getService } from "../commonServices";

export class ScholarshipService {
  getScholarship() {
    let url = "/scholarship/getScholarship";
    let data = getService(url);
    return data;
  }

  addScholarship(scholarship_data) {
    let url = "/scholarship/addScholarship";
    let body = JSON.stringify(scholarship_data);

    let data = postService(url, body);
    return data;
  }

  deleteScholarship(id) {
    let url = `/scholarship/deleteScholarship/${id}`;

    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
