import { postService, getService } from "../commonServices";

export class Gallery {
    getGallery(college_id){
        let url =`/gallery/${college_id}`
        let data = getService(url);
        return data;
    }

    addGallery(gallery_data){
        let url ="/gallery"
        let body = JSON.stringify(gallery_data);
        let data = postService(url,body)
        return data;
    }
}