import { postService, getService } from "../commonServices";

export class StreamService {
    getStream(){
        let url = "/courses/streams";
        let data = getService(url);
        return data;
    }


    addStream(stream_data){
      let url = "/courses/streams";
      let body = JSON.stringify(stream_data);
      let data = postService(url,body)
      return data;  
    }

    deleteStream(id){
        let url =`/courses/streams/${id}`
        let data = postService(url,"DELETE");
        return data;
    }
}