import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  // Container,
} from "reactstrap";
import TextEditor from "../../components/TextEditor";
import { useSelector, useDispatch } from "react-redux";
import { getColleges } from "redux/actions/college_actions";
import { addBlog, getBlogs } from "redux/actions/blog_actions";
import { Form, Select, Input, Button } from "antd";
import ImageUploader from "components/ImageUploader/ImageUploader";

const AddBlogModal = (props) => {
  const [_colleges, set_colleges] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const colleges = useSelector((state) => state.college.colleges);
  const [blogText, setBlogText] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getColleges());
  }, []);

  useEffect(() => {
    set_colleges(
      colleges.map((clg) => ({
        label: clg.name,
        value: clg.college_slug,
      }))
    );
  }, [colleges]);
  const { setisAddBlogModalOpen, isAddBlogModalOpen } = props;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoader(true);
    let blogData = { ...values, blog_desc: blogText };
    await dispatch(addBlog({ ...blogData })); //Adding new blog
    await dispatch(getBlogs("All")); //re-rendering the blogs
    setLoader(false);
    setisAddBlogModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  const toggle = () => {
    setisAddBlogModalOpen(false);
  };

  const handleChange = (html) => {
    setBlogText(html);
  };

  const categoryOption = [
    {
      label: "Business",
      value: "business",
    },
    {
      label: "Engineering",
      value: "engineering",
    },
    // {
    //   label: "Something",
    //   value: "something",
    // },
    {
      label: "Software Dev",
      value: "software dev",
    },
    {
      label: "Science",
      value: "science",
    },
    {
      label: "Medical",
      value: "medical",
    },
    {
      label: "Others",
      value: "others",
    },
  ];

  return (
    <>
      <Modal isOpen={isAddBlogModalOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Add Blog</div>
        </ModalHeader>

        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  label="Blog title"
                  className="form-control-label"
                  name="blog_title"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter blog name!",
                    },
                  ]}
                >
                  <Input placeholder="Blog Title" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Subtitle"
                  name="blog_subtitle"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter blog subtitle",
                    },
                  ]}
                >
                  <Input placeholder="Blog Subtitle" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              {/*  */}
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Category"
                  name="category"
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select category"
                    optionFilterProp="children"
                    options={categoryOption}
                  />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  label="Author"
                  className="form-control-label"
                  name="author"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter author name!",
                    },
                  ]}
                >
                  <Input placeholder="author name" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Image"
                  name="blog_imageURL"
                  rules={[
                    {
                      required: true,
                      message: "Please upload blog image",
                    },
                  ]}
                >
                  <ImageUploader
                    form={form}
                    formField="blog_imageURL"
                    folder="blog"
                  />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Tags"
                  name="tags"
                  // rules={[
                  //   {
                  //     type: "array",
                  //     required: true,
                  //     message: "Please select the college tag!",
                  //   },
                  // ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select college"
                    optionFilterProp="children"
                    // multiple
                    options={_colleges}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .trim()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Form.Item
                  className="form-control-label"
                  label="Description"
                  name="blog_desc"
                  rules={[
                    {
                      required: true,

                      message: "Please enter blog description",
                    },
                  ]}
                >
                  <TextEditor
                    onChange={handleChange}
                    value={form.getFieldValue()?.blog_desc}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="primary" onClick={onSubmit} loading={loader}>
            Add Blog
          </Button>
          <Button
            onClick={() => setisAddBlogModalOpen(false)}
            disabled={loader}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddBlogModal;
