import React from "react";
import Layout from "../../layouts/index";
import ContactsTable from "./Contacts";

const Contacts = () => {
  return (
    <Layout>
      <ContactsTable />
    </Layout>
  );
};

export default Contacts;
