import { GET_RANK_TYPES } from "redux/types";

const initialState = {
  rank: [],
  loader: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RANK_TYPES.RANK_BEGIN:
      return {
        ...state,
        loader: true,
      };
      
    case GET_RANK_TYPES.GET_RANK:
      return {
        ...state,
        rank: payload,
        loader: false,
      };
      
    case GET_RANK_TYPES.RANK_END:
      return {
        ...state,
        loader: false,
      };
    default:
      return {
        ...state,
      };
  }
};
