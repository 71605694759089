import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editCollege } from "redux/actions/college_actions";

import { getCollegeCourses } from "redux/actions/courses_action";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Form, Select, Button } from "antd";

const TrendingCourse = () => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [fetchedCollegeCourses, setFetchedCollegeCourses] = useState([]);

  const college = useSelector((state) => state.college.college);

  const fetchCollegeCourses = async () => {
    let data = await dispatch(getCollegeCourses(college?._id));

    setFetchedCollegeCourses(data);
    console.log('data',data)
  };

  React.useEffect(() => {
    fetchCollegeCourses();
  }, []);

  React.useEffect(() => {
    if (college) {
      form.setFieldsValue({
        trending_courses: college?.trending_courses?.map(course => course?.name),
      });
    }
  }, [college]);

  const onFinish = async (values) => {
    setLoading(true);
    let trendingCourses = [];

    fetchedCollegeCourses.forEach((item) => {
      // if (values.trending_courses.filter((id) => id === item._id)) {
      const data = values.trending_courses.filter((id) => id === item._id);
      if (data.length) {
        trendingCourses.push(item);
      }
    });

    setLoading(false);
    // return console.log(trendingCourses);

    values.id = college._id;
    values.trending_courses = trendingCourses;
     console.log("TC",values.trending_courses);
    await dispatch(editCollege(values, college.college_slug));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };


  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Trending Section</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              className="form-control-label"
              label="Trending Courses"
              name="trending_courses"
              rules={[
                {
                  type: "array",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Please select the course"
                style={{ width: "100%" }}
              >
                {fetchedCollegeCourses &&
                  fetchedCollegeCourses?.map((course, i) => {
                    return (
                      <Select.Option value={course?._id} key={i}>
                        {course?.courseprogram?.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TrendingCourse;