import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editCollege } from "redux/actions/college_actions";
import TextEditor from "../../../components/TextEditor";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";

import { Form, Input, InputNumber, message, Button } from "antd";

const DetailInformation = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);
  useEffect(() => {
    const {
      description,
      overview,
      college_profile,
      affliated_by
    } = college;
    form.setFieldsValue({
      description: description,
      overview: overview,
      college_profile: college_profile,
      affliated_by: affliated_by,
    });
  }, [college, form]);
  const onFinish = (values) => {
    setLoading(true);
    values.id = college._id;
    dispatch(editCollege(values, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Detail Information</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              
              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="affliated_by"
                    label="Affiliated By"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        message: "Please input latest university ranking!",
                      },
                    ]}
                  >
                    <Input placeholder="Add University" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="12">
                {/* <div style={{ height: "295px" }}> */}
                  <Form.Item
                    className="form-control-label"
                    label="Overview"
                    name="overview"
                    rules={[
                      {
                        required: false,
                        message: "Please input description.",
                      },
                    ]}
                  >
                    <TextEditor
                      value={form?.getFieldValue()?.overview}
                      onChange={(html) =>
                        form.setFieldsValue({
                          overview: html,
                        })
                      }
                    />
                  </Form.Item>
                {/* </div> */}
              </Col>

              <Col lg="12">
                {/* <div style={{ height: "270px", paddingTop: "20px"}}> */}
                  <Form.Item
                    className="form-control-label"
                    label="Profile"
                    name="college_profile"
                    rules={[
                      {
                        required: false,
                        message: "Please input college profile.",
                      },
                    ]}
                  >
                    <TextEditor
                      value={form?.getFieldValue()?.college_profile}
                      onChange={(html) =>
                        form.setFieldsValue({
                          college_profile: html,
                        })
                      }
                    />
                  </Form.Item>
                {/* </div> */}
              </Col>

              <Col lg="12">
                {/* <div style={{ height: "285px", paddingTop: "20px"}}> */}
                  <Form.Item
                    className="form-control-label"
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Please input description.",
                      },
                    ]}
                  >
                    <TextEditor
                      value={form?.getFieldValue()?.description}
                      onChange={(html) =>
                        form.setFieldsValue({
                          description: html,
                        })
                      }
                    />
                  </Form.Item>
                {/* </div> */}
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default DetailInformation;
