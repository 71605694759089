import React from "react";
import { toast } from "react-toastify";

export const PopUp = (msg, status) => {
  return toast[status](
    <>
      {/* <img
        src={`${status === "success" ? "/success.png" : "/cancel.png"}`}
        style={{ marginRight: "1rem" }}
      /> */}
      {msg}
    </>,
    { autoClose: 2000, hideProgressBar: true }
  );
};
