import React from "react";
import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getAllStudentApplication } from "../../redux/actions/studentapplication_action";
import { getCounsellorData } from "../../redux/actions/counsellor_actions";
import { getColleges } from "../../redux/actions/college_actions";
import { getBlogs } from "../../redux/actions/blog_actions";
const Header = () => {
  const [studentApplication, setStudentApplication] = React.useState([]);
  const [counsellor, setCounsellor] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const blogList = useSelector((state) => state?.blog?.blogs);
  const collegeList = useSelector((state) => state?.college?.colleges);

  const dispatch = useDispatch();

  const fetchStudentApplication = async () => {
    setLoading(true);
    let res = await dispatch(getAllStudentApplication());
    setStudentApplication(res);
    setLoading(false);
  };

  const getFireStoreCounselor = async () => {
    const res = await dispatch(getCounsellorData());
    setCounsellor(res);
  };

  const fetchColleges = async () => {
    await dispatch(getColleges());
  };

  const fetchBlog = async () => {
    await dispatch(getBlogs("All"));
  };

  React.useEffect(() => {
    getFireStoreCounselor();
    fetchColleges();
    fetchBlog();
    fetchStudentApplication();
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
