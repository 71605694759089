import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Modal,
} from "reactstrap";
import TextEditor from "../../components/TextEditor";
import { Form, Select, Input, Button } from "antd";
import {
  updateProgram,
  getLevels,
  getStreams,
} from "redux/actions/courses_action";
const EditCourse = (props) => {
  const { course, setIsEditModalOpen, isEditModalOpen, fetchCourses } = props;
  const [loading, setLoading] = useState(false);
  const [fetchedLevels, setFetchedLevels] = useState([]); //original data
  const [fetchedStreams, setFetchedStreams] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;

  const fetchLevels = async () => {
    let data = await dispatch(getLevels());
    setFetchedLevels(data);
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  const onSelectLevel = async (level) => {
    form.setFieldsValue({ coursestream: null });
    let data = await dispatch(getStreams(level));
    setFetchedStreams(data);
  };

  const fetchCourseData = async (course) => {
    fetchLevels();
    let data = await dispatch(getStreams(course?.courselevel?._id));
    setFetchedStreams(data);
    form.setFieldsValue({
      courselevel: course?.courselevel?._id,
      coursestream: course?.coursestream?._id,
      name: course?.name,
      duration: course?.duration,
      exam_type: course?.exam_type,
      average_salary: course?.average_salary,
      selection_process: course?.selection_process,
      minimum_score: course?.minimum_score,
      overview: course?.overview,
      carrer_options: course?.carrer_options,
      program_code: course?.program_code,
      course_outline: course?.course_outline,
    });
  };

  useEffect(() => {
    fetchCourseData(course);
  }, [course]);

  const toggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const onFinish = async (values) => {
    let data = {
      ...values,
      id: course?._id,
    };
    setLoading(true);
    let response = await dispatch(updateProgram(data));
    if (response) {
      fetchCourses();
      setIsEditModalOpen(false);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Modal isOpen={isEditModalOpen} toggle={toggle} className="popup-modal">
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Edit Program</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Level"
                  name="courselevel"
                  rules={[
                    {
                      required: true,
                      message: "Level is required",
                    },
                  ]}
                >
                  <Select
                    onSelect={onSelectLevel}
                    placeholder="Select Level"
                    // disabled
                  >
                    {fetchedLevels.map((l) => {
                      return (
                        <Option key={l?._id} value={l?._id}>
                          <div style={{ textTransform: "capitalize" }}>
                            {l.name}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Stream"
                  name="coursestream"
                  rules={[
                    {
                      required: true,
                      message: "Stream is required",
                    },
                  ]}
                >
                  <Select placeholder="Select Stream" 
                  // disabled
                  >
                    {fetchedStreams.map((l) => {
                      return (
                        <Option key={l?._id} value={l?._id}>
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Program"
                  name="name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Program is required",
                    },
                  ]}
                >
                  <Input
                    // className="form-control-alternative"
                    placeholder="BBA"
                  />
                </Form.Item>
              </Col>

              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Program Code"
                  name="program_code"
                  // rules={[
                  //   {
                  //     type: "string",
                  //     required: true,
                  //     message: "Program is required",
                  //   },
                  // ]}
                >
                  <Input placeholder="Program code" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
              <div id="testPosition" style={{ position: 'relative' }}>

                <Form.Item
                  className="form-control-label"
                  label="Duration"
                  name="duration"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "duration is required",
                    },
                  ]}
                >
                    <Select placeholder="Select course duration"
                    getPopupContainer={() => document.getElementById("testPosition")}>
                      <Option value="6 Months">6 Months</Option>
                      <Option value="1 Year">1 Year</Option>
                      <Option value="2 Year">2 Years</Option>
                      <Option value="3 Year">3 Years</Option>
                      <Option value="4 Year">4 Years</Option>
                      <Option value="5 Year">5 Years</Option>
                      <Option value="6 Year">6 Years</Option>
                    </Select>
                
                </Form.Item>
                </div>
              </Col>

              <Col lg="6" xs="12">
              <div id="positioning" style={{ position: 'relative' }}>

                <Form.Item
                  className="form-control-label"
                  label="Exam Type"
                  name="exam_type"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Exam Type is required",
                    },
                  ]}
                >
                    <Select placeholder="Select exam type"
                    getPopupContainer={() => document.getElementById("positioning")}>
                      <Option value="Semester">Semester</Option>
                      <Option value="Year">Year</Option>
                    </Select>
                </Form.Item>
                </div>

              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Average Salary"
                  name="average_salary"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Average Salary is required",
                    },
                  ]}
                >
                  <Input placeholder="INR 5 To 2 Lacs Per Annum" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Selection Process"
                  name="selection_process"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Selection Process is required",
                    },
                  ]}
                >
                  <Input placeholder="Entrance Exam" />
                </Form.Item>
              </Col>

              {/* <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Minimum Score"
                  name="minimum_score"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Minimum Score is required",
                    },
                  ]}
                >
                  <Input placeholder="55%" />
                </Form.Item>
              </Col> */}

              <Col lg="12">
                <div style={{ height: "295px" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Overview"
                    name="overview"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Overview is required",
                      },
                    ]}
                  >
                    <TextEditor
                      value={form.getFieldValue().overview}
                      onChange={(html) =>
                        form.setFieldsValue({
                          overview: html,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col lg="12">
                <div style={{ height: "295px" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Course Outline"
                    name="course_outline"
                    // rules={[
                    //   {
                    //     type: "string",

                    //     message: "Course outline is required",
                    //   },
                    // ]}
                  >
                    <TextEditor
                      value={form.getFieldValue().course_outline}
                      onChange={(html) =>
                        form.setFieldsValue({
                          course_outline: html,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col lg="12">
                <div style={{ height: "295px" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Career Options"
                    name="carrer_options"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Career options is required",
                      },
                    ]}
                  >
                    <TextEditor
                      value={form.getFieldValue().carrer_options}
                      onChange={(html) =>
                        form.setFieldsValue({
                          carrer_options: html,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button loading={loading} type="primary" onClick={onSubmit}>
            {loading ? "Updating..." : "Update Program"}
          </Button>
          <Button
            type="danger"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default React.memo(EditCourse);
