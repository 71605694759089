import React from "react";
import AddTopColleges from "./AddTopColleges";
import AddTopCourses from "./AddTopCourses";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

const Index = () => {
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    <div className="card-title-detail">Top Colleges</div>
                  </div>
                </div>
              </CardHeader>
              <AddTopColleges />
            </Card>
          </Col>
          <Col>
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    <div className="card-title-detail">Top Courses</div>
                  </div>
                </div>
              </CardHeader>
              <AddTopCourses />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
