import React, { useEffect, useState, useMemo } from "react";
import { Table as AntdTable, Input, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import BasicDetails from "./BasicDetails";
import CollegeEditModal from "./CollegeEditModal/index";
import { Card, CardHeader, Media, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCollege,
  getCollege,
  getColleges,
} from "redux/actions/college_actions";
import ConfirmationBox from "../../components/ConfirmationBox";

const CollegesDetail = () => {
  const [_collegeList, setCollegeList] = useState([]);
  const collegeList = useSelector((state) => state.college.colleges);
  const loading = useSelector((state) => state.college.multiLoading);
  const dispatch = useDispatch();
  const [searchField, setSearchField] = React.useState("");
  const [isAddCollegeModalOpen, setIsAddCollegeModalOpen] = useState(false);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);

  const [isEditCollegeDrawerOpen, setIsEditCollegeDrawerOpen] = useState(false);

  const [pagination, setPagination] = useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  useEffect(() => {
    dispatch(getColleges());
  }, []);

  useEffect(() => {
    setCollegeList(collegeList);
  }, [collegeList]);

  useEffect(() => {
    setPagination({
      ...pagination,
      total: collegeList.length,
    });
  }, [collegeList.length]);

  const getCollegeNameWithLogo = () => ({
    render: (college) => {
      return (
        <>
          <div className="image-container-with-name">
            <div className="image-container">
              <img
                alt="..."
                src={college.college_logo}
                style={{ height: "100%", objectFit: "cover", width: "48px" }}
              />
            </div>
            <div className="title-meta">
              <span className="mb-0 text-sm">{college.name}</span>
            </div>
          </div>
        </>
      );
    },
  });

  const openAddCollege = (res) => {
    setIsAddCollegeModalOpen(res);
  };

  const openEditCollege = (college_slug) => {
    dispatch(getCollege(college_slug));
    setIsEditCollegeDrawerOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "College Name",
        dataIndex: "",
        width: "15%",
        ...getCollegeNameWithLogo(""),
      },
      {
        title: "Address",
        dataIndex: "address",
        width: "30%",
      },
      {
        title: "Courses",
        dataIndex: "total_course",
        width: "5%",
      },
      {
        title: "Students",
        dataIndex: "total_students",
        width: "5%",
      },
      {
        title: "Action",
        align: "right",

        dataIndex: "",
        width: "8%",
        render: (college) => (
          <div
            className="text-right site-button-ghost-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => openEditCollege(college.college_slug)}
            />
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => {
                setConformObj({
                  title: `Are you sure you want to delete ${college?.name}?`,
                  msg: "This process cannot be undone.",
                  onSuccess: async () => {
                    await dispatch(deleteCollege(college._id));
                    setIsConformModal(false);
                  },
                });
                setIsConformModal(true);
              }}
              ghost
            />
          </div>
        ),
      },
    ],
    []
  );

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const FilteredCollege = _collegeList
    ?.sort(dynamicSort("name"))
    ?.filter((college) => {
      return (
        college?.name?.toLowerCase()?.includes(searchField.toLowerCase()) ||
        college?.address?.toLowerCase()?.includes(searchField.toLowerCase())
      );
    });

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <CollegeEditModal
        isEditCollegeDrawerOpen={isEditCollegeDrawerOpen}
        setIsEditCollegeDrawerOpen={setIsEditCollegeDrawerOpen}
      />

      <BasicDetails
        isAddCollegeModalOpen={isAddCollegeModalOpen}
        openAddCollege={openAddCollege}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    <Input.Search
                      placeholder="Search by college name and address"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                  <Button onClick={() => openAddCollege(true)} type="primary">
                    Add
                  </Button>
                </div>
              </CardHeader>

              <AntdTable
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={FilteredCollege || _collegeList}
                pagination={pagination}
                loading={loading}
                size="small"
                sticky
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CollegesDetail;
