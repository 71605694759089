import { combineReducers } from "redux";
import alert from "./alert_reducer";
import college from "./college_reducer";
import blog from "./blog_reducer";
import footer from "./footer_reducer";
import scholarship from "./scholarship_reducer";
import recruit from "./recruit_reducer";
import collegeboard from "./collegeboard_reducer";
import rank from "./rank_reducer";
import facilities from "./facilities_reducer";
import gallery from "./gallery_reducer";
import universityrank from "./universityrank_reducer";
import newsLetter from "./newsletter_reducers";

const rootReducer = combineReducers({
  alert,
  college,
  blog,
  footer,
  scholarship,
  recruit,
  collegeboard,
  rank,
  facilities,
  gallery,
  universityrank,
  newsLetter
  
});

export default rootReducer;
