import { GET_FACILITIES_TYPES } from "../types";

const initialState = {
  facilities: [],
  loader: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FACILITIES_TYPES.FACILITIES_BEGIN:
      return {
        ...state,
        loader: true,
      };
    case GET_FACILITIES_TYPES.GET_FACILITIES:
      return {
        ...state,
        facilities: payload,
        loader: false,
      };
    case GET_FACILITIES_TYPES.FACILITIES_END:
      return {
        ...state,
        loader: false,
      };
    default:
      return {
        ...state,
      };
  }
};
