import { postService, getService } from "../commonServices";
export class BlogService {
  getBlogs(type) {
    let url = `/blogs/${type}`;
    let data = getService(url);
    return data;
  }

  getBlog(blog_slug) {
    let url = `/blogs/slug/${blog_slug}`; //temporarly slug is id
    let data = getService(url);
    return data;
  }

  addBlog(blog) {
    let body = blog;
    let url = `/blogs/addBlog`;
    let data = postService(url, body);

    return data;
  }

  editBlog(blog) {
    let body = blog;
    let url = `/blogs/updateBlog`;

    let data = postService(url, body, "PUT");
    return data;
  }

  deleteBlog(blog_id) {
    let url = `/blogs/deleteBlog/?id=${blog_id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
