import React from "react";
import Admin from "./Admin";
import Counsellor from "./Counsellor";
import Layout from "../../layouts/index";

const Index = ({ user }) => {
  // console.log(props)
  return (
    <>
      <Layout>{user?.admin ? <Admin /> : <Counsellor />}</Layout>
    </>
  );
};

export default Index;
