import React from "react";
import { Row, Col } from "reactstrap";

const BackgroundInformation = ({ selectedApplication }) => {
  const { backgroundInformation } = selectedApplication || {};

  return (
    <div style={{ marginTop: "35px", paddingRight: "20px" }}>
      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          marginBottom: "20px",
        }}
      >
        <b>Background Information</b>
      </div>

      <Row>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Applied For Passport :</span>
            <span className="text-detail">
              {" "}
              {backgroundInformation?.haveAppliedForPassport ? "Yes" : "No"}
            </span>
          </div>
        </Col>
        {backgroundInformation?.havePassport && (
          <>
            <Col xs="6">
              <div className="text-container">
                <span className="title">Have Passport:</span>
                <span className="text-detail">
                  {" "}
                  {backgroundInformation?.havePassport ? "Yes" : "No"}
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="text-container">
                <span className="title">CitizenID :</span>
                <span className="text-detail">
                  {" "}
                  {backgroundInformation?.citizenId}
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="text-container">
                <span className="title">Passport Name :</span>
                <span className="text-detail">
                  {" "}
                  {backgroundInformation?.passportDetails?.nameOnPassport}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="text-container">
                <span className="title">Passport Number:</span>
                <span className="text-detail">
                  {" "}
                  {backgroundInformation?.passportDetails?.numberOnPassport}
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="text-container">
                <span className="title">Passport Issue Country :</span>
                <span className="text-detail">
                  {" "}
                  {
                    backgroundInformation?.passportDetails
                      ?.passportIssuedCountry
                  }
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="text-container">
                <span className="title">Passport Issued Authority :</span>
                <span className="text-detail">
                  {" "}
                  {
                    backgroundInformation?.passportDetails
                      ?.passportIssuingAuthority
                  }
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="text-container">
                <span className="title">Passport Expire Date:</span>
                <span className="text-detail">
                  {" "}
                  {backgroundInformation?.passportDetails?.passportExpireDate}
                </span>
              </div>
            </Col>
          </>
        )}
      </Row>
      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          margin: "20px 0",
        }}
      >
        <b>References Information</b>
      </div>
      <Row>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Full Name:</span>
            <span className="text-detail">
              {" "}
              {backgroundInformation?.references?.fullName}
            </span>
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">Email :</span>
            <span className="text-detail">
              {" "}
              {backgroundInformation?.references?.emailAddress}
            </span>
          </div>
        </Col>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Address:</span>
            <span className="text-detail">
              {" "}
              {backgroundInformation?.references?.address}
            </span>
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">Phone Number:</span>
            <span className="text-detail">
              {" "}
              {backgroundInformation?.references?.phoneNumber}
            </span>
          </div>
        </Col>
      </Row>

      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          marginBottom: "20px",
        }}
      >
        <b>Documents</b>
      </div>
      <Row>
        {backgroundInformation?.documentImage && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={`${backgroundInformation?.documentImage}`}
                target="_blank"
                className="text-detail"
              >
                Certificate Document
              </a>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BackgroundInformation;
