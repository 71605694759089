import { postService, getService } from "../commonServices";

export class UniversityRankService {
  getUniversityRank() {
    let url = "/ranking/getallrankings";
    let data = getService(url);
    return data;
  }


  addUniversityRank(universityrank_data) {
    let url = "/ranking/addranking";
    let body = JSON.stringify(universityrank_data);
    let data = postService(url, body);
    return data;
  }


  deleteUniversityRank(id) {
    let url = `/ranking/deleteranking/${id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
