import React, { useMemo } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const _routes = useMemo(() => {
    let isAdmin = props.user.admin;
    let isCounsellor = props.user.isCounsellor;
    let roleBasedRoutes = [];
    routes.forEach((prop, key) => {
      if (
        isAdmin &&
        (prop.canBeUsedBy === "admin" ||
          prop.canBeUsedBy === "admin/counsellor")
      ) {
        roleBasedRoutes.push(prop);
      } else if (
        isCounsellor &&
        (prop.canBeUsedBy === "counsellor" ||
          prop.canBeUsedBy === "admin/counsellor")
      ) {
        roleBasedRoutes.push(prop);
      }

      prop.subNavBar &&
        prop.subNavBar.forEach((sub, key) => {
          if (
            isAdmin &&
            (sub.canBeUsedBy === "admin" ||
              sub.canBeUsedBy === "admin/counsellor")
          ) {
            roleBasedRoutes.push(sub);
          } else if (
            isCounsellor &&
            (sub.canBeUsedBy === "counsellor" ||
              sub.canBeUsedBy === "admin/counsellor")
          ) {
            roleBasedRoutes.push(sub);
          }
        });
    });
    return roleBasedRoutes;
  }, [props.user]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const currentRoute = window.location.href;

  React.useEffect(() => {
    localStorage.setItem("current-route", currentRoute);
  }, [currentRoute]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          render={(_props) => {
            return <prop.component {..._props} user={props.user} />;
          }}
          // component={prop.component}
          key={key}
        />
      );
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < _routes.length; i++) {
      if (
        props.location.pathname.indexOf(_routes[i].layout + _routes[i].path) !==
        -1
      ) {
        return _routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={_routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(_routes)}

          {/* <Redirect from="*" to="/admin/index" /> */}
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default React.memo(Admin);
