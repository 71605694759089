import { message } from "antd";
import { db, storage } from "../../firebase";

export const getTeammember = () => async (dispatch) => {
  let teammember = [];
  try {
    const res = await db.collection("team_member").get();
    res.forEach(function (doc) {
      const data = doc.data();

      teammember.push({
        id: doc.id,
        ...data,
      });
    });
    return teammember;
  } catch (err) {
    return err;
  }
};

export const addTeammeber = (values) => async (dispatch) => {
  try {
    let res = db.collection("team_member").add({
      createdAt: new Date(),
      name: values.name,
      email: values.email,
      description: values.description,
      type: values.type,
      facebook_link: values.facebook_link || '',
      insta_link: values.insta_link || '',
      twitter_link: values.twitter_link || '',
      medium_link: values.medium_link || '',
      image: values.imageURL,
      youtube_link: values.youtube_link || '',
      linkedin_link: values.linkedin_link || '',
    });
    message.success("Team member added");
    return res;
  } catch (err) {
    message.error("Failed to add team member");
    return err;
  }
};

export const singleTeammember = (id) => async (dispatch) => {
  try {
    let doc = await db.collection("team_member").doc(id).get();

    if (doc.exists) {
      let data = doc.data();
      let teammember = {
        id: doc.id,
        ...data,
      };

      return teammember;
    } else {
      return "Team member not found";
    }
  } catch (error) {
    return error;
  }
};

export const editTeammember = (id, values) => async (dispatch) => {
  try {
    await db.collection("team_member").doc(id).update({
      name: values.name,
      email: values.email,
      description: values.description,
      type: values.type,
      facebook_link: values.facebook_link,
      insta_link: values.insta_link,
      twitter_link: values.twitter_link,
      medium_link: values.medium_link,
      image: values.imageURL,
      youtube_link: values.youtube_link,
      linkedin_link: values.linkedin_link,
    });
    message.success("Team member updated");
    return true;
  } catch (error) {
    message.error("Failed to update team member");
    // console.log(error);
    return false;
  }
};

export const deleteTeammember = (id, image) => async (dispatch) => {
  try {
    await db.collection("team_member").doc(id).delete();
    await storage.refFromURL(image).delete();
    message.success("Team member deleted");
    return true;
  } catch (error) {
    message.error("Failed to delete team member");
    return error;
  }
};
