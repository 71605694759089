import { getService } from "../commonServices";

export class NewsLetterService {
  getNewsletter() {
    let url = `newsletter`;
    let data = getService(url);
    return data;
  }
  getAllNewsLetterList(){
    let url = `/newsletter`
    let data = getService(url);
    return data;
  }
}
