import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, Space,message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, CardHeader, CardBody, Row, Col, ModalFooter } from "reactstrap";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { editCollege } from "redux/actions/college_actions";
import ConfirmationBox from "components/ConfirmationBox";

const InternationalCollaboration = () => {
  const dispatch = useDispatch();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const college = useSelector((state) => state.college.college);
  const [uploadedCollab, setUploadedCollab] = useState([]);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [form] = Form.useForm();
  //const { Option } = Select;
  useEffect(() => {
    form.setFieldsValue({
      international_collaboration: college?.international_collaboration,
    });
  }, [college, form]);

  const onFinish = async (values) => {
    
    setLoading(true);
    let _college = {};
    _college.id = college._id;
    _college.international_collaboration = values?.international_collaboration || [];
      // ? values.international_collaboration
      // : [];

    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {};

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    console.log( {fields})
    

    let { international_collaboration } = fields;

    if (international_collaboration.length) {
      uploadedCollab.forEach((icon) => {
        if (international_collaboration[icon.index])
          Object.assign(international_collaboration[icon.index], {
            logo: icon.logo,
          });
      });
      form.setFieldsValue({ international_collaboration });
    }
    form.submit();

  };

  const saveLogoUrl = (url) => {
    setUploadedCollab([...uploadedCollab, url]);
  };

  const removeLogoUrl = (index) => {
    setUploadedCollab(uploadedCollab.filter((u) => u.index !== index));
  };
  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">International Collaboration</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form 
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <div className="pl-lg-4">
              <Form.List name="international_collaboration">
                {(fields, { add, remove }) => (
                  <Row key={fields.index}>
                    {fields.map((field, i) => {
                      return (
                        <Space key={i} align="baseline">
                          <Col lg="12">
                            <Form.Item
                              {...field}
                              label="Title"
                              name={[field.name, "title"]}
                              fieldKey={[field.fieldKey, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Title name cannot be empty",
                                },
                              ]}
                              
                            >
                              <Input placeholder="Title Name" 
                               onChange={()=>setCount (count+1)
                                }
                               />
                            </Form.Item>
                          </Col>
                          <Col lg="12">
                            <Form.Item
                              {...field}
                              label="Logo"
                              name={[field.name, "logo"]}
                              fieldKey={[field.fieldKey, "logo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "please upload image logo",
                                },
                              ]}
                            >
                              <ImageUploader
                                form={form}
                                isInFormList={true} //on dynamic form
                                fieldKey={field.name} //on dynamic form
                                saveUrl={saveLogoUrl} //on dynamic form
                                removeUrl={removeLogoUrl} //on dynamic form
                                formField={`logo`}
                                folder="international-collab"
                                url={
                                  form.getFieldsValue()
                                    ?.international_collaboration[field.key]
                                    ?.logo
                                }
                              />
                            </Form.Item>
                          </Col>

                          <MinusCircleOutlined
                            onClick={() => {
                              setCount((prev) => prev - 1);
                              setConformObj({
                                title: `Are you sure you want to delete ?`,
                                msg: "This process cannot be undone.",
                                onSuccess: () => {
                                  remove(field.name);
                                  setIsConformModal(false);
                                },
                              });
                              setIsConformModal(true);
                            }}
                          />
                        </Space>
                      );
                    })}
                    <Col lg="12">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                            setCount((prev) => prev + 1);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add International Collaboration
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </div>
          </Form>
          <div className="form-btn-container">
            
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              disabled={count === 0 }
            >
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default InternationalCollaboration;
