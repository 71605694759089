import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "components/TextEditor";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { mailToSubcribers } from "redux/actions/sendmail_actions";
import { Form, Select, Button, Input } from "antd";
import {
  getColleges
} from "redux/actions/college_actions";
const SendMailModal = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    isSendMailModalOpen,
    setIsSendMailModalOpen,
    selectedColleges,
  } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [_collegeList, setCollegeList] = React.useState([]);
  const collegeList = useSelector((state) => state.college.colleges);

  React.useEffect(() => {
    if (selectedColleges) {
      form.setFieldsValue({
        email: selectedColleges.map((email) => email),
      });
    }
  }, [selectedColleges]);
 React.useEffect(() => {
    dispatch(getColleges());
  }, []);

  React.useEffect(() => {
    setCollegeList(collegeList);
  }, [collegeList]);


  const onFinish = async (values) => {
    const {college,subject,offer} = values;
  let emailData = {college,subject,offer}

    setLoading(true);
    // emailData = [...new Set(emailData)];

    
    await dispatch(mailToSubcribers(emailData));
   
    setLoading(false);
    toggle();
    form.resetFields()
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };
  const onSubmit = () => {
    
    form.submit();
   // form.resetFields()
  };
  const toggle = () => {
    setIsSendMailModalOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={isSendMailModalOpen}
        toggle={toggle}
        className="popup-modal"
        des
      >
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Send Mail</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col log="12">
                <Form.Item
                  label="College Name"
                  className="form-control-label"
                  name="college"
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Field cannot be empty",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Please select"
                    // defaultValue={selectedColleges}
                  >
                    {collegeList.map((item, i) => {
                      return (
                        <Select.Option value={item.name} key={i}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg="12">
                <Form.Item
                  label="Subject"
                  className="form-control-label"
                  name="subject"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Subject field cannot be empty",
                    },
                  ]}
                >
                  <Input placeholder="Subject" />
                </Form.Item>
              </Col>

              <Col lg="12">
                <label>
                  <b>Offer:</b>
                </label>
                <Form.Item
                  className="form-control-label"
                  name="offer"
                  rules={[
                    {
                      type: "string",
                       required: true,
                      message: "Offers field cannot be empty",
                    },
                  ]}
                >
                  <TextEditor
                    onChange={(html) => {
                      form.setFieldsValue({
                        body: html,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button loading={loading} type="primary" onClick={onSubmit}>
            Send Email
          </Button>
          <Button
            type="danger"
            onClick={() => setIsSendMailModalOpen(false)}
            disabled={loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SendMailModal;
