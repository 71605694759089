import React, { useMemo } from "react";
import {
  deleteTestimonials,
  getAllTestimonials,
} from "../../redux/actions/testimonial_action";
import { useDispatch } from "react-redux";
import _ from "lodash";
import AddEditTestimonialModal from "./AddEditTestimonialModal";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, CardHeader, Container, Row } from "reactstrap";
import ConfirmationBox from "components/ConfirmationBox";
import { Table as AntdTable, Button, Input } from "antd";

const TestomonialDetails = () => {
  const [isAddEditModalOpen, setIsAddEditModalOpen] = React.useState(false);
  const [testimonialList, setTestimonialList] = React.useState([]);

  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [testimonialId, setTestimonialId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const pagination = {
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const dispatch = useDispatch();

  const fetchTestimonial = async () => {
    setLoading(true);
    const testimonialList = await dispatch(getAllTestimonials());
    setTestimonialList(testimonialList.testimonials);
    setLoading(false);
  };
  React.useEffect(() => {
    fetchTestimonial();
  }, []);

  const handleAddEditModal = (res) => {
    setIsAddEditModalOpen(res);
  };

  const handleDelete = async (_id) => {
    setIsConformModal(true);

    setConformObj({
      title: `Are you sure you want to delete?`,
      msg: "This process cannot be undone.",
      onSuccess: async () => {
        setDeleteLoader(true);
        await dispatch(deleteTestimonials(_id));
        fetchTestimonial();
        setDeleteLoader(false);
        setIsConformModal(false);
      },
    });
  };

  const getNameWithImage = () => ({
    render: (testimonialList) => {
      return (
        <>
          <div className="image-container-with-name">
            <div className="image-container">
              <img
                alt={`${testimonialList.name}_logo`}
                src={testimonialList.image}
              />
            </div>
            <div className="title-meta">
              <span className="mb-0">{testimonialList.name}</span>
            </div>
          </div>
        </>
      );
    },
  });

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "",
        width: "20%",
        ...getNameWithImage(""),
      },
      {
        title: "Designation",
        dataIndex: "testimonial_type",
        width: "20%",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "20%",
        render: (description) => `${description.substring(0, 20)}...`,
      },
      {
        title: "Video url",
        dataIndex: "video_url",
        width: "22%",
      },
      { title: "University Name", dataIndex: "university_name", width: "22%" },
      {
        title: "Action",
        dataIndex: "",
        className: "table-title",
        width: "8%",
        render: (testimonialList) => (
          <>
            <div className="text-right site-button-ghost-wrapper">
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  setTestimonialId(testimonialList._id);
                  setIsAddEditModalOpen(true);
                }}
              />
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleDelete(testimonialList._id, testimonialList.image);
                }}
              />
            </div>
          </>
        ),
      },
    ],
    []
  );

  const filteredData = testimonialList
    ?.sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds)
    .filter((item) => {
      return (
        item?.name.toLowerCase().includes(searchField.toLowerCase()) ||
        item?.designation?.toLowerCase().includes(searchField.toLowerCase())
      );
    });
  return (
    <>
      <AddEditTestimonialModal
        isAddEditModalOpen={isAddEditModalOpen}
        handleAddEditModal={handleAddEditModal}
        testimonialId={testimonialId}
        setTestimonialId={setTestimonialId}
        fetchTestimonial={fetchTestimonial}
      />
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
        loader={deleteLoader}
      />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    <Input.Search
                      placeholder="Search by name, email and coverage"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setTestimonialId("");
                      setIsAddEditModalOpen(true);
                    }}
                    type="primary"
                  >
                    Add
                  </Button>
                </div>
              </CardHeader>

              <AntdTable
                // className="align-items-center table-flush"
                columns={columns}
                rowKey={(testimonialList) => testimonialList.id}
                dataSource={filteredData}
                pagination={pagination}
                loading={loading}
                size="small"
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TestomonialDetails;
