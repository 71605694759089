import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBlogs, deleteBlog } from "redux/actions/blog_actions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Input, Button, Table } from "antd";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import AddBlogModel from "./AddBlogModal";
import ConfirmationBox from "components/ConfirmationBox";
import BlogEdit from "./BlogEdit";
import moment from "moment";

const BlogsDetail = () => {
  const [isEditModal, setIsEditModal] = React.useState(false);
  const [blogSlug, setBlogSlug] = React.useState("");

  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);

  const pagination = {
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const [searchKeyword, setSearchKeyword] = React.useState("");
  const dispatch = useDispatch();

  const fetchBlogs = async () => {
    await dispatch(getBlogs("All"));
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const blogList = useSelector((state) => state.blog.blogs);
  const loading = useSelector((state) => state.blog.multiLoading);

  const [isAddBlogModalOpen, setisAddBlogModalOpen] = React.useState(false);

  function truncateString(str, num) {
    if (str?.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const getTitleWithImage = () => ({
    render: (blog) => {
      return (
        <>
          <div className="image-container-with-name">
            <div className="image-container">
              <img alt="..." src={blog.blog_imageURL} />
            </div>
            <div className="title-meta">
              <div className="mb-0 tecounsellorIdt-sm">{blog.author}</div>
              <div className="blog-created-time">
                {moment(blog?.createdAt).fromNow()}
              </div>
            </div>
          </div>
        </>
      );
    },
  });

  const removeHtmlChar = (text) => {
    return text?.replace(/<[^>]+>/g, "");
  };
  const columns = React.useMemo(
    () => [
      {
        title: "Author Name",
        dataIndex: "",
        width: "20%",
        ...getTitleWithImage(""),
      },
      {
        title: "Blog Title",
        dataIndex: "blog_title",
        width: "30%",
      },
      {
        title: "Category",
        dataIndex: "category",
        width: "15%",
      },
      {
        title: "Description",
        dataIndex: "",
        width: "40%",
        render: (blog) => truncateString(removeHtmlChar(blog?.blog_desc), 80),
      },
      {
        title: "Action",
        dataIndex: "",
        width: "8%",
        className: "table-title",
        render: (blog) => (
          <div className="text-right site-button-ghost-wrapper">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setBlogSlug(blog?.blog_slug);
                setIsEditModal(true);
              }}
            />
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => {
                setIsConformModal(true);

                setConformObj({
                  title: `Are you sure you want to delete ${blog?.author}?`,
                  msg: "This process cannot be undone.",
                  onSuccess: async () => {
                    await dispatch(deleteBlog(blog._id, blog.imageURL));
                    fetchBlogs();
                    setIsConformModal(false);
                  },
                });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const latestBlog = blogList
    ?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
    .filter((blog) => {
      return (
        blog?.author.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        blog?.blog_title?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        blog?.category?.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    });

  return (
    <>
      <BlogEdit
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
        blogSlug={blogSlug}
        fetchBlogs={fetchBlogs}
      />
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
        loader={loading}
      />
      {isAddBlogModalOpen && (
        <AddBlogModel
          isAddBlogModalOpen={isAddBlogModalOpen}
          setisAddBlogModalOpen={setisAddBlogModalOpen}
        />
      )}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    <Input.Search
                      placeholder="Search by author name, title and category"
                      enterButton
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                    />{" "}
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setisAddBlogModalOpen(true)}
                  >
                    Add
                  </Button>
                </div>
              </CardHeader>

              <Table
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={latestBlog}
                pagination={pagination}
                loading={loading}
                size="small"
                scroll={{ x: true }}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogsDetail;
