import { BLOG_TYPES, SUCCESS, BLOGS_TYPES, ADD_BLOG_TYPES } from "../types";
import { finish, init, success, error } from "../commonActions";
import { storage } from "../../firebase";
import { BlogService } from "../api/blog_api";
import { message } from "antd";

const blogService = new BlogService();
export const getBlogs = (type) => async (dispatch) => {
  dispatch(init(BLOGS_TYPES.GET_BLOGS));
  const response = await blogService.getBlogs(type);
  dispatch(finish(BLOGS_TYPES.GET_BLOGS));

  if (response.isSuccess) {
    dispatch(success(BLOGS_TYPES.GET_BLOGS, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const getBlog = (blog_slug) => async (dispatch) => {
  dispatch(init(BLOG_TYPES.GET_BLOG));

  const response = await blogService.getBlog(blog_slug);

  dispatch(finish(BLOG_TYPES.GET_BLOG));

  if (response.isSuccess) {
    dispatch(success(BLOG_TYPES.GET_BLOG, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const addBlog = (blog) => async (dispatch) => {
  // dispatch(init(ADD_BLOG_TYPES.ADD_BLOG));
  const response = await blogService.addBlog(blog);

  dispatch(finish(ADD_BLOG_TYPES.ADD_BLOG));

  if (response.isSuccess) {
    message.success("New blog added");
    dispatch(success(SUCCESS, "Blog has been added successfully."));
  } else if (!response.isSuccess) {
    message.error("Failed to add");
    dispatch(error(response.errorMessage));
  }
};

export const editBlog = (blog) => async (dispatch) => {
  dispatch(init(ADD_BLOG_TYPES.ADD_BLOG));

  const response = await blogService.editBlog(blog);

  dispatch(finish(ADD_BLOG_TYPES.ADD_BLOG));

  if (response.isSuccess) {
    message.success("Blog updated");
    dispatch(success(SUCCESS, "Blog has been updated successfully."));
  } else if (!response.isSuccess) {
    message.error("Failed to update");
    dispatch(error(response.errorMessage));
  }
};

export const deleteBlog = (id, image) => async (dispatch) => {
  dispatch(init(BLOGS_TYPES.GET_BLOGS)); //as we need to show loading on card
  const response = await blogService.deleteBlog(id);
  // await storage.refFromURL(image).delete();
  dispatch(finish(BLOGS_TYPES.GET_BLOGS));
  if (response.isSuccess) {
    dispatch(success(SUCCESS, "Your Blog has been deleted successfully."));
    message.success("Blog deleted");
  } else if (!response.isSuccess) {
    message.error("Failed to delete");
    dispatch(error(response.errorMessage));
  }
};
