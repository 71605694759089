import { GET_SCHOLARSHIP_TYPES } from "../types";

const initialState = {
  scholarship: [],
  loader: false,
};
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SCHOLARSHIP_TYPES.SCHOLARSHIP_BEGIN:
      return {
        ...state,
        loader: true,
      };
    case GET_SCHOLARSHIP_TYPES.GET_SCHOLARSHIP:
      return {
        ...state,
        scholarship: payload,
        loader: false,
      };
    case GET_SCHOLARSHIP_TYPES.SCHOLARSHIP_END:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};
