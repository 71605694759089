import React from "react";
import { Button } from "antd";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const index = ({ data, setIsConformModal, isConformModal, loader }) => {
  // console.log({ data });
  return (
    <>
      <Modal isOpen={isConformModal}>
        <ModalHeader>{data.title}</ModalHeader>
        <ModalBody>{data.msg}</ModalBody>
        <ModalFooter>
          <Button type="primary" onClick={data.onSuccess} loading={loader}>
            Ok
          </Button>
          <Button
            type="danger"
            onClick={() => setIsConformModal(false)}
            disabled={loader}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default index;
