import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";
import { Form, Input, Select, Divider, Radio, InputNumber, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import IndiaCityList from "../../../utils/IndiaCityList";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { editCollege } from "redux/actions/college_actions";
import {
  addCollegeBoard,
  getCollegeBoard,
} from "redux/actions/collegeboard_action";

const BasicInfo = () => {
  const [loading, setLoading] = useState(false);
  const [stateOption, setStateOption] = React.useState([]);
  const [cityOption, setCityOption] = React.useState([]);
  const [formValues, setFormValues] = React.useState({});
  const [collegeBoard, setCollegeBoard] = React.useState("");
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);
  const collegeBoardArray = useSelector(
    (state) => state.collegeboard.collegeBoard
  );
  const collegeBoardLoader = useSelector((state) => state.collegeboard.loader);

  const [form] = Form.useForm();
  const [countryState, setCountryState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [countryCity, setCountryCity] = React.useState("");
  React.useEffect(() => {
    dispatch(getCollegeBoard());
  }, [form]);

  const onCountryChange = (value) => {
    setCountry(value);
  };
  const handleFormChange = (values) => {
    setFormValues(values);
  };

  const handleStateChange = (values) => {
    setCountryState(values);
    form.setFieldsValue({ state: values });
  };

  const handleCityChange = (values) => {
    setCountryCity(values);
    form.setFieldsValue({ city: values });
  };

  React.useEffect(() => {
    const {
      name,
      email,
      address,
      estd_year,
      total_course,
      city,
      country,
      state,
      total_students,
      placement_percentage,
      graduation_percentage,
      average_fee,
      college_logo,
      institution_type,
      college_board,
    } = college;

    form.setFieldsValue({
      name: name,
      address: address,
      estd_year: estd_year,
      total_course: total_course,
      total_students: total_students,
      placement_percentage: placement_percentage,
      graduation_percentage: graduation_percentage,
      average_fee: average_fee,
      college_logo: college_logo,
      email: email,
      institution_type: institution_type,
      state: state,
      country: country,
      city: city,
      college_board: college_board,
    });
    setCountry(form.getFieldValue().country);
    setCountryState(form.getFieldValue().state);
    setCountryCity(form.getFieldValue().city);
  }, [college, form]);

  React.useEffect(() => {
    let _stateOption = [];
    const selectedCountry = country === "india" ? IndiaCityList : [];
    selectedCountry.forEach((state) => {
      if (_stateOption.includes(state.state)) {
      } else {
        _stateOption.push(state.state);
      }
    });

    setStateOption(_stateOption);

    // if (formValues.country !== undefined) {
    //   form.setFieldsValue({ state: "", city: "" });
    // }
  }, [country]);

  React.useEffect(() => {
    let _cityOption = [];
    IndiaCityList.forEach((state) => {
      if (state.state === countryState) {
        _cityOption.push(state.name);
      }
    });
    setCityOption(_cityOption);
    // if (formValues.state !== undefined) {
    //   form.setFieldsValue({ city: "" });
    // }
  }, [countryState]);

  const onFinish = async (values) => {
    setLoading(true);
    values.id = college._id;
    await dispatch(editCollege(values, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  const handleAddCollegeBoard = async () => {
    if (collegeBoard) {
      await dispatch(addCollegeBoard({ title: collegeBoard }));
      await dispatch(getCollegeBoard());
      setCollegeBoard("");
    }
  };
  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Basic Info</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            onValuesChange={handleFormChange}
          >
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    label="College Name"
                    name="name"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Please input college name!",
                      },
                    ]}
                  >
                    <Input placeholder="Modi. University" />
                  </Form.Item>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="email"
                    label="College Email"
                    rules={[
                      {
                        type: "email",
                        // required: true,
                        message: "Please input college email!",
                      },
                    ]}
                  >
                    <Input placeholder="College@gmail.com" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    label="ESTD Year"
                    name="estd_year"
                    rules={[
                      {
                        type: "number",
                        // required: true,
                        message: "Please input college estd year",
                      },
                    ]}
                  >
                    <InputNumber placeholder="1995" style={{ width: "100%" }} />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="total_course"
                    label="Total Course"
                    rules={[
                      {
                        type: "number",
                        // required: true,
                        message: "Please input college total courses!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="500" style={{ width: "100%" }} />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6" xs="12">
                <div id="positioning" style={{ position: "relative" }}>
                  <Form.Item
                    className="form-control-label"
                    label="Select Country"
                    name="country"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        message: "Please select the country",
                      },
                    ]}
                  >
                    <Select
                      value={country}
                      onChange={onCountryChange}
                      style={{ width: "100%" }}
                      placeholder="Select country"
                    >
                      <Select.Option value="india">India</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Select State"
                  name="state"
                  rules={[
                    {
                      type: "string",
                      required: false,
                      message: "Please select the state",
                    },
                  ]}
                >
                  <div id="positioning" style={{ position: "relative" }}>
                    <Select
                      value={countryState}
                      onChange={handleStateChange}
                      style={{ width: "100%" }}
                      placeholder="Select state"
                      getPopupContainer={() =>
                        document.getElementById("positioning")
                      }
                    >
                      {stateOption.map((state, i) => {
                        return (
                          <Select.Option value={state} key={i}>
                            {state}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Select City"
                  name="city"
                  rules={[
                    {
                      type: "string",
                      required: false,
                      message: "Please select the city",
                    },
                  ]}
                >
                  <div id="positioning" style={{ position: "relative" }}>
                    <Select
                      value={countryCity}
                      onChange={handleCityChange}
                      getPopupContainer={() =>
                        document.getElementById("positioning")
                      }
                      style={{ width: "100%" }}
                      placeholder="Select city"
                    >
                      {cityOption.map((city, i) => {
                        return (
                          <Select.Option value={city} key={i}>
                            {city}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="address"
                    label="College Address"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Please input college address!",
                      },
                    ]}
                  >
                    <Input placeholder="9063 Jason Locks\nWest Gregoryview, AL 51403" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="total_students"
                    label="Total Student"
                    rules={[
                      {
                        type: "number",
                        // required: true,
                        message: "Please write your total student number!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="1005" style={{ width: "100%" }} />
                  </Form.Item>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="placement_percentage"
                    label="Placement Percentage"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Please enter your placement percentage",
                      },
                    ]}
                  >
                    <Input placeholder="95%" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="graduation_percentage"
                    label=" Graduation Percentage"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please enter your graduation percentage",
                      },
                    ]}
                  >
                    <Input placeholder="95%" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="average_fee"
                    label="Average Fee"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Average fee should not be empty",
                      },
                    ]}
                  >
                    <Input placeholder="4 L" />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    className="form-control-label"
                    name="institution_type"
                    label="Institution Type"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please select institution type",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <div className="institution-type-container">
                        <Radio value="Central University">
                          Central University
                        </Radio>
                        <Radio value="State University">State University</Radio>
                        <Radio value="Public Institute">Public Institute</Radio>
                        <Radio value="Private Institute">
                          Private Institute
                        </Radio>
                        <Radio value="Autonomous Institute">
                          Autonomous Institute
                        </Radio>
                      </div>
                    </Radio.Group>
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    name="college_logo"
                    valuePropName="fileList"
                    label="Upload Logo"
                    //getValueFromEvent={normFile}
                  >
                    <ImageUploader
                      form={form}
                      formField="college_logo"
                      folder="college-logo"
                      url={college?.college_logo}
                    />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <div id="testPosition" style={{ position: "relative" }}>
                  <Form.Item
                    name="college_board"
                    label="College Board"
                    rules={[
                      {
                        type: "array",
                        required: true,
                        message: "College field should not be empty",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      loading={collegeBoardLoader}
                      style={{ width: 240 }}
                      placeholder="Select College Board"
                      getPopupContainer={() =>
                        document.getElementById("testPosition")
                      }
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div className="college-board-container">
                            <Input
                              style={{ flex: "auto" }}
                              onChange={(e) => setCollegeBoard(e.target.value)}
                              value={collegeBoard}
                            />
                            <a
                              className="add-college-board "
                              onClick={handleAddCollegeBoard}
                            >
                              <PlusOutlined /> Add item
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {collegeBoardArray &&
                        collegeBoardArray?.map((item,index) => (
                          <Select.Option key={index}>
                            {item.title}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default BasicInfo;
