import React from "react";
import { useDispatch } from "react-redux";
import {
  FacebookFilled,
  InstagramFilled,
  TwitterSquareFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { singleTeammember } from "redux/actions/team_member";

// reactstrap components
import { Card, CardBody, Row, Col, Modal } from "reactstrap";

const TeamMemberCard = ({ isViewModalOpen, setIsViewModalOpen, id }) => {
  const [data, setData] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const dispatch = useDispatch();

  const getSingleTeamMember = async () => {
    const res = await dispatch(singleTeammember(id));

    setData(res);
    setLoader(false);
  };

  React.useEffect(() => {
    getSingleTeamMember();
  }, [id]);

  const toggle = () => {
    setIsViewModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isViewModalOpen} toggle={toggle} className="popup-modal">
        {loader ? (
          <p>asdasdasd</p>
        ) : (
          <Card className="shadow">
            <Row className="justify-content-center">
              <Col>
                <div className="teammember-view-card-image-container">
                  <img alt="..." className="rounded-circle" src={data.image} />
                </div>
              </Col>
            </Row>
            <CardBody className="pt-2">
              <Row>
                <div className="col">
                  <div className="team-member-view-social-link">
                    {data?.facebook_link && (
                      <a href={data?.facebook_link} target="_blank">
                        <FacebookFilled style={{ fontSize: "26px" }} />
                      </a>
                    )}
                    {data?.twitter_link && (
                      <a href={data?.twitter_link} target="_blank">
                        <TwitterSquareFilled style={{ fontSize: "26px" }} />
                      </a>
                    )}
                    {data?.insta_link && (
                      <a href={data?.insta_link} target="_blank">
                        <InstagramFilled style={{ fontSize: "26px" }} />
                      </a>
                    )}
                    {data?.youtube_link && (
                      <a href={data?.youtube_link} target="_blank">
                        <YoutubeFilled style={{ fontSize: "26px" }} />
                      </a>
                    )}
                  </div>
                </div>
              </Row>
              <div className="text-center">
                <h3>{data.name}</h3>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2" />
                  {data.email}
                </div>
                <div className="h5 mt-4">Counsellor Type: {data.type}</div>

                <div className="h5 mt-4">Medium: {data.medium_link}</div>

                <hr className="my-4" />
                <p>{data.description}</p>
              </div>
            </CardBody>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default TeamMemberCard;
