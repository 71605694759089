import {
  COLLEGE_TYPES,
  SUCCESS,
  COLLEGES_TYPES,
  ADD_COLLEGE_TYPES,
} from "../types";
import { db } from "../../firebase";
import { finish, init, success, error } from "../commonActions";
import { CollegeService } from "../api/college_api";
import { message } from "antd";

const collegeService = new CollegeService();
export const getColleges = () => async (dispatch) => {
  dispatch(init(COLLEGES_TYPES.GET_COLLEGES));
  const response = await collegeService.getColleges();
  dispatch(finish(COLLEGES_TYPES.GET_COLLEGES));

  if (response.isSuccess) {
    dispatch(success(COLLEGES_TYPES.GET_COLLEGES, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const getCollege = (college_slug) => async (dispatch) => {
  dispatch(init(COLLEGE_TYPES.GET_COLLEGE));

  const response = await collegeService.getCollege(college_slug);

  dispatch(finish(COLLEGE_TYPES.GET_COLLEGE));

  if (response.isSuccess) {
    dispatch(success(COLLEGE_TYPES.GET_COLLEGE, response.data));
    console.log("res", response.data);
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const addCollege = (college) => async (dispatch) => {
  dispatch(init(ADD_COLLEGE_TYPES.ADD_COLLEGE));

  const response = await collegeService.addCollege(college);

  dispatch(finish(ADD_COLLEGE_TYPES.ADD_COLLEGE));

  if (response.isSuccess) {
    message.success("New college added");
    dispatch(success(SUCCESS, "College has been added successfully."));

    dispatch(getColleges());
  } else if (!response.isSuccess) {
    message.error("Failed to add new college");
    dispatch(error(response.errorMessage));
  }
};

export const deleteCollege = (id) => async (dispatch) => {
  dispatch(init(COLLEGES_TYPES.GET_COLLEGES)); //as we need to show loading on table
  const response = await collegeService.deleteCollege(id);

  dispatch(finish(COLLEGES_TYPES.GET_COLLEGES));
  if (response.isSuccess) {
    message.success("College deleted");
    dispatch(success(SUCCESS, "Your product has been deleted successfully."));
    dispatch(getColleges());
  } else if (!response.isSuccess) {
    message.error("Failed to delete");
    dispatch(error(response.errorMessage));
  }
};

export const editCollege = (college, slug) => async (dispatch) => {
  dispatch(init(ADD_COLLEGE_TYPES.ADD_COLLEGE));
  console.log("collegee", college);
  const response = await collegeService.editCollege(college);

  dispatch(finish(ADD_COLLEGE_TYPES.ADD_COLLEGE));

  if (response.isSuccess) {
    dispatch(success(SUCCESS, "College has been updated successfully."));
    dispatch(getCollege(slug));
    message.success("College profile has been updated");
    dispatch(getColleges());
  } else if (!response.isSuccess) {
    message.error("Failed to update");
    dispatch(error(response.errorMessage));
  }
};

export const addCollegeRankingAuthorites = (authorites) => async (dispatch) => {
  try {
    await db
      .collection("college-ranking-authorites")
      .doc("ranking-authorites")
      .set({ authorites }, { merge: true });
    message.success("Rank added successfully");
    return true;
  } catch (error) {
    message.error("Failed to add rank");
    return false;
  }
};
export const getCollegeRankingAuthorites = () => async (dispatch) => {
  try {
    let doc = await db
      .collection("college-ranking-authorites")
      .doc("ranking-authorites")
      .get();
    if (doc.exists) {
      // console.log("Document data:", doc.data());
      return { ...doc.data(), id: doc.id };
    } else {
      throw Error("No such document!");
    }
  } catch (error) {
    // console.log(error)
    return [];
  }
};

//faciliteis
export const addCollegeFacilities = (facilities) => async (dispatch) => {
  try {
    await db
      .collection("college-facilities")
      .doc("facilities_id")
      .set({ facilities }, { merge: true });
    message.success("Facilities added successfully");
    return true;
  } catch (error) {
    message.error("Failed to add facilities");
    return false;
  }
};
export const getCollegeFacilities = () => async (dispatch) => {
  try {
    let doc = await db
      .collection("college-facilities")
      .doc("facilities_id")
      .get();
    if (doc.exists) {
      // console.log("Document data:", doc.data());
      return { ...doc.data(), id: doc.id };
    } else {
      throw Error("No such document!");
    }
  } catch (error) {
    // console.log(error)
    return [];
  }
};
