import React, { useMemo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Card, CardHeader } from "reactstrap";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ConfirmationBox from "../../components/ConfirmationBox";
import { Input, Table as AntdTable, Button, Space } from "antd";
import AddCourse from "./AddCourse";
import { getProgram, removeProgram } from "redux/actions/courses_action";
import EditCourse from "./EditCourse";
import moment from "moment";

const Table = ({ loading, fetchedCourses, fetchCourses }) => {
  const [_courses, setCourses] = useState([]); //can be altered by onchange table
  const [course, setCourse] = useState(null);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setCourses(fetchedCourses);
  }, [fetchedCourses]);

  const openEditCourse = async (id) => {
    setIsEditModalOpen(true);
    let course = await dispatch(getProgram(id));
    setCourse(course);
    console.log("course", course);
  };
  const deleteCourse = async (id) => {
    setDeleteLoader(true);
    await dispatch(removeProgram({ id }));
    setIsConformModal(false);
    setDeleteLoader(false);
    fetchCourses();
  };

  const [filters, setFilters] = useState({
    level: null,
    stream: null,
    program: null,
  });

  const handleFilterChange = (column, value) => {
    setFilters({
      ...filters,
      [column]: value,
    });
  };

  const clearFilters = () => {
    setFilters({
      level: null,
      stream: null,
      program: null,
    });
  };

  const filteredCourses = useMemo(() => {
    return fetchedCourses
      ?.filter((course) => {
        return (
          (filters.level ? course.courselevel?.name === filters.level : true) &&
          (filters.stream
            ? course.coursestream?.name === filters.stream
            : true) &&
          (filters.program ? course.name === filters.program : true)
        );
      })
      .sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt));
  }, [fetchedCourses, filters]);

  const getUniqueValues = (data, key) => {
    const levelOrder = ["diploma", "undergraduate", "postgraduate", "phd"];
    if (key === "courselevel") {
      return [...new Set(data?.map((item) => item[key]?.name))].sort((a, b) => {
        return levelOrder.indexOf(a) - levelOrder.indexOf(b);
      });
    } else {
      return [...new Set(data?.map((item) => item[key]?.name))].sort();
    }
  };

  const columns = useMemo(
    () => [
      {
        key: "level",
        title: "Level",
        dataIndex: "courselevel",
        width: "20%",
        paddingLeft: "20px",
        className: "custom-table",
        filters: getUniqueValues(filteredCourses, "courselevel").map(
          (level) => ({
            text:
              level === "postgraduate"
                ? "Post Graduate"
                : level === "undergraduate"
                ? "Under Graduate"
                : level === "phd"
                ? "PhD"
                : level === "diploma"
                ? "Diploma"
                : level,
            value: level,
          })
        ),
        onFilter: (value, record) => record.courselevel?.name === value,
        render: (level) =>
          level.name === "postgraduate"
            ? "Post Graduate"
            : level.name === "undergraduate"
            ? "under graduate"
            : level.name,
      },
      {
        key: "stream",
        title: "Stream",
        dataIndex: "coursestream",
        width: "20%",
        filters: getUniqueValues(filteredCourses, "coursestream").map(
          (stream) => ({
            text: stream,
            value: stream,
          })
        ),
        onFilter: (value, record) => record.coursestream?.name === value,
        render: (stream) => stream?.name,
      },
      {
        key: "program",
        title: "Program",
        dataIndex: "name",
        width: "20%",
        filters: getUniqueValues(filteredCourses, "name").map((program) => ({
          text: program,
          value: program,
        })),
        onFilter: (value, record) => record.name === value,
        // ...getProgramSearchProps("name"),
        // render:program => program.name
      },
      {
        key: "short_code",
        title: "Short Code",
        dataIndex: "program_code",
        width: "22%",
      },
      {
        key: "action",
        title: "Action",
        textAlign: "center",
        className: "table-title  ",
        dataIndex: "",
        width: "8%",
        render: (course) => (
          <>
            <div className="text-right site-button-ghost-wrapper">
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => openEditCourse(course.slug)}
              />
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => {
                  setConformObj({
                    title: `Are you sure you want to delete ${course?.name}?`,
                    msg: "This process cannot be undone.",
                    onSuccess: () => {
                      deleteCourse(course._id);
                    },
                  });
                  setIsConformModal(true);
                }}
                ghost
              />
            </div>
          </>
        ),
      },
    ],
    [filteredCourses]
  );

  const FilteredCourse = _courses
    ?.sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt))
    ?.filter((courses) => {
      return (
        courses?.courselevel?.name
          ?.toLowerCase()
          .includes(searchField.toLowerCase()) ||
        courses?.coursestream?.name
          ?.toLowerCase()
          .includes(searchField.toLowerCase()) ||
        courses?.name?.toLowerCase().includes(searchField.toLowerCase())
      );
    });
  return (
    <div>
      <EditCourse
        fetchCourses={fetchCourses}
        course={course}
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
      />

      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
        loader={deleteLoader}
      />

      <AddCourse
        fetchCourses={fetchCourses}
        isAddModalOpen={isAddModalOpen}
        setIsAddModalOpen={setIsAddModalOpen}
      />

      <Card className="shadow">
        <CardHeader>
          <div className="card-header-content">
            <div className="card-title-content">
              <div className="card-title-detail">
                <Input.Search
                  placeholder="Search course level, stream or program"
                  enterButton
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value)}
                />
              </div>
            </div>
            <Button type="primary" onClick={() => setIsAddModalOpen(true)}>
              Add
            </Button>
          </div>
        </CardHeader>
        <div>
          <AntdTable
            columns={columns}
            rowKey={(record) => {
              return record._id;
            }}
            dataSource={FilteredCourse}
            loading={loading}
            // onChange={handleTableChange}
            size="small"
            sticky
          />
        </div>
      </Card>
    </div>
  );
};

export default Table;
