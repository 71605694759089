import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FileUploader from "components/ImageUploader/FileUploader";
import SendMailModal from "./SendMailModal";
import { Radio, Input, Button, Form } from "antd";
import { Row, Col } from "reactstrap";
import { updateStudentApplication } from "../../../redux/actions/studentapplication_action";
import { mailOfferLetterToStudent } from "redux/actions/sendmail_actions";

const Status = ({ selectedApplication, fetchStudentApplication }) => {
  const [isMailModal, setIsMailModal] = React.useState(false);
  const [value, setValue] = React.useState("pending");
  const [note, setNote] = React.useState("");
  const [document, setDocument] = React.useState("");
  const [offerLetter, setOfferLetter] = React.useState({ offer_letter: [] });
  const [documentItems, setDocumentItems] = React.useState([]);
  const [documentLinks, setDocumentLinks] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  React.useEffect(() => {
    setValue(selectedApplication?.status);
    setOfferLetter({ offer_letter: selectedApplication?.offer_letter });
  }, [selectedApplication]);

  useEffect(() => {
    const { highSchool, school, under_Graduate, other } =
      selectedApplication.academicInformation;
    let items = [];
    let documentLink = {};
    if (highSchool) {
      items.push(`HighSchool`);
      documentLink.HighSchool = highSchool;
    }
    if (school) {
      items.push(`School`);
      documentLink.School = school;
    }
    if (under_Graduate) {
      items.push(`Under Graduate`);
      documentLink.under_graduate = under_Graduate;
    }
    if (other) {
      items.push(`Other`);
      documentLink.other = other;
    }
    setDocumentItems(items);
    setDocumentLinks(documentLink);
    setNote(selectedApplication.remarks);
  }, [selectedApplication]);

  const onSubmit = async () => {
    form.submit();
    setLoading(true);

    await dispatch(
      updateStudentApplication(
        {
          status: value,
          remark: note,
          offer_letter: document,
        },
        selectedApplication.id
      )
    );
    await dispatch(
      mailOfferLetterToStudent({
        status: value,
        offer_letter: document,
        selectedApplication,
      })
    );
    window.location.replace("admin/student-application");

    // fetchStudentApplication();   //this is throwing error so i comment for now

    setLoading(false);
  };

  return (
    <>
      <SendMailModal
        isMailModal={isMailModal}
        setIsMailModal={setIsMailModal}
        selectedApplication={selectedApplication}
        documents={documentItems}
        documentLinks={documentLinks}
      />

      <div style={{ marginTop: "35px", paddingRight: "20px" }}>
        <div
          style={{
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: "5px",
            color: "#333333",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <b>Send mail to respective college</b>
        </div>
        <Row>
          <Col>
            <div
              className="send-mail-college"
              onClick={() => setIsMailModal(true)}
            >
              + Compose
            </div>
          </Col>
        </Row>

        <div
          style={{
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: "5px",
            color: "#333333",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <b>Send offer to applicant</b>
        </div>
        <Row>
          <Col className="col-3">
            <div>
              <FileUploader
                id={selectedApplication?.id}
                form={setOfferLetter}
                formField="offer_letter"
                folder="offerLetter"
                setDocument={setDocument}
                url={""}
              />
            </div>
          </Col>
          <Col className="col-3">
            {(document || selectedApplication.offer_letter) && (
              <a href={document} target="_blank">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/admizz.appspot.com/o/pdf.png?alt=media&token=d18f311a-8eaf-465a-a568-7d1830b5f7a1"
                  height={50}
                  alt="document image"
                />
              </a>
            )}
          </Col>
        </Row>
        <div
          style={{
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: "5px",
            color: "#333333",
            marginBottom: "20px",
            marginTop: "35px",
          }}
        >
          <b>Status</b>
        </div>

        <Row>
          <Col>
            <div>
              <span className="title"> Status :</span>{" "}
              <Radio.Group
                onChange={(e) => setValue(e.target.value)}
                value={value}
              >
                <Radio value={"pending"} disabled>
                  Pending
                </Radio>
                <Radio value={"approved"}>Approved</Radio>
                <Radio value={"rejected"}>Rejected</Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <div
          style={{
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: "5px",
            color: "#333333",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <b>Remarks</b>
        </div>
        <Row>
          <Col>
            <Form.Item
              className="form-control-label"
              label="Note"
              name="note"
               rules={[
                  {
                    required:false,
                    type: "string",
                    message: "please send your remarks",
                  },
                ]}
            >
              <Input.TextArea
                placeholder="Send a note.."
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className="form-control-label"
                rows={4}
              
              
              />

            </Form.Item>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "2%", paddingBottom: 15 }}>
        <Button type="primary" onClick={onSubmit} loading={loading}>
          Save Status and Remarks
        </Button>
      </div>
    </>
  );
};

export default Status;
