import { postService, getService } from "../commonServices";

export class FacilitiesService {
  getFacilities() {
    let url = "/facilities/getFacilities";
    let data = getService(url);
    return data;
  }
  addFacilities(facilities_data) {
    let url = "/facilities/addFacilities";
    let body = JSON.stringify(facilities_data);
    let data = postService(url, body);
    return data;
  }
  deleteFacilities(id) {
    let url = `/facilities/deleteFacilities/${id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
