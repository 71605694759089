import React from "react";
import { getTeammember, deleteTeammember } from "redux/actions/team_member";
import { useDispatch } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Button, Input } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import TeamMemberViewCard from "./TeamMemberViewCard";
import ConfirmationBox from "components/ConfirmationBox";
import AddTeammember from "./AddTeammember";
import EditTeammember from "./EditTeammember";

const TeamMemberDetails = () => {
  const [teamMemberArray, setTeamMemberArray] = React.useState([]);
  const [addTeamMember, setAddTeamMember] = React.useState(false);
  const [editTeamMember, setEditTeamMember] = React.useState(false);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [teamMemberId, setTeamMemberId] = React.useState("");
  const [searchField, setSearchField] = React.useState("");
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isViewModalOpen, setIsViewModalOpen] = React.useState(false);

  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const dispatch = useDispatch();

  const fetchTeammember = async () => {
    setLoading(true);
    const res = await dispatch(getTeammember());
    setTeamMemberArray(res);
    setLoading(false);
  };
  React.useEffect(() => {
    fetchTeammember();
  }, []);

  const onDelete = async (id, image) => {
    setIsConformModal(true);
    setConformObj({
      title: `Are you sure you want to delete?`,
      msg: "This process cannot be undone.",
      onSuccess: async () => {
        setDeleteLoader(true);
        await dispatch(deleteTeammember(id, image));
        setIsConformModal(false);
        setDeleteLoader(false);
        fetchTeammember();
      },
    });
  };

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const getNameWithImage = () => ({
    render: (teammember) => {
      return (
        <div className="image-container-with-name">
          <div className="image-container">
            <img alt="..." src={teammember.image} />
          </div>
          <div className="title-meta">
            <span className="mb-0 tecounsellorIdt-sm">{teammember.name}</span>
          </div>
        </div>
      );
    },
  });

  const columns = React.useMemo(() => [
    {
      title: "Name",
      dataIndex: "",
      width: "20%",
      ...getNameWithImage(""),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "30%",
      render: (description) => truncateString(description, 80),
    },
    {
      title: "Action",
      dataIndex: "",
      width: "8%",
      align: "right",
      render: (teammember) => (
        <>
          <div
            className="text-right site-button-ghost-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {
                setTeamMemberId(teammember.id);
                setIsViewModalOpen(true);
              }}
            />
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setTeamMemberId(teammember.id);
                setEditTeamMember(true);
              }}
            />
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => {
                onDelete(teammember.id, teammember.image);
              }}
            />
          </div>
        </>
      ),
    },
  ]);

  const filteredData = teamMemberArray
    ?.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
    .filter((member) => {
      return (
        member?.name?.toLowerCase().includes(searchField.toLowerCase()) ||
        member?.type?.toLowerCase().includes(searchField.toLowerCase()) ||
        member?.email?.toLowerCase().includes(searchField.toLowerCase())
      );
    });
  return (
    <>
      <Container className="mt--7" fluid>
        <EditTeammember
          setEditTeamMember={setEditTeamMember}
          editTeamMember={editTeamMember}
          id={teamMemberId}
          fetchTeammember={fetchTeammember}
        />

        <AddTeammember
          addTeamMember={addTeamMember}
          setAddTeamMember={setAddTeamMember}
          fetchTeammember={fetchTeammember}
        />
        <TeamMemberViewCard
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          id={teamMemberId}
        />
        <ConfirmationBox
          data={conformObj}
          setIsConformModal={setIsConformModal}
          isConformModal={isConformModal}
          loader={deleteLoader}
        />

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    <Input.Search
                      placeholder="Search by name, email and type"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                  <Button onClick={() => setAddTeamMember(true)} type="primary">
                    Add
                  </Button>
                </div>
              </CardHeader>

              <AntdTable
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={filteredData}
                pagination={pagination}
                loading={loading}
                size="small"
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TeamMemberDetails;
