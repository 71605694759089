import { message } from "antd";
import { SendMailService } from "redux/api/sendmail_api";
import { success } from "redux/commonActions";

const sendMailService = new SendMailService();

export const mailToCollege = (mail_data) => async (dipatch) => {
  try {
    const res = await sendMailService.sendMailToCollege(mail_data);
    if (res.isSuccess) {
      message.success("Mail sent successfull");
    } else {
      message.error("Failed to send");
    }
    return res;
  } catch (error) {
    message.error(`Server Error while sending email${error.message}`);
    return error;
  }
};

export const mailOfferLetterToStudent = (mail_data) => async (dipatch) => {
  try {
    const res = await sendMailService.sendOfferLetterMail(mail_data);
    if (res.isSuccess) {
      //message.success("Sucess");
    } else {
      message.error("Failed to send");
    }
    return res;
  } catch (error) {
    message.error(`Server Error while sending email${error.message}`);
    return error;
  }
};

export const mailToStudent = (mail_data) => async (dispatch) => {
  try {
    const res = await sendMailService.sendMailToStudent(mail_data);
    console.log("res", res);

    if (res.isSuccess) {
      message.success("Mail sent successfull");
    } else {
      message.error("Failed to send");
    }
    return res;
  } catch (err) {
    message.error("Server Error");
    return err;
  }
};

export const mailToSubcribers = (mail_data) => async (dispatch) => {
  try {
    const res = await sendMailService.sendMailToSubscribers(mail_data);
    console.log("res", res);
    if (res.isSuccess) {
      message.success("Mail sent successfull");
    } else {
      message.error("Failed to send");
    }
    return res;
  } catch (err) {
    message.error("Server Error");
    return err;
  }
};
