import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  Button,
  ModalHeader,
} from "reactstrap";
import BasicInformation from "./BasicInformation";
import AcademicInformation from "./AcademicInformation";
import BackgroundInformation from "./BackgroundInformation";
import SelectedCollege from "./SelectedCollege";
import Status from "./Status";
import SendMailModal from "./SendMailModal";
import "./style.css";

const Index = ({
  isApplicationViewModalOpen,
  setIsApplicationViewModalOpen,
  selectedApplication,
  fetchStudentApplication,
}) => {
  const [selected, setSelected] = React.useState("BASIC_INFORMATION");
  const getSection = () => {
    switch (selected) {
      case "BASIC_INFORMATION":
        return (
          <BasicInformation selectedApplication={selectedApplication.data} />
        );
      case "BACKGROUND_INFORMATION":
        return (
          <BackgroundInformation
            selectedApplication={selectedApplication.data}
          />
        );
      case "SELECTED_COLLEGE":
        return (
          <SelectedCollege selectedApplication={selectedApplication.data} />
        );
      case "ACADEMIC_INFORMATION":
        return (
          <AcademicInformation selectedApplication={selectedApplication.data} />
        );
      case "STATUS":
        return (
          <Status
            selectedApplication={selectedApplication.data}
            fetchStudentApplication={fetchStudentApplication.data}
          />
        );
      case "EMAIL":
        return <SendMailModal selectedApplication={selectedApplication.data} />;
      default:
        return null;
    }
  };

  const toggle = () => {
    setIsApplicationViewModalOpen(false);
    // setFragment("basicinfo");
  };

  console.log(selectedApplication);

  return (
    <>
      <Modal
        isOpen={isApplicationViewModalOpen}
        //isOpen
        toggle={() => setIsApplicationViewModalOpen(false)}
        style={{ minWidth: "80%" }}
      >
        <ModalHeader toggle={toggle}>Student Application</ModalHeader>
        <Row>
          <Col xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col>
                  <div className="img-container" style={{ marginTop: "15px" }}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={
                        selectedApplication?.data?.basicInformation
                          ?.profileImage ||
                        "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
                      }
                    />
                  </div>
                </Col>
              </Row>

              <CardBody>
                <div className="text-center col">
                  <h3>{selectedApplication.basicInformation?.fullName}</h3>
                  <div className="h5 font-weight-300">
                    {selectedApplication?.basicInformation?.email}
                  </div>
                  <div className="h5 font-weight-300">
                    {selectedApplication?.basicInformation?.countryCode}{" "}
                    {selectedApplication?.basicInformation?.phoneNumber}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Application for:
                  </div>
                  <div
                    className="h5 font-weight-300"
                    style={{ textTransform: "uppercase" }}
                  >
                    {selectedApplication?.basicInformation?.selectedLevel}
                  </div>
                </div>
                <div>
                  <hr className="my-4" />
                  <Button
                    color="primary"
                    block
                    outline
                    size="large"
                    className="mt-2 ml-1"
                    onClick={() => setSelected("BASIC_INFORMATION")}
                    active={selected === "BASIC_INFORMATION"}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                  >
                    Basic Information
                  </Button>
                  <Button
                    color="primary"
                    block
                    outline
                    size="large"
                    className="mt-2 ml-1"
                    onClick={() => setSelected("BACKGROUND_INFORMATION")}
                    active={selected === "BACKGROUND_INFORMATION"}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                  >
                    Background Information
                  </Button>
                  <Button
                    color="primary"
                    block
                    outline
                    size="large"
                    className="mt-2 ml-1"
                    onClick={() => setSelected("SELECTED_COLLEGE")}
                    active={selected === "SELECTED_COLLEGE"}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                  >
                    Selected College
                  </Button>
                  <Button
                    color="primary"
                    block
                    outline
                    size="large"
                    className="mt-2 ml-1"
                    onClick={() => setSelected("ACADEMIC_INFORMATION")}
                    active={selected === "ACADEMIC_INFORMATION"}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                  >
                    Academic Information
                  </Button>
                  <Button
                    color="primary"
                    block
                    outline
                    size="large"
                    className="mt-2 ml-1"
                    onClick={() => setSelected("STATUS")}
                    active={selected === "STATUS"}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                  >
                    Actions
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="" xl="8">
            {getSection()}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Index;
