// export const API_BASE_URL = "http://157.245.106.9";
// export const API_BASE_URL = "https://admizzapi.asterdio.xyz";
export const API_BASE_URL = "https://api.admizz.com";
// export const API_BASE_URL = "http://localhost:3005";

// alert
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const SUCCESS = "SUCCESS";

//college
export const COLLEGES_TYPES = {
  GET_COLLEGES: "GET_COLLEGES",
  GET_COLLEGES_INIT: "GET_COLLEGES_INIT",
  GET_COLLEGES_FINISH: "GET_COLLEGES_FINISH",
};

export const COLLEGE_TYPES = {
  GET_COLLEGE: "GET_COLLEGE",
  GET_COLLEGE_INIT: "GET_COLLEGE_INIT",
  GET_COLLEGE_FINISH: "GET_COLLEGE_FINISH",
};
export const ADD_COLLEGE_TYPES = {
  ADD_COLLEGE: "ADD_COLLEGE",
  ADD_COLLEGE_INIT: "ADD_COLLEGE_INIT",
  ADD_COLLEGE_FINISH: "ADD_COLLEGE_FINISH",
};

//testimonial
export const TESTIMONIAL_TYPES = {
  TESTIMONIAL_BEGIN: "TESTIMONIAL_BEGIN",
  ADD_TESTIMONIAL: "ADD_TESTIMONIAL",
  GET_TESTIMONIAL: "GET_TESTIMONIAL",
  END_TESTIMONIAL: "END_TESTIMONIAL",
};

//blog
export const BLOGS_TYPES = {
  GET_BLOGS: "GET_BLOGS",
  GET_BLOGS_INIT: "GET_BLOGS_INIT",
  GET_BLOGS_FINISH: "GET_BLOGS_FINISH",
};
//newLetter
export const NEWSLETTER_TYPES = {
  GET_NEWSLETTER: "GET_NEWSLETTER",
  GET_ALL_NEWSLETTER:"GET_ALL_NEWSLETTER",
  GET_NEWSLETTER_INIT: "GET_NEWSLETTER_INIT",
  GET_NEWSLETTER_FINISH: "GET_NEWSLETTER_FINISH",
};
export const BLOG_TYPES = {
  GET_BLOG: "GET_BLOG",
  GET_BLOG_INIT: "GET_BLOG_INIT",
  GET_BLOG_FINISH: "GET_BLOG_FINISH",
};
export const ADD_BLOG_TYPES = {
  ADD_BLOG: "ADD_BLOG",
  ADD_BLOG_INIT: "ADD_BLOG_INIT",
  ADD_BLOG_FINISH: "ADD_BLOG_FINISH",
};

export const GET_TOP_COLLEGES_TYPES = {
  TOP_COLLEGES_BEGIN: "TOP_COLLEGES_BEGIN",
  ADD_TOP_COLLEGES: "ADD_TOP_COLLEGES",
  GET_TOP_COLLEGES: "GET_TOP_COLLEGES",
};

export const GET_TOP_COURSES_TYPES = {
  TOP_COURSES_BEGIN: "TOP_COURSES_BEGIN",
  ADD_TOP_COURSES: "ADD_TOP_COURSES",
  GET_TOP_COURSES: "GET_TOP_COURSES",
};

export const GET_SCHOLARSHIP_TYPES = {
  SCHOLARSHIP_BEGIN: "SCHOLARHSIP_BEGIN",
  GET_SCHOLARSHIP: "GET_SCHOLARSHIP",
  ADD_SCHOLARSHIP: "ADD_SCHOLARSHIP",
  SCHOLARSHIP_END: "SCHOLARSHIP_END",
};
export const GET_GALLERY_TYPES = {
  GALLERY_BEGIN: "GALLERY_BEGIN",
  GET_GALLERY: "GET_GALLERY",
  ADD_GALLERY: "ADD_GALLERY",
  GALLERY_END: "GALLERY_END",
};

export const GET_RECRUIT_TYPES = {
  RECRUIT_BEGIN: "AWARD_BEGIN",
  GET_RECRUIT: "GET_AWARD",
  ADD_RECRUIT: "ADD_AWARD",
  RECRUIT_END: "AWARD_END",
};

export const GET_COLLEGE_BOARD_TYPES = {
  COLLEGE_BOARD_BEGIN: "COLLEGE_BOARD_BEGIN",
  GET_COLLEGE_BOARD: "GET_COLLEGE_BOARD",
  ADD_COLLEGE_BOARD: "ADD_COLLEGE_BOARD",
  COLLEGE_BOARD_END: "COLLEGE_BOARD_END",
};

export const GET_RANK_TYPES = {
  RANK_BEGIN: "RANK_BEGIN",
  GET_RANK: "GET_RANK",
  ADD_RANK: "ADD_RANK",
  RANK_END: "RANK_END",
};

export const GET_FACILITIES_TYPES = {
  FACILITIES_BEGIN: "FACILITIES_BEGIN",
  GET_FACILITIES: "GET_FACILITIES",
  ADD_FACILITIES: "ADD_FACILITIES",
  FACILITIES_END: "FACILITIES_END",
};

export const GET_UNIVERSITY_RANKS = {
  UNIVERSITY_RANK_BEGIN: "UNIVERSITY_RANK_BEGIN",
  UNIVERSITY_GET_RANK: "UNIVERSITY_GET_RANK",
  UNIVERSITY_ADD_RANK: "UNIVERSITY_ADD_RANK",
  UNIVERSITY_RANK_END: "UNIVERSITY_RANK_END",
};

export const GET_STREAM = {
  STREAM_BEGIN: "STREAM_BEGIN",
  STREAM_GET: "STREAM_GET",
  STREAM_ADD: "STREAM_ADD",
  STREAM_END: "STREAM_END",
};
