import React from "react";
import SharedInfos from "./SharedInfos";
// core components
import Header from "../../../components/Headers/Header.js";
const index = () => {
  return (
    <>
      <Header />
      <SharedInfos />
    </>
  );
};

export default index;
