import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextEditor from "components/TextEditor";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { mailToStudent } from "redux/actions/sendmail_actions";
import { Form, Button, Input } from "antd";
import moment from "moment";

const SendMailModal = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    isSendMailModalOpen,
    setIsSendMailModalOpen,
    selectedStudents,
    appointmentArray,
    data,
  } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (selectedStudents) {
      form.setFieldsValue({
        email: selectedStudents.map((email) => email),
      });
    }
  }, [selectedStudents]);

  const onFinish = async (values) => {
    console.log("values", values);
    setLoading(true);
    let allEmails = selectedStudents?.map((std) => std.email);
    allEmails = [...new Set(allEmails)];
    console.log("data", data);
    let email = data?.map((l) => {
      return l.email;
    });
    
    let studentname = data?.map((l) => {
      return l.name;
    });
   

    let formData = {
      to: data[0].email,
      body: values.body,
      subject: values.subject,
      counselor: data[0].counsellor,
      studentname: studentname[0],
      appointmentDate:  `${moment
        .unix(data[0]?.date?.seconds)
        .format("DD MMM YYYY")} ${data[0]?.time}`
    };
    console.log("formData", formData);
    await dispatch(mailToStudent(formData));

    setLoading(false);
    toggle();
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  const toggle = () => {
    setIsSendMailModalOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={isSendMailModalOpen}
        toggle={toggle}
        className="popup-modal"
        des
      >
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Send Mail</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col log="12">
                <Form.Item
                  label="Email"
                  className="form-control-label"
                  name="email"
                  rules={[
                    {
                      type: "array",
                    },
                  ]}
                >
                  {data?.map((l) => {
                    return <Input placeholder={l?.email} disabled></Input>;
                  })}
                </Form.Item>
              </Col>

              <Col lg="12">
                <Form.Item
                  label="Subject"
                  className="form-control-label"
                  name="subject"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Subject field cannot be empty",
                    },
                  ]}
                >
                  <Input placeholder="Subject" />
                </Form.Item>
              </Col>

              <Col lg="12">
                <label>
                  <b>Body:</b>
                </label>
                <Form.Item
                  className="form-control-label"
                  name="body"
                  rules={[
                    {
                      type: "string",
                      // required: true,
                      message: "Body field cannot be empty",
                    },
                  ]}
                >
                  <TextEditor
                    onChange={(html) => {
                      form.setFieldsValue({
                        body: html,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button loading={loading} type="primary" onClick={onSubmit}>
            Send Email
          </Button>
          <Button
            type="danger"
            onClick={() => setIsSendMailModalOpen(false)}
            disabled={loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SendMailModal;
