import { db, storage } from "../../firebase";
// import { PopUp } from "../../components/InformationModal/index";
import { message } from "antd";
import { TestimonialService } from "../api/testimonial_api";
import { TESTIMONIAL_TYPES, SUCCESS } from "../types";
import { finish, success, error } from "../commonActions";

const testimonialService = new TestimonialService();

export const addTestimonials = (testimonial_data) => async (dispatch) => {
  const response = await testimonialService.addTestimonial(testimonial_data);
  dispatch(finish(TESTIMONIAL_TYPES.ADD_TESTIMONIAL));

  if (response.isSuccess) {
    message.success("New testimonail added");
    dispatch(success(SUCCESS, "Testimonail has been added successfully."));
  } else if (!response.isSuccess) {
    message.error("Failed to add");
    dispatch(error(response.errorMessage));
  }
};

export const getTestimonials = (id) => async (dispatch) => {
  const response = await testimonialService.getTestimonial(id);

  if (response.isSuccess) {
    return response.data;
  } else if (!response.isSuccess) {
    return null;
  }
};
export const getAllTestimonials = (get_testimonial) => async (dispatch) => {
  const response = await testimonialService.getAllTestimonial(get_testimonial);
  if (response.isSuccess) {
    return response.data;
  } else if (!response.isSuccess) {
    return [];
  }
};

export const deleteTestimonials = (id) => async (dispatch) => {
  const response = await testimonialService.deleteTestmonial(id);

  if (response.isSuccess) {
    message.success("Testimonial deleted");
    dispatch(
      success(SUCCESS, "Your testimonial has been deleted successfully.")
    );
  } else if (!response.isSuccess) {
    message.error("Failed to delete");
    dispatch(error(response.errorMessage));
  }
};

export const editTestimonails = (id, testimonail) => async (dispatch) => {
  const response = await testimonialService.updateTestimonail(id, testimonail);
  console.log("action-response", response);

  if (response.isSuccess) {
    message.success("Testmonial updated");
    return true;
  } else if (!response.isSuccess) {
    message.error("Failed to update");
    return false;
  }
};

export const updateTestimonial = (id, values) => async (dispatch) => {
  try {
    await db.collection("testimonial").doc(id).update({
      name: values.name,
      designation: values.designation,
      image_url: values.image_url,
      description: values.description,
      video_url: values.video_url,
    });
    message.success("Testimonial update successfull");
    return true;
  } catch (error) {
    message.error("Failed to update");
    // console.log(error);
    return false;
  }
};

const deleteImage = async (image) => {
  const desertRef = storage.refFromURL(image);
  const isDeleted = await desertRef
    .delete()
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
  return isDeleted;
};

export const deleteTestimonial = (id, image_url) => async (dispatch) => {
  try {
    await db.collection("testimonial").doc(id).delete();
    await deleteImage(image_url);
    message.success("Testimonial deleted");
    return "Deleted sucessfull";
  } catch (err) {
    message.error("Failed to delete");
    return null;
  }
};
