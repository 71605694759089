import { GET_UNIVERSITY_RANKS } from "redux/types";

const initialState = {
  universityranks: [],
  loader: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_UNIVERSITY_RANKS.UNIVERSITY_RANK_BEGIN:
      return {
        ...state,
        loader: true,
      };
      
    case GET_UNIVERSITY_RANKS.UNIVERSITY_GET_RANK:
      return {
        ...state,
        universityranks: payload,
        loader: false,
      };
      
    case GET_UNIVERSITY_RANKS.UNIVERSITY_RANK_END:
      return {
        ...state,
        loader: false,
      };
    default:
      return {
        ...state,
      };
  }
};
