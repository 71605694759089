import React from "react";
import { Row, Col } from "reactstrap";

const AcademicInformation = ({ selectedApplication }) => {
  const { academicInformation } = selectedApplication || {};
  console.log("first", academicInformation);
  return (
    <div style={{ marginTop: "35px", paddingRight: "20px" }}>
      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          marginBottom: "20px",
        }}
      >
        <b>Academic Information</b>
      </div>

      <Row>
        {academicInformation?.schoolMarks.schoolMarks && (
          <Col xs="6">
            <div className="text-container">
              <span className="title">School Marks: </span>
              <span className="text-detail">
                {" "}
                {academicInformation?.schoolMarks.schoolMarks}
              </span>
            </div>
          </Col>
        )}

        {academicInformation?.level1Score && (
          <Col xs="6">
            <div className="text-container">
              <span className="title">Level I:</span>
              <span className="text-detail">
                {" "}
                {academicInformation?.level1Score}
              </span>
            </div>
          </Col>
        )}

        {academicInformation?.level2Score?.level2Score && (
          <Col xs="6">
            <div className="text-container">
              <span className="title"> HighSchool Score: </span>
              <span className="text-detail">
                {" "}
                {academicInformation?.level2Score?.level2Score}
              </span>
            </div>
          </Col>
        )}

        {academicInformation?.underGraduate?.underGraduate && (
          <Col xs="6">
            <div className="text-container">
              <span className="title"> Under Gradute Score:</span>
              <span className="text-detail">
                {" "}
                {academicInformation?.underGraduate?.underGraduate}
              </span>
            </div>
          </Col>
        )}
        {academicInformation?.semesterMarks && (
          <Col xs="6">
            <div className="text-container">
              <span className="title"> Semester Score:</span>
              <span className="text-detail">
                {" "}
                {academicInformation?.semesterMarks}
              </span>
            </div>
          </Col>
        )}
        {academicInformation?.postGraduteScore?.postGraduteScore && (
          <Col xs="6">
            <div className="text-container">
              <span className="title"> Post Gradute Score:</span>
              <span className="text-detail">
                {" "}
                {academicInformation?.postGraduteScore?.postGraduteScore}
              </span>
            </div>
          </Col>
        )}
      </Row>
      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          margin: "20px 0",
        }}
      >
        <b>Language</b>
      </div>
      <Row>
        <Col xs="6">
          <div className="text-container">
            <span className="title">GMAT:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.gmat?.haveDone === "yes"
                ? academicInformation?.gmat?.score
                : "No"}
            </span>
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title"> GRE:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.gre?.haveDone === "yes"
                ? academicInformation?.gre?.score
                : "No"}
            </span>
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">TOFEL:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.tofel?.haveDone === "yes"
                ? academicInformation?.tofel?.score
                : "No"}
            </span>
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title"> JEE Advance:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.jeeAdvance?.haveDone === "yes"
                ? academicInformation?.jeeAdvance?.score
                : "No"}
            </span>
          </div>
        </Col>
        <Col xs="6">
          <div className="text-container">
            <span className="title">SAT:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.sat?.haveDone === "yes"
                ? academicInformation?.sat?.score
                : "No"}
            </span>{" "}
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title"> SATII:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.satII?.haveDone === "yes"
                ? academicInformation?.satII?.score
                : "No"}
            </span>
          </div>
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">IELTS:</span>
            <span className="text-detail">
              {" "}
              {academicInformation?.ielts?.haveDone === "yes"
                ? academicInformation?.ielts?.score
                : "No"}
            </span>
          </div>
        </Col>

        {academicInformation?.ielts?.haveDone === "yes" && (
          <>
            <Col xs="6">
              <div className="text-container">
                <span className="title"> Listening: </span>
                <span className="text-detail">
                  {" "}
                  {academicInformation?.ielts?.subMars?.listining}{" "}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="text-container">
                <span className="title">Reading: </span>
                <span className="text-detail">
                  {" "}
                  {academicInformation?.ielts?.subMars?.reading}{" "}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="text-container">
                <span className="title">Speaking: </span>
                <span className="text-detail">
                  {" "}
                  {academicInformation?.ielts?.subMars?.speaking}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="text-container">
                <span className="title">Writing: </span>
                <span className="text-detail">
                  {" "}
                  {academicInformation?.ielts?.subMars?.writing}
                </span>
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col xs="12">
          <div
            style={{
              borderBottom: "1px solid #E0E0E0",
              paddingBottom: "5px",
              color: "#333333",
              margin: "20px 0",
            }}
          >
            <b>Documents</b>
          </div>
        </Col>

        {academicInformation?.highSchool && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={academicInformation?.highSchool}
                target="_blank"
                className="text-detail"
              >
                High School Document
              </a>
            </div>
          </Col>
        )}

        {academicInformation?.school && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={academicInformation?.school}
                target="_blank"
                className="text-detail"
              >
                School Document
              </a>
            </div>
          </Col>
        )}
        {academicInformation?.under_Graduate && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={academicInformation?.under_Graduate}
                target="_blank"
                className="text-detail"
              >
                Under Graduate Documents
              </a>
            </div>
          </Col>
        )}
        {academicInformation?.semesterscore}
        {academicInformation?.post_Gradute && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={academicInformation?.post_Gradute}
                target="_blank"
                className="text-detail"
              >
                Post Graduate Documents
              </a>
            </div>
          </Col>
        )}
        {academicInformation?.other && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={academicInformation?.other}
                target="_blank"
                className="text-detail"
              >
                Other Documents
              </a>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AcademicInformation;
