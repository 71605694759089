import React from "react";
import { Form, Select, Button } from "antd";
import { addTopCourse, getTopCourses } from "redux/actions/footer_actions";
import { useSelector, useDispatch } from "react-redux";
import { getCourses } from "redux/actions/courses_action";

const AddTopColleges = () => {
  const [loader, setLoader] = React.useState(false);
  const [courseList, setCourseList] = React.useState([]);

  //redux state

  const topCoursesList = useSelector((state) => state.footer.topCourses);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const fetchCourse = async () => {
    const res = await dispatch(getCourses());
    if (res) {
      setCourseList(res);
    }
  };

  React.useEffect(() => {
    fetchCourse();
    dispatch(getTopCourses());
  }, []);

  React.useEffect(() => {
    if (topCoursesList) {
      form.setFieldsValue({
        top_courses: topCoursesList.map((course) => course.course_id),
      });
    }
  }, [topCoursesList]);

  const onFinish = async (values) => {
    setLoader(true);
    let top_courses = [];

    courseList.forEach((course) => {
      const selectedCourse = values.top_courses.find((c) => c === course?._id);
      if (selectedCourse) {
        top_courses.push({
          course_id: course?._id,
          course_name: course?.name,
          course_slug: course?.slug,
        });
      }
    });

    await dispatch(addTopCourse(top_courses));
    setLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };

  return (
    <div className="p-4">
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          className="form-control-label"
          label="Add Top Courses"
          name="top_courses"
          rules={[
            {
              type: "array",
              required: true,
              message: "Field cannot be empty",
            },
          ]}
        >
          <Select
            mode="multiple"
            label="Add top Colleges"
            style={{ width: "100%" }}
            placeholder="Please select"

            // onChange={handleChange}
          >
            {courseList &&
              courseList?.map((course, i) => {
                return (
                  <Select.Option value={course?._id} key={i}>
                    {course?.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>

      <Button type="primary" onClick={onSubmit} loading={loader}>
        Update Top Courses
      </Button>
    </div>
  );
};

export default AddTopColleges;
