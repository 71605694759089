import { message } from "antd";
import { RecruitService } from "redux/api/recruit_api";
import { GET_RECRUIT_TYPES } from "../types";
import { storage } from "../../firebase";

const recruitService = new RecruitService();
export const addRecruit = (data) => async () => {
  const res = await recruitService.addRecruit(data);

  if (res.isSuccess) {
    message.success("Added succesfully");
    return "successfully added";
  } else {
    message.error("Failed to addd");
    return res.data;
  }
};

export const getRecruit = () => async (dispatch) => {
  dispatch({
    type: GET_RECRUIT_TYPES.RECRUIT_BEGIN,
  });
  const res = await recruitService.getRecruit();
  if (res) {
    dispatch({
      type: GET_RECRUIT_TYPES.GET_RECRUIT,
      payload: res.data,
    });
  } else {
    return res.data;
  }
};

export const deleteRecruit = (id, image) => async (dispatch) => {
  try {
    const res = await recruitService.deleteRecruit(id);
    await storage.refFromURL(image).delete();

    if (res.isSuccess) {
      message.success("Deleted sucessfully");
    } else {
      message.error("Failed to delete");
    }
  } catch (error) {
    // console.log(error);
    message.error("Server Error");
  }
};
