import { postService, getService } from "../commonServices";

export class TestimonialService {
  addTestimonial(testimonials_data) {
    let url = "/testimonials";
    let body = JSON.stringify(testimonials_data);
    console.log("body", body);
    let data = postService(url, body);
    console.log("data", data);
    return data;
  }
  getAllTestimonial() {
    let url = `/testimonials/all`;
    let data = getService(url);
    return data;
  }
  getTestimonial(_id) {
    let url = `/testimonials/${_id}`;
    let data = getService(url);
    return data;
  }
  updateTestimonail(id, testimonials) {
    let body = JSON.stringify(testimonials);
    let url = `/testimonials/${id}`;
    let data = postService(url, testimonials, "PUT");
    return data;
  }
  deleteTestmonial(_id) {
    let url = `/testimonials/${_id}`;
    let data = postService(url, undefined, "DELETE");
    console.log("data-delte", data);
    return data;
  }
}
