import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import { Form, Input, Select, Button } from "antd";
import {
  addCollegeCourse,
  getLevels,
  getStreams,
  getAllPrograms,
} from "redux/actions/courses_action";
import AddNewCourse from "../../../Courses/AddCourse";
const { TextArea } = Input;
const AddCourse = ({ fetchCourses, college }) => {
  const [loading, setLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [fetchedLevels, setFetchedLevels] = useState([]); //original data
  const [fetchedStreams, setFetchedStreams] = useState([]);
  const [fetchedPrograms, setFetchedPrograms] = useState([]);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { Option } = Select;

  const fetchLevels = async () => {
    let data = await dispatch(getLevels());
    setFetchedLevels(data);
    // console.log('datalevels',data);
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  const onSelectLevel = async (level) => {
    form.setFieldsValue({ coursestream: null });
    let data = await dispatch(getStreams(level));
    setFetchedStreams(data);
  };

  const onselectStream = async (level, option) => {
    form.setFieldsValue({ courseprogram: null });
    let data = await dispatch(getAllPrograms(option.courselevel, option.value));
    setFetchedPrograms(data);
  };
  const onFinish = async (values) => {
    // console.log('valuesonfinish',values);
    if (college._id) {
      setLoading(true);
      values.college = college._id;
      let response = await dispatch(addCollegeCourse(values));
      if (response) {
        form.resetFields();
        fetchCourses && fetchCourses();
      }
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  function makeData() {
    let newLabel = [];
    fetchedLevels.map((l) => {
      if (l.name == "diploma") {
        newLabel[0] = l;
      } else if (l.name == "undergraduate") {
        newLabel[1] = l;
      } else if (l.name == "postgraduate") {
        newLabel[2] = l;
      } else if (l.name == "phd") {
        newLabel[3] = l;
      }
    });
    let labels = newLabel.map((l) => {
      return (
        <Option
          key={l._id}
          value={l._id}
          style={{ textTransform: "capitalize" }}
        >
          <div style={{ textTransform: "capitalize" }}>
            {l.name === "postgraduate"
              ? "Post Graduate"
              : l.name === "undergraduate"
              ? "under graduate"
              : l.name}
          </div>
        </Option>
      );
    });
    return labels;
  }

  return (
    <div>
      <AddNewCourse
        isAddModalOpen={isAddModalOpen}
        setIsAddModalOpen={setIsAddModalOpen}
      />
      <Card className="bg-secondary shadow">
        <CardHeader>
          <div className="card-header-content">
            <h3 className="mb-0">Add Courses To College</h3>
          </div>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <div className="p-2">
              <Row>
                <Col lg="6" xs="12">
                  {/* Just copy what I did here*/}
                  <div id="testPosition" style={{ position: "relative" }}>
                    <Form.Item
                      className="form-control-label"
                      label="Level"
                      name="courselevel"
                      rules={[
                        {
                          required: true,
                          message: "Level is required",
                        },
                      ]}
                    >
                      <Select
                        onSelect={onSelectLevel}
                        placeholder="Select Level"
                        getPopupContainer={() =>
                          document.getElementById("testPosition")
                        }
                      >
                        {makeData()}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg="6" xs="12">
                  <div id="testPosition" style={{ position: "relative" }}>
                    <Form.Item
                      className="form-control-label"
                      label="Stream"
                      name="coursestream"
                      rules={[
                        {
                          required: true,
                          message: "Stream is required",
                        },
                      ]}
                    >
                      <Select
                        onSelect={onselectStream}
                        placeholder="Select Stream"
                        getPopupContainer={() =>
                          document.getElementById("testPosition")
                        }
                      >
                        {fetchedStreams?.sort(dynamicSort("name")).map((l) => {
                          return (
                            <Option
                              key={l._id}
                              courselevel={l.courselevel}
                              value={l._id}
                            >
                              {l.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>

                <Col lg="12" xs="12">
                  <div id="testPosition" style={{ position: "relative" }}>
                    <Form.Item
                      className="form-control-label"
                      label="Program"
                      name="courseprogram"
                      rules={[
                        {
                          required: true,
                          message: "Program is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Program"
                        getPopupContainer={() =>
                          document.getElementById("testPosition")
                        }
                      >
                        {fetchedPrograms.map((l) => {
                          return (
                            <Option key={l._id} value={l._id}>
                              {l.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>

                <Col lg="6" xs="12">
                  <Form.Item
                    className="form-control-label"
                    label="First Sem Fee"
                    name="fee_per_sem"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "fee_per_sem is required",
                      },
                    ]}
                  >
                    <Input placeholder="50k" />
                  </Form.Item>
                </Col>
                
                <Col lg="6" xs="12">
                  <Form.Item
                    className="form-control-label"
                    label="Course Duration"
                    name="course_duration"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "course_duration is required",
                      },
                    ]}
                  >
                    <Input placeholder="3 years" />
                  </Form.Item>
                </Col>
                <Col lg="12">
                  <Form.Item
                    className="form-control-label"
                    label="How to apply"
                    name="how_to_apply"
                    // rules={[
                    //   {
                    //     type: "string",
                    //     required: true,
                    //     message: "how_to_apply is required",
                    //   },
                    // ]}
                  >
                    <TextArea placeholder="how_to_apply" rows={4} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="form-btn-container">
                <Button loading={loading} type="primary" onClick={onSubmit}>
                  Add Course To This College
                </Button>
                
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddCourse;
