import { db, storage, functions, auth } from "../../firebase";

export const signIn =
  ({ email, password }) =>
    async (dispatch) => {
      try {
        let res = await auth.signInWithEmailAndPassword(email, password);
        return res;
      } catch (error) {
        // console.log(error);
      }
    };

export const signOut = () => async (dispatch) => {
  try {
    let res = await auth.signOut();
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const getAllUsers = () => async (dispatch) => {
  let users = [];
  try {
    let querySnapShot = await db.collection("users").orderBy("date", "desc").get();

    querySnapShot.forEach(function (doc) {
      const data = doc.data();
      data.id = doc.id;
      users.push(data);
    });
    console.log(users);
    return users;
  } catch (error) {
    return [];
  }
};

export const getScholarshipStudents = () => async (dispatch) => {
  let users = [];
  try {
    let querySnapShot = await db.collection("Scholarship-Application").get();

    querySnapShot.forEach(function (doc) {
      const data = doc.data();
      data.id = doc.id;
      users.push(data);
    });
    return users;
  } catch (error) {
    return [];
  }
};
export const getRegisteredStudents = () => async (dispatch) => {
  let users = [];
  try {
    let querySnapShot = await db.collection("Registered-Students").get();

    querySnapShot.forEach(function (doc) {
      const data = doc.data();
      data.id = doc.id;
      users.push(data);
    });
    return users;
  } catch (error) {
    return [];
  }
};
