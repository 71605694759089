import Index from "views/Index.js";
import Dashboard from "views/Dashboard/Index.js";
import Shared from "views/College/Shared";
import Login from "views/examples/Login.js";
import Colleges from "views/College";
import Blog from "views/Blog";
import ScholarshipAndAwards from "views/Scholarship and Awards";
import Counsellor from "views/Counsellor";
import Appointment from "views/Appointment";
import ContactQueries from "views/ContactQueries";
import TeamMember from "views/TeamMember";
import Footer from "views/Footer";
import StudentApplication from "views/StudentApplication";
import Courses from "views/Courses";
import Testomonial from "views/Testimonial";
import CollegeRanking from "views/College/CollegeRanking";
import AddStream from "views/Stream";
import TieUp from "views/TieUp";
import NewsLetter from "views/NewsLetter";
import Subscribers from "views/Suscribers";
import Scholarship from "views/Scholarship";
import RegisteredStudents from "views/RegisteredStudents";
import Contacts from "views/Contacts/Index";

var routes = [
  {
    path: "/contacts",
    name: "Contacts",
    icon: "ni ni-ungroup text-primary",
    component: Contacts,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/index",
    name: "User Lists",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
    canBeUsedBy: "admin/counsellor",
  },
  {
    path: "/student-application",
    name: "Student Application",
    icon: "ni ni-folder-17 text-primary",
    component: StudentApplication,
    layout: "/admin",
    canBeUsedBy: "admin/counsellor",
  },
  {
    path: "/scholarship-form-data",
    name: "Scholarship Application",
    icon: "ni ni-single-copy-04 text-primary",
    component: Scholarship,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/registered-student-data",
    name: "Registered Students",
    icon: "ni ni-single-02 text-primary",
    component: RegisteredStudents,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/contactqueries",
    name: "Contact Queries",
    icon: "ni ni-collection text-primary",
    component: ContactQueries,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/appointments",
    name: "Appointment",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: Appointment,
    layout: "/admin",
    canBeUsedBy: "admin/counsellor", //can also be seen by counsellor
  },
  {
    path: "/newsletter",
    name: "NewsLetter",
    icon: "ni ni-collection text-primary",
    component: NewsLetter,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/subscribers",
    name: "Subscribers",
    icon: "ni ni-collection text-primary",
    component: Subscribers,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/testomonial",
    name: "Testimonial",
    icon: "ni ni-satisfied text-primary",
    component: Testomonial,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "ni ni-single-copy-04 text-primary",
    component: Courses,
    layout: "/admin",
    canBeUsedBy: "admin",
  },

  {
    path: "/blogs",
    name: "Blog",
    icon: "ni ni-books text-primary",
    component: Blog,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/tie-up",
    name: "TieUp",
    icon: "ni ni-ungroup text-primary",
    component: TieUp,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/counsellor",
    name: "Counsellor",
    icon: "ni ni-paper-diploma text-primary",
    component: Counsellor,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/team-member",
    name: "Team Member",
    icon: "ni ni-ungroup text-primary",
    component: TeamMember,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/footer",
    name: "Footer",
    icon: "ni ni-ungroup text-primary",
    component: Footer,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/colleges",
    name: "Colleges",
    icon: "ni ni-paper-diploma text-primary",
    component: Colleges,
    layout: "/admin",
    canBeUsedBy: "admin",
  },

  {
    path: "/college-shared-infos",
    name: "Rank and facilities",
    icon: "ni ni-collection text-primary",
    component: Shared,
    layout: "/admin",
    canBeUsedBy: "admin",
  },

  {
    path: "/college-ranking",
    name: "College Ranking",
    icon: "ni ni-paper-diploma  text-primary",
    component: CollegeRanking,
    layout: "/admin",
    canBeUsedBy: "admin",
  },
  {
    path: "/scholarship-recruit",
    name: "Scholarship & Recruit",
    icon: "ni ni-ungroup text-primary",
    component: ScholarshipAndAwards,
    layout: "/admin",
    canBeUsedBy: "admin",
  },

  {
    path: "/login",
    // name: "Login",
    // icon: "ni ni-key-25 text-primary",
    component: Login,
    layout: "/auth",
    canBeUsedBy: "admin/counsellor",
  },
  // {
  //   path: "/register",
  //   // name: "Register",
  //   // icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  //   canBeUsedBy: "admin/counsellor",
  // },
];
export default routes;
