import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Form, Input, Button, Space, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ConfirmationBox from "components/ConfirmationBox";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { getRecruit, addRecruit } from "redux/actions/recruit_actions";

const AddRecrutingCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [recruitingCompany, setRecruitingCompany] = useState([]);
  const [addRecruitingCount, setAddRecruitingCount] = useState(0);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(addRecruit(values.top_recruiting_companies));
    await dispatch(getRecruit());
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    let { top_recruiting_companies } = fields;
    if (top_recruiting_companies && top_recruiting_companies.length) {
      recruitingCompany.forEach((icon) => {
        Object.assign(top_recruiting_companies[icon.index], {
          ["logo"]: icon.logo,
        });
      });
      form.setFieldsValue({ top_recruiting_companies });
      form.submit();
    } else {
      message.error("Please check fields unable to add");
    }
  };

  const saveLogoUrl = (url) => {
    setRecruitingCompany([...recruitingCompany, url]);
  };

  const removeLogoUrl = (index) => {
    setRecruitingCompany(recruitingCompany.filter((u) => u.index !== index));
  };

  return (
    <>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <div className="mt-5">
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.List name="top_recruiting_companies">
            {(fields, { add, remove }) => (
              <Row key={fields.index}>
                {fields.map((field, i) => {
                  return (
                    <Space key={i} align="baseline">
                      <Col lg="12">
                        <label className="form-control-label">Title</label>
                        <Form.Item
                          {...field}
                          name={[field.name, "title"]}
                          fieldKey={[field.fieldKey, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "Title name cannot be empty",
                            },
                          ]}
                        >
                          <Input placeholder="Title Name" />
                        </Form.Item>
                      </Col>
                      <Col lg="12">
                        <label className="form-control-label">Logo</label>
                        <Form.Item
                          {...field}
                          name={[field.name, "logo"]}
                          fieldKey={[field.fieldKey, "logo"]}
                          rules={[
                            {
                              required: true,
                              message: "please upload image logo",
                            },
                          ]}
                        >
                          <ImageUploader
                            form={form}
                            isInFormList={true} //on dynamic form
                            fieldKey={field.name} //on dynamic form
                            saveUrl={saveLogoUrl} //on dynamic form
                            removeUrl={removeLogoUrl} //on dynamic form
                            formField={`logo`}
                            folder="top-recruiting-companies"
                            url={
                              form.getFieldsValue()?.top_recruiting_companies[
                                field.key
                              ]?.logo
                            }
                          />
                        </Form.Item>
                      </Col>

                      <MinusCircleOutlined
                        onClick={() => {
                          setConformObj({
                            title: `Are you sure you want to delete ?`,
                            msg: "This process cannot be undone.",
                            onSuccess: () => {
                              remove(field.name);
                              removeLogoUrl(field.name);
                              setIsConformModal(false);
                              setAddRecruitingCount((prev) => prev - 1);
                            },
                          });
                          setIsConformModal(true);
                        }}
                      />
                    </Space>
                  );
                })}
                <Col lg="12">
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        setAddRecruitingCount((prev) => prev + 1);
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Recruiting Companies
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.List>
        </Form>
      </div>
      <div className="form-btn-container">
        <Button
          disabled={addRecruitingCount === 0 || loading}
          type="primary"
          onClick={onSubmit}
        >
          Add
        </Button>
      </div>
    </>
  );
};

export default AddRecrutingCompanies;
