import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBlog, editBlog } from "redux/actions/blog_actions";
import { getColleges } from "redux/actions/college_actions";
import { Form, Select, Input, Button } from "antd";
import TextEditor from "../../components/TextEditor";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import ImageUploader from "components/ImageUploader/ImageUploader";

const BlogEdit = (props) => {
  const [_colleges, set_colleges] = useState([]);
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();

  const { setIsEditModal, isEditModal, blogSlug, fetchBlogs } = props;
  const blog = useSelector((state) => state.blog.blog);
  const colleges = useSelector((state) => state.college.colleges);
  const loading = useSelector((state) => state.blog.addBlogLoading);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getColleges());
  }, []);

  useEffect(() => {
    set_colleges(
      colleges?.map((clg) => ({
        label: clg.name,
        value: clg.college_slug,
      }))
    );
  }, [colleges]);

  React.useEffect(() => {
    dispatch(getBlog(blogSlug));
  }, [blogSlug]);

  React.useEffect(() => {
    if (blog) {
      form.setFieldsValue({
        ...blog,
      });
      setBlogImageUrl(form?.getFieldValue()?.blog_imageURL);
    }
  }, [blog]);

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onFinish = async (values) => {
    setLoader(true);
    let data = {
      ...values,
      id: blog?._id,
    };

    await dispatch(editBlog(data));

    setLoader(false);
    setIsEditModal(false);
    await fetchBlogs();
  };

  const onSubmit = () => {
    form.submit();
  };

  const toggle = () => {
    setIsEditModal(false);
  };

  const categoryOption = [
    {
      label: "Business",
      value: "business",
    },
    {
      label: "Engineering",
      value: "engineering",
    },
    
    {
      label: "Software Dev",
      value: "software dev",
    },
    {
      label: "Science",
      value: "science",
    },
    {
      label: "Medical",
      value: "medical",
    },
    {
      label: "Other",
      value: "others",
    },
  ];

  return (
    <>
      <Modal isOpen={isEditModal} toggle={toggle} style={{ maxWidth: "800px" }}>
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Edit Blog</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  label="Blog Title"
                  className="form-control-label"
                  name="blog_title"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter blog name!",
                    },
                  ]}
                >
                  <Input placeholder="Blog Title" />
                </Form.Item>
              </Col>

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Blog Subtitle"
                  name="blog_subtitle"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter blog subtitle",
                    },
                  ]}
                >
                  <Input placeholder="Blog Subtitle" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              {/*  */}
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Category"
                  name="category"
                  // rules={[
                  //   {
                  //     // type: 'string',
                  //     required: true,
                  //     message: "Please enter category",
                  //   },
                  // ]}
                >
                  {/* categoryOption
                  <Input placeholder="category" /> */}
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select category"
                    optionFilterProp="children"
                    options={categoryOption}
                  />
                </Form.Item>
              </Col>
              <Col lg="6">
                <Form.Item
                  className="form-control-label"
                  label="Author Name"
                  name="author"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter author name!",
                    },
                  ]}
                >
                  <Input placeholder="author name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Tags"
                  name="tags"
                  // rules={[
                  //   {
                  //     type: "array",
                  //     required: true,
                  //     message: "Please enter colleges",
                  //   },
                  // ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select college"
                    optionFilterProp="children"
                    // multiple
                    options={_colleges}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .trim()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Image"
                  name="blog_imageURL"
                >
                  <ImageUploader
                    form={form}
                    formField="blog_imageURL"
                    folder="blog"
                    url={blogImageUrl}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Form.Item
                  className="form-control-label"
                  label="Description"
                  name="blog_desc"
                >
                  <TextEditor value={form?.getFieldValue()?.blog_desc} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="primary" onClick={onSubmit} loading={loader}>
            Update Blog
          </Button>
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BlogEdit;
