import { postService, getService } from "../commonServices";

export class CollegeBoardService {
  getCollegeBoard() {
    let url = "/collegeboard/getCollegeBoard";
    let data = getService(url);
    return data;
  }
  addCollegeBoard(board_data) {
    let url = "/collegeboard/addCollegeBoard";
    let body = JSON.stringify(board_data);
    let data = postService(url, body);
    return data;
  }
  deleteCollegeBoard(id) {
    let url = `/collegeboard/deleteCollegeBoard/${id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
