import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import { Form, Button, Select } from "antd";
import { editCollege } from "redux/actions/college_actions";
import { getScholarship } from "redux/actions/scholarship_actions";
import AddScholarship from "./AddScholarship";
import "./style.css";

const Scholarship = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const college = useSelector((state) => state.college.college);

  const [form] = Form.useForm();

  //redux state
  const scholarship = useSelector((state) => state.scholarship.scholarship);

  React.useEffect(() => {
    dispatch(getScholarship());
  }, []);

  React.useEffect(() => {
    form.setFieldsValue({
      scholarship: college?.scholarships?.map((item) => item?._id),
    });
  }, [college]);

  const onFinish = async (values) => {
    let scholarships = [];
    scholarship.forEach((item) => {
      const selectedItem = values.scholarship.find((id) => id === item?._id);
      if (selectedItem) {
        scholarships.push(item);
      }
    });

    setLoading(true);
    let _college = {
      id: college._id,
      scholarships: scholarships,
    };

    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Scholarship Offered</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="12">
                <Form.Item
                  className="form-control-label"
                  label="Scholarships"
                  name="scholarship"
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Field cannot be empty",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Add the scholarship"
                    className="custom-drop-down"
                  >
                    {scholarship &&
                      scholarship?.map((item, i) => (
                        <Select.Option key={i} value={item._id}>
                          <div className="scholarship-image-dropdown">
                            <div className="scholarship-image-container">
                              <img src={item.logo} alt="..." />
                            </div>
                            <div className="scholarship-image-title">
                              {item.title}
                            </div>
                          </div>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg="12">
                <div className="form-btn-container">
                  <Button type="primary" onClick={onSubmit} loading={loading}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          <AddScholarship />
        </CardBody>
      </Card>
    </>
  );
};

export default Scholarship;
