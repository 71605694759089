import { COLLEGES_TYPES, COLLEGE_TYPES, ADD_COLLEGE_TYPES } from "../types";


const initialState = {
    college: {},
    colleges: [],
    multiLoading: true,
    singleLoading: true,
    addCollegeLoading: false
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case COLLEGES_TYPES.GET_COLLEGES:
            return {
                ...state,
                colleges: payload,
            };
        case COLLEGE_TYPES.GET_COLLEGE:
            return {
                ...state,
                college: payload,
                singleLoading: false
            };
        case COLLEGES_TYPES.GET_COLLEGES_INIT:
            return {
                ...state,
                multiLoading: true,
            }
        case COLLEGES_TYPES.GET_COLLEGES_FINISH:
            return {
                ...state,
                multiLoading: false
            }
        case COLLEGE_TYPES.GET_COLLEGE_FINISH:
            return {
                ...state,
                singleLoading: false
            }
        case COLLEGE_TYPES.GET_COLLEGE_INIT:
            return {
                ...state,
                singleLoading: true,
                college: {}
            }
        case ADD_COLLEGE_TYPES.ADD_COLLEGE_INIT:
            return {
                ...state,
                addCollegeLoading: true
            }
        case ADD_COLLEGE_TYPES.ADD_COLLEGE_FINISH:
            return {
                ...state,
                addCollegeLoading: false
            }
        default:
            return state;
    }
}
