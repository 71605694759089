import { message } from "antd";
import { Gallery } from '../api/gallery_api';
import { GET_GALLERY_TYPES } from "../types";
import { storage } from "../../firebase";

const galleryAction = new Gallery();
export const addGallery = (data, isDelete = undefined) => async (dispatch) => {
  const res = await galleryAction.addGallery(data);

  if (res.isSuccess === true) {
    if (isDelete)
      message.success("Gallery image removed");
    else
      message.success("Images added successfully.");
    return "sucessfully added";
  } else {
    message.error("Failed to add gallery");
    return res.data;
  }
};

export const getGallery = (college_id) => async (dispatch) => {
  dispatch({
    type: GET_GALLERY_TYPES.GALLERY_BEGIN,
  });
  const res = await galleryAction.getGallery(college_id);
  if (res) {
    dispatch({
      type: GET_GALLERY_TYPES.GET_GALLERY,
      payload: res.data,
    });
  } else {
    return res.data;
  }
};

export const deleteGallery = (id, image) => async (dispatch) => {
  try {
    const res = await galleryAction.deleteGallery(id);
    await storage.refFromURL(image).delete();

    if (res.isSucess) {
      dispatch({
        type: GET_GALLERY_TYPES.GALLERY_END,
      });
      message.success("Gallery deleted");
    } else {
      dispatch({
        type: GET_GALLERY_TYPES.GALLERY_END,
      });
      message.error("Failed to delete");
    }
  } catch (error) {
    message.error("Server Errror");
  }
};
