import React from "react";
import TestomonialDetails from "./TestimonialDetails";
// core components
import Header from "components/Headers/Header.js";
import { ToastContainer } from "react-toastify";
const Testomonial = () => {
  return (
    <>
      <Header />
      <TestomonialDetails />
    </>
  );
};

export default Testomonial;
