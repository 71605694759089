import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Form, InputNumber, Button } from "antd";

import { editCollege } from "redux/actions/college_actions";
const Statistics = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setLoading(true);
    values = {
      statistics: values,
      id: college._id,
    };
    await dispatch(editCollege(values, college.college_slug));
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      placement: college?.statistics?.placement,
      average_salary: college?.statistics?.average_salary,
      total_recruting_companies: college?.statistics?.total_recruting_companies,
      highest_package: college?.statistics?.highest_package,
    });
  }, [college, form]);

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Statistics</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="12">
                <label>
                  <b>Placement in %</b>
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(3, "year").year()}
                  className="form-control-label"
                  name={["placement", "last"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber min={1} max={100} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(2, "year").year()}
                  className="form-control-label"
                  name={["placement", "second"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber min={1} max={100} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(1, "year").year()}
                  className="form-control-label"
                  name={["placement", "first"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber min={1} max={100} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>
                  <b>Average Salary </b>
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(3, "year").year()}
                  className="form-control-label"
                  name={["average_salary", "last"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(2, "year").year()}
                  className="form-control-label"
                  name={["average_salary", "second"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(1, "year").year()}
                  className="form-control-label"
                  name={["average_salary", "first"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <label>
                  <b>Highest Package</b>
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(3, "year").year()}
                  className="form-control-label"
                  name={["highest_package", "last"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(2, "year").year()}
                  className="form-control-label"
                  name={["highest_package", "second"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(1, "year").year()}
                  className="form-control-label"
                  name={["highest_package", "first"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <label>
                  <b>Total Recruting Companies</b>
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(3, "year").year()}
                  className="form-control-label"
                  name={["total_recruting_companies", "last"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(2, "year").year()}
                  className="form-control-label"
                  name={["total_recruting_companies", "second"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg="4">
                <Form.Item
                  label={moment().subtract(1, "year").year()}
                  className="form-control-label"
                  name={["total_recruting_companies", "first"]}
                  rules={[
                    {
                      type: "number",
                      // required: true,
                      message: "please add number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Statistics;
