import { GET_COLLEGE_BOARD_TYPES } from "../types";

const initialState = {
  collegeBoard: [],
  loader: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COLLEGE_BOARD_TYPES.COLLEGE_BOARD_BEGIN:
      return {
        ...state,
        loader: true,
      };
    case GET_COLLEGE_BOARD_TYPES.GET_COLLEGE_BOARD:
      return {
        ...state,
        collegeBoard: payload,
        loader: false,
      };
    default:
      return state;
  }
};
