import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Input } from "antd";
import { get } from "jquery";
// import { getCounsellorData } from "redux/actions/counsellor_actions";
import { getRegisteredStudents } from "../../redux/actions/auth_action";
import { Link } from "react-router-dom";
import "./style.css";
import LinkIcon from "@mui/icons-material/Link";

const RegisteredStudentsTable = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const getFireStoreScholarshipStudents = async () => {
    setLoading(true);

    let getUsers = await dispatch(getRegisteredStudents());
    console.log("getUsers", getUsers);

    setFormData([...getUsers]);
    // console.log("formdata", formData);
    // console.log("getUsers", getUsers);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreScholarshipStudents();
  }, []);

  // const columns = React.useMemo(() => [
  //   {}])

  const styles = {
    tableRowEven: {
      backgroundColor: "#f2f2f2",
    },
    tableRowOdd: {
      backgroundColor: "#ffffff",
    },
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const columns = React.useMemo(
    () => [
      // {
      //   title: "Date",
      //   dataIndex: "",
      //   ellipsis: true,
      //   render: (usersLists) => usersLists.DateSubmitted,
      // },
      {
        title: "Date",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        // maxWidth: 1,
        // minWidth: "50%",
        render: (usersLists) => ({
          children:
            usersLists.TimeSubmitted != undefined
              ? usersLists.DateSubmitted + " " + usersLists.TimeSubmitted
              : usersLists.DateSubmitted,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
        // width: "100%",
      },
      {
        title: "Application ID",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        //  render: (usersLists) => ({
        render: (usersLists) => ({
          children: usersLists.ApplicationId,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },

      {
        title: "Full Name",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.FullName,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Personal Email",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.PersonalEmail,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Nationality",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.Nationality,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Phone Number",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.PersonalPhoneNumber,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Date of Birth",
        dataIndex: "",
        ellipsis: true,
        render: (usersLists) => ({
          children: usersLists.DateofBirth,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Gender",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.Gender,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Address",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.Address,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "City",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.City,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "State",
        ellipsis: true,
        dataIndex: "",
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.State,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Country",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.Country,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      // {
      //   title: "Passport",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.Passport,
      // },
      // {
      //   title: "Applied For Passport",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.AppliedForPassport,
      // },
      {
        title: "Citizenship No./ Identification No.",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.CitizenshipNo,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Parent Name",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.ParentName,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Parent Email",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.ParentEmail,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Parent Phone Number",
        ellipsis: true,
        dataIndex: "",
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.ParentPhoneNumber,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Parent Address",
        ellipsis: true,
        dataIndex: "",
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.ParentAddress,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Level of Study",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.LevelOfStudy,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "School Marks",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.SchoolMarks,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "High School Marks",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.HighSchoolMarks,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Undergraduation Marks",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.UnderGraduateMarks,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Graduation Marks",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: usersLists.PostGraduateMarks,
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      // {
      //   title: "GRE",
      //   ellipsis: true,
      //   dataIndex: "",
      //    render: (usersLists) => ({
      // children: usersLists.GRE,
      // },
      // {
      //   title: "SAT",
      //   ellipsis: true,
      //   dataIndex: "",
      //    render: (usersLists) => ({
      // children: usersLists.SAT,
      // },
      // {
      //   title: "GMAT",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.GMAT,
      // },
      // {
      //   title: "SAT II",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.SATII,
      // },
      // {
      //   title: "TOEFL",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.TOEFL,
      // },
      // {
      //   title: "JEE Advance",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.JEEAdvance,
      // },
      // {
      //   title: "IELTS",
      //   dataIndex: "",
      //   ellipsis: true,
      //    render: (usersLists) => ({
      // children: usersLists.IELTS,
      // },
      {
        title: "Profile Photo",
        dataIndex: "",
        // ellipsis: true,
        //align: "top",
        // width: "100px",
        //  render: (usersLists) => ({
        // children: usersLists.ProfilePhoto,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.ProfilePhoto) &&
                usersLists.ProfilePhoto.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Passport/ Citizenship/ National ID",
        dataIndex: "",
        //align: "top",
        // ellipsis: true,
        //  render: (usersLists) => ({
        // children: usersLists.PassportOrCitizenship,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.PassportOrCitizenship) &&
                usersLists.PassportOrCitizenship.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "School Marksheet",
        dataIndex: "",
        //align: "top",
        // ellipsis: true,
        //  render: (usersLists) => ({
        // children: usersLists.SchoolMarksheet,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.SchoolMarksheet) &&
                usersLists.SchoolMarksheet.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "High School Marksheet",
        // ellipsis: true,
        dataIndex: "",
        //align: "top",
        //  render: (usersLists) => ({
        // children: usersLists.HighSchoolMarksheet,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.HighSchoolMarksheet) &&
                usersLists.HighSchoolMarksheet.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Undergraduation Marksheet",
        // ellipsis: true,
        dataIndex: "",
        //align: "top",
        //  render: (usersLists) => ({
        // children: usersLists.HighSchoolMarksheet,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.UnderGraduateMarksheet) &&
                usersLists.UnderGraduateMarksheet.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Postgraduation Marksheet",
        // ellipsis: true,
        dataIndex: "",
        //align: "top",
        //  render: (usersLists) => ({
        // children: usersLists.HighSchoolMarksheet,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.PostGraduateMarksheet) &&
                usersLists.PostGraduateMarksheet.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Other Documents",
        dataIndex: "",
        //align: "top",
        // ellipsis: true,
        //  render: (usersLists) => ({
        // children: usersLists.OtherDocuments,
        render: (usersLists) => ({
          children: (
            <>
              {Array.isArray(usersLists.OtherDocuments) &&
                usersLists.OtherDocuments.map((photo, index) => (
                  <div
                    key={photo + index}
                    style={{
                      //lineHeight: "1rem",
                      border: "1px solid grey",
                      margin: "5px 0",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "200px",
                      height: "33px",
                      overflow: "hidden",
                      textOverflow: "hidden",
                    }}
                  >
                    <Link
                      to={{ pathname: photo }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.currentTarget.style.color = "purple";
                      }}
                    >
                      {" "}
                      <div>{photo}</div>
                    </Link>
                  </div>
                ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
      {
        title: "Choices",
        dataIndex: "",
        ellipsis: true,
        //align: "top",
        render: (usersLists) => ({
          children: (
            <>
              {usersLists.ChoiceOfCollege.map((choice, index) => (
                <div
                  key={choice + index}
                  style={{
                    border: "1px solid grey",
                    margin: "5px 0",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <div>
                    <strong>Stream:</strong> {choice.stream}
                  </div>
                  <div>
                    <strong>Program:</strong> {choice.program}
                  </div>
                </div>
              ))}
            </>
          ),
          props: {
            style: { verticalAlign: "top" },
          },
        }),
      },
    ],
    []
  );

  const filteredUser = formData
    ?.sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds)
    .filter((usersLists) => {
      return (
        usersLists?.DateSubmitted?.toLowerCase().includes(searchField) ||
        usersLists?.ApplicationId?.toLowerCase().includes(searchField) ||
        usersLists?.LevelOfStudy?.toLowerCase().includes(searchField) ||
        usersLists?.FullName?.toLowerCase().includes(searchField) ||
        usersLists?.PersonalEmail?.toLowerCase().includes(searchField) ||
        usersLists?.Nationality?.toLowerCase().includes(searchField) ||
        usersLists?.PersonalPhoneNumber?.toLowerCase().includes(searchField) ||
        usersLists?.DateofBirth?.toLowerCase().includes(searchField) ||
        usersLists?.Gender?.toLowerCase().includes(searchField) ||
        usersLists?.Address?.toLowerCase().includes(searchField) ||
        usersLists?.City?.toLowerCase().includes(searchField) ||
        usersLists?.State?.toLowerCase().includes(searchField) ||
        usersLists?.Country?.toLowerCase().includes(searchField) ||
        // usersLists?.Passport?.toLowerCase().includes(searchField) ||
        // usersLists?.AppliedForPassport?.toLowerCase().includes(searchField) ||
        usersLists?.CitizenshipNo?.toLowerCase().includes(searchField) ||
        usersLists?.ParentName?.toLowerCase().includes(searchField) ||
        usersLists?.ParentEmail?.toLowerCase().includes(searchField) ||
        usersLists?.ParentPhoneNumber?.toLowerCase().includes(searchField) ||
        usersLists?.ParentAddress?.toLowerCase().includes(searchField) ||
        usersLists?.SchoolMarks?.toLowerCase().includes(searchField) ||
        usersLists?.HighSchoolMarks?.toLowerCase().includes(searchField) ||
        usersLists?.UnderGraduateMarks?.toLowerCase().includes(searchField) ||
        usersLists?.PostGraduateMarks?.toLowerCase().includes(searchField)
        // usersLists?.GRE?.toLowerCase().includes(searchField) ||
        // usersLists?.SAT?.toLowerCase().includes(searchField) ||
        // usersLists?.GMAT?.toLowerCase().includes(searchField) ||
        // usersLists?.SATII?.toLowerCase().includes(searchField) ||
        // usersLists?.TOEFL?.toLowerCase().includes(searchField) ||
        // usersLists?.JEEAdvance?.toLowerCase().includes(searchField) ||
        // usersLists?.IELTS?.toLowerCase().includes(searchField) ||
        // usersLists?.PassportOrCitizenship?.toLowerCase().includes(
        //   searchField
        // ) ||
        // usersLists?.ProfilePhoto?.toLowerCase().includes(searchField) ||
        // usersLists?.SchoolMarksheet?.toLowerCase().includes(searchField) ||
        // usersLists?.HighSchoolMarksheet?.toLowerCase().includes(searchField) ||
        // usersLists?.OtherDocuments?.toLowerCase().includes(searchField)
      );
    });

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader>
              <div className="card-header-content">
                <div className="card-title-content">
                  <Input.Search
                    placeholder="Search by name, email and coverage"
                    enterButton
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
                {/* {console.log("first", formData)} */}
              </div>
            </CardHeader>

            <style>
              {`.ant-table-cell {
                  border: 1px solid #e8e8e8;
              }`}
            </style>
            <AntdTable
              columns={columns}
              rowKey={(record) => {
                return record.id;
              }}
              dataSource={filteredUser}
              pagination={pagination}
              // loading={loading}
              size="small"
              // stripes="even"
              style={{ borderCollapse: "collapse" }}
              scroll={{ x: true }}
              rowClassName={rowClassName}
              bordered
            />
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default RegisteredStudentsTable;
