import React from "react";
import TieUpDetails from "./TieUpDetails";
// core components
import Header from "components/Headers/Header.js";
import { ToastContainer } from "react-toastify";
const Testomonial = () => {
  return (
    <>
      <Header />
      <TieUpDetails />
    </>
  );
};

export default Testomonial;
