import {NEWSLETTER_TYPES} from '../types';

const initialState =  {
    newsLetter:[],
}
export default (state = initialState,action) => {
    const {type,payload} = action;
    switch(type){
        case NEWSLETTER_TYPES.GET_ALL_NEWSLETTER:
            return{
                ...state,
                newsLetter:payload,
            }
            default:
                return state;
    }
}