import React, { useMemo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationBox from "components/ConfirmationBox";
import _ from "lodash";
import { Card, CardHeader } from "reactstrap";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table as AntdTable, Button, Input } from "antd";
import { deleteCollegeCourse } from "redux/actions/courses_action";
import EditCourse from "./EditCourse";

const Table = ({
  loading,
  fetchedCourses,
  fetchCourses,
  setLoading,
  college,
}) => {
  const [_courses, setCourses] = useState([]); //can be altered by onchange table

  const [course, setCourse] = useState(null);
  const [conformObj, setConformObj] = React.useState({
    title: "",
    msg: "",
    onSuccess: "",
  });
  const [isConformModal, setIsConformModal] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const pagination = {
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setCourses(fetchedCourses);
  }, [fetchedCourses]);

  const openEditCourse = async (course) => {
    console.log('course',course);
    setIsEditModalOpen(true);

    setCourse(course);
  };
  const deleteCourse = async (id) => {
    setLoading(true);
    await dispatch(deleteCollegeCourse({ id }));
    fetchCourses();
    setIsConformModal(false);
  };
  console.log('_courses',_courses);

  const filteredData = _courses?.filter((item) => {
    return (
      item?.courselevel?.name
        ?.toLowerCase()
        .includes(searchKeyword.toLowerCase()) ||
      item?.coursestream?.name
        ?.toLowerCase()
        .includes(searchKeyword.toLowerCase()) ||
      item?.courseprogram?.name
        ?.toLowerCase()
        .includes(searchKeyword.toLowerCase())
    );
  });

  const columns = useMemo(
    () => [
      {
        key: "level",
        title: "Level",
        dataIndex: "courselevel",
        width: "10%",
        render: (level) => level.name,
      },
      {
        key: "stream",
        title: "Stream",
        dataIndex: "coursestream",
        width: "20%",
        render: (stream) => (stream?.name ? stream?.name : "null"),
      },
      {
        key: "courseprogram",
        title: "Program",
        dataIndex: "courseprogram",
        width: "20%",
        render: (program) => program?.name,
      },
      {
        key: "action",
        title: "Action",
        align: "right",
        dataIndex: "",
        width: "10%",
        render: (course) => (
          <>
            <div
              className="text-right site-button-ghost-wrapper"
              style={{ justifyContent: "flex-end" }}
            >
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => openEditCourse(course)}
              />
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => {
                  setIsConformModal(true);
                  setConformObj({
                    title: `Are you sure you want to delete ${course?.name}?`,
                    msg: "This process cannot be undone.",
                    onSuccess: () => {
                      deleteCourse(course._id);
                      fetchCourses();
                    },
                  });
                }}
              />
            </div>
          </>
        ),
      },
    ],
    []
  );
  return (
    <div>
      <ConfirmationBox
        data={conformObj}
        setIsConformModal={setIsConformModal}
        isConformModal={isConformModal}
      />
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <div className="card-header-content">
            <div className="card-title-content">Courses</div>
            <div style={{ width: "35%" }}>
              <Input.Search
                placeholder="Search by author name, title and category"
                enterButton
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </div>
          </div>
        </CardHeader>
        {console.log('filteredData',filteredData)}
        <AntdTable
          columns={columns}
          rowKey={(record) => {
            return record._id;
          }}
          dataSource={filteredData}
          pagination={pagination}
          loading={loading}
          size="small"
          style={{ backgroundColor: "transparent" }}
        />
      </Card>
      <EditCourse
        fetchCourses={fetchCourses}
        course={course}
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
        college={college}
      />
    </div>
  );
};

export default Table;
