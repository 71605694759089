import React, { useMemo, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllStudentApplication,
  getStudentApplication,
} from "../../redux/actions/studentapplication_action";
import { EyeOutlined } from "@ant-design/icons";

import { Card, CardHeader, Container, Badge } from "reactstrap";

import { Table as AntdTable, Input, Button } from "antd";
import ViewModal from "./ViewModal/index";

const StudentApplicationDetails = () => {
  const [studentApplication, setStudentApplication] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const dispatch = useDispatch();
  const [selectedApplication, setSelectedApplication] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isApplicationViewModalOpen, setIsApplicationViewModalOpen] =
    React.useState(false);
  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const fetchStudentApplication = async () => {
    setLoading(true);
    const res = await dispatch(getAllStudentApplication());
    console.log('res',res)
  const sortedData=  res.sort((a, b)=>{return b.createdAt?.seconds*1000 - a.createdAt?.seconds*1000});
    setStudentApplication(sortedData);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchStudentApplication();
  }, []);

  const handleClick = async (id) => {
    const data = await dispatch(getStudentApplication(id));  
    if(data){
      setSelectedApplication({data});
      setIsApplicationViewModalOpen(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "",
        width: "20%",
        render: (studentApplication) =>
          studentApplication?.basicInformation?.fullName,
      },
      {
        title: "Applying for",
        dataIndex: "",
        width: "20%",
        render: (studentApplication) =>
          studentApplication?.basicInformation?.selectedLevel.label,
      },
      {
        title: "Course",
        dataIndex: "",
        width: "20%",
        render: (studentApplication) => {
          if (studentApplication?.selectedChoice?.length) {
            if (studentApplication?.selectedChoice?.length>1){
              return (
                <>
                  {studentApplication?.selectedChoice[0]?.collegeProgram}{" "}
                  <Badge color="info">
                    +{studentApplication?.selectedChoice?.length - 1}
                  </Badge>
                </>
              );
            }
            else{
              return `${ studentApplication?.selectedChoice[0]?.collegeProgram }`
            }
      
          } else {
            return `Not selected`;
          }
        },
      },
      {
        title: "College",
        dataIndex: "",
        width: "20%",
        render: (studentApplication) => {
          if (studentApplication?.selectedChoice?.length) {
            if (studentApplication?.selectedChoice?.length > 1){
              return (
                <>
                  {studentApplication?.selectedChoice[0]?.collegeName}{" "}
                  <Badge color="info">
                    +{studentApplication?.selectedChoice?.length - 1}
                  </Badge>
                </>
              );
            }else{
              return `${studentApplication?.selectedChoice[0]?.collegeName}`;
            }
          } else {
            return `Not selected`;
          }
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "10%",
        render: (status) => (
          <Badge
            color={
              status === "pending"
                ? "primary"
                : status === "approved"
                ? "success"
                : "danger"
            }
          >
            {status}
          </Badge>
        ),
      },
      {
        title: "Action",
        dataIndex: "",
        width: "15%",
        align: "right",
        render: (studentApplication) => (
          <div
            className="text-right site-button-ghost-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {
                handleClick(studentApplication.id);
              }}
            />
          </div>
        ),
      },
    ],
    []
  );
 
  const filteredData = studentApplication
  ?.filter((item) => {
    console.log('item',item)
    return (
      item?.basicInformation?.fullName
        ?.toLowerCase()
        ?.includes(searchField.toLowerCase()) ||
      item?.selectedChoice?.college_slug
        ?.toLowerCase()
        ?.includes(searchField.toLowerCase()) ||
      item?.status?.toLowerCase()?.includes(searchField.toLowerCase())
    );
  });
  return (
    <>
      <Container className="mt--7" fluid>
        <ViewModal
          isApplicationViewModalOpen={isApplicationViewModalOpen}
          setIsApplicationViewModalOpen={setIsApplicationViewModalOpen}
          selectedApplication={selectedApplication}
          fetchStudentApplication={fetchStudentApplication}
        />

        <div className="col">
          <Card className="shadow">
            <CardHeader>
              <div className="card-header-content">
                <div className="card-title-content">Student Application</div>
                <div>
                  <Input.Search
                    placeholder="Search by name, applying for and status"
                    enterButton
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
              </div>
            </CardHeader>

            <AntdTable
              columns={columns}
              rowKey={(studentApplication) => studentApplication.id}
              dataSource={filteredData}
              pagination={pagination}
              loading={loading}
              size="small"
              sticky
              scroll={{ x: true }}
              // scroll={{ y: 400 }}
            />
          </Card>
        </div>
      </Container>
    </>
  );
};

export default StudentApplicationDetails;
