import { db, storage } from "../../firebase";
import { message } from "antd";

export const addTieUp = (values) => async (dispatch) => {
  try {
    let doc = await db.collection("tieup").add({
      createdAt: new Date(),
      name: values.name,
      image_url: values.image_url,
      college_url:values.college_url
    });
    message.success("Tieups added succesfull");

    return doc;
  } catch (error) {
    console.error(error);
    message.error("Failed to add");
    return null;
  }
};

export const getAllTieUp = () => async (dispatch) => {
  let tieup = [];
  try {
    let querySnapshot = await db.collection("tieup").get();

    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      data.id = doc.id;
      tieup.push(data);
    });
    return tieup;
  } catch (error) {
    return tieup;
  }
};

export const getTieUp = (id) => async (dispatch) => {
  try {
    let doc = await db.collection("tieup").doc(id).get();

    if (doc.exists) {
      return { ...doc.data(), id: doc.id };
    } else {
      throw Error("no such document!");
    }
  } catch (error) {
    // console.log(error);
    return null;
  }
};

export const updateTieUp = (id, values) => async (dispatch) => {
  try {
    await db.collection("tieup").doc(id).update({
      name: values.name,
      image_url: values.image_url,
      college_url:values.college_url

      
    });
    message.success("TieUps update successfull");
    return true;
  } catch (error) {
    message.error("Failed to update");
    // console.log(error);
    return false;
  }
};

const deleteImage = async (image) => {
  const desertRef = storage.refFromURL(image);
  const isDeleted = await desertRef
    .delete()
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
  return isDeleted;
};

export const deleteTieUp = (id, image_url) => async (dispatch) => {
  try {
    await db.collection("tieup").doc(id).delete();
    await deleteImage(image_url);
    message.success("TieUp deleted");
    return "Deleted sucessfull";
  } catch (err) {
    message.error("Failed to delete");
    return null;
  }
};