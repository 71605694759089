import React, { useEffect, useState } from "react";
import { CardHeader, Row, CardBody, Col, Card } from "reactstrap";
import AddCourse from "./AddCourse";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { getCollegeCourses } from "redux/actions/courses_action";

const CoursesDetail = ({ setIsCourseComplete }) => {
  const [fetchedCourses, setFetchedCourses] = useState([]); //original data
  const college = useSelector((state) => state.college.college);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchCourses = async () => {
    setLoading(true);
    let data = await dispatch(getCollegeCourses(college._id));
    setLoading(false);
    setFetchedCourses(data);
    if (data.length) {
      setIsCourseComplete(true);
    } else {
      setIsCourseComplete(false);
    }
  };

  useEffect(() => {
    college && fetchCourses();
  }, [college]);
  return (
    <>
      <Row>
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
          <AddCourse college={college} fetchCourses={fetchCourses} />
        </Col>
      </Row>
      <Row>
        <Col className="order-xl-1 mt-4" xl="12">
          <Table
            fetchCourses={fetchCourses}
            loading={loading}
            setLoading={setLoading}
            fetchedCourses={fetchedCourses}
            college={college}
          />
        </Col>
      </Row>
    </>
  );
};

export default CoursesDetail;
