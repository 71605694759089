import React from "react";
import moment from "moment";
import Loader from "components/Loader";
import SendMailModal from "./SendMailModal";

// reactstrap components
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  //Form,
  Input,
  //Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { Button } from "antd";

const AppointmentUiCard = (props) => {
  const {
    setisAppointmentModalOpen,
    data,
    isAppointmentModalOpen,
    counsellorInfo,
    loader,
    appointmentArray,
    // setIsSendMailModalOpen,
  } = props;
  const toggle = () => {
    setisAppointmentModalOpen(false);
  };
  const [isSendMailModalOpen, setIsSendMailModalOpen] = React.useState(false);

  return (
    <>
      <Modal
        isOpen={isAppointmentModalOpen}
        toggle={toggle}
        style={{ minWidth: "80%" }}
      >
        {loader ? (
          <Loader />
        ) : (
          <div className="appoitment-container">
            <Card
              className="card-profile shadow bg-secondary"
              style={{ minWidth: "350px" }}
            >
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Counsellor Details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Row>
                <Col>
                  <div className="teammember-view-card-image-container mb-2">
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={
                        counsellorInfo?.imageURL
                          ? counsellorInfo?.imageURL
                          : "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
                      }
                    />
                  </div>
                </Col>
              </Row>

              <div className="text-center p-2">
                <h3>
                  {counsellorInfo?.name
                    ? counsellorInfo?.name
                    : "Counsellor Not Found"}
                </h3>
                <div className="h5 font-weight-300 appointment-counsellor-details">
                  {counsellorInfo?.nationality}
                </div>
                <div className="h5 mt-4 appointment-counsellor-details">
                  Type :{" "}
                  <span className="appointment-counsellor-details">
                    {counsellorInfo?.type}
                  </span>
                </div>
                <div>
                  Coverage Country :{" "}
                  <span className="appointment-counsellor-details">
                    {counsellorInfo?.coverage}
                  </span>
                </div>
                <hr className="my-4" />
                <p>{counsellorInfo?.desc}</p>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsSendMailModalOpen(data);
                  }}
                >
                  <span className="btn-inner--icon">Send mail</span>
                </Button>
              </div>
            </Card>

            {data.map((data, i) => {
              {console.log('data',data)}
              return (
                <Row key={i}>
                  <Col>
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Appointment Details</h3>
                          </Col>
                         
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <h6 className="heading-small text-muted mb-4">
                          Client information
                        </h6>

                        <Row>
                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Created Date:
                            </label>
                            <label className="ml-2 form-control-label">
                              {moment(data?.createdAt).format("DD MMM YYYY")}
                            </label>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Appointment Time:
                              </label>
                              <label className="ml-2 form-control-label">
                                {`${moment
                                  .unix(data?.date?.seconds)
                                  .format("DD MMM YYYY")} ${data?.time}`}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Full Name:
                              </label>
                              <label className="ml-2 form-control-label">
                                {data.name}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Email:
                              </label>
                              <label className="ml-2 form-control-label">
                                {data?.email}
                              </label>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Course :
                              </label>
                              <label className="ml-2 form-control-label">
                                {data?.course}
                              </label>
                            </FormGroup>
                          </Col>
                          
                        </Row>

                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Contact information
                        </h6>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Contact Code :
                              </label>
                              <label className="ml-2 form-control-label">
                                {data?.country_code}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Contact :
                              </label>
                              <label className="ml-2 form-control-label">
                                {data?.phone}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Country :
                              </label>
                              <label className="ml-2 form-control-label">
                                {data?.home_country}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Contact Medium :
                              </label>
                              <label className="ml-2 form-control-label">
                                {data?.contact_medium}({data?.contact_id})
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>

                        <hr className="my-4" />
                        {/* Description */}
                        <h6 className="heading-small text-muted mb-4">
                          Queries
                        </h6>

                        <FormGroup>
                          <label className="form-control-label">
                            Client says
                          </label>
                          <Input
                            className="form-control-alternative"
                            disabled
                            rows="4"
                            defaultValue={data?.description}
                            type="textarea"
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            })}
          </div>
        )}
        <SendMailModal
          // selectedStudents={selectedStudents}
          isSendMailModalOpen={isSendMailModalOpen}
          setIsSendMailModalOpen={setIsSendMailModalOpen}
          appointmentArray={appointmentArray}
          data={data}
        />
      </Modal>
    </>
  );
};

export default AppointmentUiCard;
