import React from "react";
import { Spin } from "antd";

const index = () => {
  return (
    <div className="loader-container">
      <Spin size="large" />
    </div>
  );
};

export default index;
