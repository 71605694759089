import React from "react";
import NewsLetterDetails from "./NewsLetterDetails";
import Layout from "../../layouts/index";
const subscriber = () => {
  return (
    <Layout>
      <NewsLetterDetails />
    </Layout>
  );
};

export default subscriber;
