import React from "react";
import { addTeammeber } from "redux/actions/team_member";
import { useDispatch } from "react-redux";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { Form, Input, Button } from "antd";
import ImageUploader from "components/ImageUploader/ImageUploader";

const AddTeammember = ({
  setAddTeamMember,
  addTeamMember,
  fetchTeammember,
}) => {
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoader(true);
    await dispatch(addTeammeber(values));
    setLoader(false);
    setAddTeamMember(false);
    fetchTeammember();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const toggle = () => {
    setAddTeamMember(false);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <Modal isOpen={addTeamMember} toggle={toggle} className="popup-modal">
      <ModalHeader toggle={toggle} className="modal--header">
        <div className="modal--title">Add Team Member</div>
      </ModalHeader>
      <ModalBody>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row>
            <Col lg="6" xs="12">
              <Form.Item
                label="Name"
                className="form-control-label"
                name="name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter team member name!",
                  },
                ]}
              >
                <Input placeholder="Full Name..." />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Email"
                name="email"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter team member Email",
                  },
                ]}
              >
                <Input placeholder="Email...." />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Types"
                name="type"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter Team member Type...",
                  },
                ]}
              >
                <Input placeholder="Type.." />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Facebook"
                name="facebook_link"
               
              >
                <Input placeholder="Facebook link" />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Instagram"
                name="insta_link"
                
              >
                <Input placeholder="Instagram link" />
              </Form.Item>
            </Col>
             <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Youtube"
                name="youtube_link"
                
              >
                <Input placeholder="Youtube link" />
              </Form.Item>
            </Col>
             <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="LinkedIn"
                name="linkedin_link"
                
              >
                <Input placeholder="LinkedIn link" />
              </Form.Item>
            </Col>


            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Twitter"
                name="twitter_link"
               
              >
                <Input placeholder="Twitter link" />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Medium"
                name="medium_link"
                
              >
                <Input placeholder="Platform" />
              </Form.Item>
            </Col>

            <Col lg="6" xs="12">
              <Form.Item
                className="form-control-label"
                label="Upload Image"
                name="imageURL"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "please upload team member image",
                  },
                ]}
              >
                <ImageUploader
                  form={form}
                  formField="imageURL"
                  folder="team-member"
                />
              </Form.Item>
            </Col>

            <Col lg="12" xs="12">
              <Form.Item
                className="form-control-label"
                label="Description"
                name="description"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "please enter team member description",
                  },
                ]}
              >
                <Input.TextArea placeholder="Description..." rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="primary" onClick={onSubmit} loading={loader}>
          Add
        </Button>
        <Button type="secondary" disabled={loader} onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTeammember;
