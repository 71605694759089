import React from "react";
import CourseDetails from "./CoursesDetail";
// core components
import Header from "components/Headers/Header.js";
const index = () => {
  return (
    <>
      <Header />
      <CourseDetails />
    </>
  );
};

export default index;
