import React from "react";
import {  db } from "../../firebase";
import moment from "moment";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Button, Input } from "antd";

const NewsLetterDetails = () => {
  const [subscriberArray, setSubscriberArray] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");  
  const [loading, setLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const getFireStoreSubscriber = async () => {
    const subscriber = [];
    console.log('subscriber',subscriber)
    await db
      .collection("subscriber")
      // .orderBy("createdAt", "desc")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          subscriber.push({
            id: doc.id,
            email: data.email,
          });
        });
      });

    setSubscriberArray([...subscriber]);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreSubscriber();
  }, []);

  const columns = React.useMemo(() => [
    {
      title: "Email",
      width: "45%",
      dataIndex:'email'
    },
  ]);
 

  const filteredData = subscriberArray
    ?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
    .filter((item) => {
      return (
        item?.email.toLowerCase().includes(searchField.toLowerCase())
      );
    });

  return (
    <>
      <Container className="mt--7" fluid>
        

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    {" "}
                    <Input.Search
                      placeholder="Search by name, email, country and course"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                   
         
                </div>
              </CardHeader>

              <AntdTable
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={filteredData}
                pagination={pagination}
                loading={loading}
                size="small"
                sticky
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default NewsLetterDetails;