import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, CardBody, Row, Col, ModalFooter } from "reactstrap";
import { Form, Button, Select } from "antd";
import { getRecruit } from "redux/actions/recruit_actions";
import AddRecrutingCompanies from "./AddRecrutingCompanies";

import { editCollege } from "redux/actions/college_actions";

const RecrutingCompanies = () => {
  const [loading, setLoading] = useState(false);

  //redux state
  const recruitCompany = useSelector((state) => state.recruit.recruitCompanies);
  const college = useSelector((state) => state.college.college);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getRecruit());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      recruit_companies: college?.top_recruiting_companies?.map(
        (item) => item?._id
      ),
    });
  }, [college]);

  const onFinish = async (values) => {
    setLoading(true);
    let recruit_company = [];

    recruitCompany.forEach((item) => {
      const selectedItem = values.recruit_companies.find(
        (id) => id === item._id
      );
      if (selectedItem) {
        recruit_company.push(item);
      }
    });

    let _college = {
      id: college._id,
      top_recruiting_companies: recruit_company,
    };

    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Top Recruiting Companies</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="12">
                <Form.Item
                  className="form-control-label"
                  label="Recruiting Companies"
                  name="recruit_companies"
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Field cannot be empty",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select recruiting companies"
                    className="custom-drop-down"
                  >
                    {recruitCompany &&
                      recruitCompany?.map((item, i) => (
                        <Select.Option key={i} value={item._id}>
                          <div className="scholarship-image-dropdown">
                            <div className="scholarship-image-container">
                              <img src={item.logo} alt="..." />
                            </div>
                            <div className="scholarship-image-title">
                              {item.title}
                            </div>
                          </div>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg="12">
                <div className="form-btn-container">
                  <Button type="primary" onClick={onSubmit} loading={loading}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          <AddRecrutingCompanies />
        </CardBody>
      </Card>
    </>
  );
};

export default RecrutingCompanies;
