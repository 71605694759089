import React, { useEffect } from "react";
import { db } from "../../firebase";
import moment from "moment";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { Table as AntdTable, Button, Input } from "antd";
import SendMailModal from "./SendMailModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllNewsletter } from "../../redux/actions/newsLetter_action";
const NewsLetterDetails = () => {
  const [subscriberArray, setSubscriberArray] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");

  const [isSendMailModalOpen, setIsSendMailModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });
  const dispatch = useDispatch();

  const fetchNewsLetter = async () => {
    await dispatch(getAllNewsletter());
  };
  useEffect(() => {
    fetchNewsLetter();
  }, []);
  const newsLetterList = useSelector((state) => state.newsLetter);
  console.log("newsLetterList", newsLetterList);

  const getFireStoreSubscriber = async () => {
    const subscriber = [];
    await db
      .collection("subscriber")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          subscriber.push({
            id: doc.id,
            email: data.email,
          });
        });
      });

    setSubscriberArray([...subscriber]);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreSubscriber();
  }, []);

  const columns = React.useMemo(() => [
    // {
    //   title: "Email",
    //   width: "20%",
    //   dataIndex: "email",
    // },

    {
      title: "Description",
      width: "25%",
      dataIndex: "description",
    },
    {
      title: "Title",
      width: "20%",
      dataIndex: "title",
    },
    // {
    //   title: "College Name",
    //   width: "20%",
    //   dataIndex: "college.name",
    // },
  ]);

  const filteredData = newsLetterList?.newsLetter?.filter((item) => {
    return item?.title.toLowerCase().includes(searchField.toLowerCase());
  });

  return (
    <>
      <Container className="mt--7" fluid>
        <SendMailModal
          // selectedColleges={selectedColleges}
          isSendMailModalOpen={isSendMailModalOpen}
          setIsSendMailModalOpen={setIsSendMailModalOpen}
          subscriberArray={subscriberArray}
        />

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">
                    {" "}
                    <Input.Search
                      placeholder="Search by name, email, country and course"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsSendMailModalOpen(true);
                    }}
                  >
                    <span className="btn-inner--icon">Send mail</span>
                  </Button>
                </div>
              </CardHeader>

              <AntdTable
                columns={columns}
                rowKey={(newsLetterList) => newsLetterList.id}
                dataSource={filteredData}
                pagination={pagination}
                loading={loading}
                size="small"
                sticky
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default NewsLetterDetails;
