import React from "react";
import { Row, Col } from "reactstrap";

const BasicInformation = ({ selectedApplication }) => {
  const { basicInformation } = selectedApplication;
  return (
    <div
      className="basic_info"
      style={{ marginTop: "35px", paddingRight: "20px" }}
    >
      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          marginBottom: "20px",
        }}
      >
        <b>Basic Information</b>
      </div>

      <Row>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Full Name :</span>{" "}
            <span className="text-detail">{basicInformation?.fullName}</span>
          </div>{" "}
        </Col>

        {/* <Col xs="6">
          <div className="text-container">
            <span className="title">Full Name :</span>{" "}
            <span className="text-detail">{basicInformation?.fullName}</span>
          </div>{" "}
        </Col> */}
        <Col xs="6">
          <div className="text-container">
            <span className="title">Email :</span>{" "}
            <span className="text-detail">{basicInformation?.email}</span>
          </div>{" "}
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">Country Code : </span>
            <span className="text-detail">{basicInformation?.countryCode}</span>
          </div>{" "}
        </Col>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Phone Number: </span>
            <span className="text-detail">{basicInformation?.phoneNumber}</span>
          </div>{" "}
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">Gender : </span>
            <span className="text-detail">{basicInformation?.gender}</span>
          </div>{" "}
        </Col>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Date of birth: </span>
            <span className="text-detail">{basicInformation?.DOB}</span>
          </div>{" "}
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">Nationality: </span>
            <span className="text-detail">{basicInformation?.nationality}</span>
          </div>{" "}
        </Col>
        <Col xs="6">
          <div className="text-container">
            <span className="title">Country: </span>
            <span className="text-detail">{basicInformation?.guardianCountry}</span>
          </div>{" "}
        </Col>

        <Col xs="6">
          <div className="text-container">
            <span className="title">Address: </span>
            <span className="text-detail">{basicInformation?.guardianAddress}</span>
          </div>{" "}
        </Col>
        <Col xs="6">
          <div className="text-container">
            <span className="title"> City : </span>
            <span className="text-detail">{basicInformation?.guardianCity}</span>
          </div>{" "}
        </Col>

        {/* <Col xs="6">
          <div className="text-container">
            <span className="title">Guardian Zip Code : </span>
            <span className="text-detail">{basicInformation?.guardianZipCode}</span>
          </div>{" "}
        </Col> */}
        <Col xs="6">
          <div className="text-container">
            <span className="title"> State: </span>
            <span className="text-detail">{basicInformation?.guardianState}</span>
          </div>{" "}
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div
            style={{
              borderBottom: "1px solid #E0E0E0",
              paddingBottom: "5px",
              color: "#333333",
              margin: "20px 0",
            }}
          >
            <b>Signature</b>
          </div>
        </Col>

        {basicInformation?.signatureImage && (
          <Col xs="6">
            <div className="text-container">
              <a
                href={basicInformation?.signatureImage}
                target="_blank"
                className="text-detail"
              >
                <img
                  alt="..."
                  style={{height:'100px',width:'100px'}}
                  // className="rounded-circle"
                  src={
                    basicInformation?.signatureImage || "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
                  }
                />
              </a>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BasicInformation;
