import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BasicInfo from "./BasicInfo";
import Courses from "./CollegeCourses";
import DetailInformation from "./DetailInformation";
import Medias from "./Medias";
import AdmissionProcess from "./AdmissionProcess";
import Facilities from "./Facilities";
import InternationalCollaboration from "./InternationalCollaboration";
import Gallery from "./Gallery";
import UniversityRanking from "./UniversityRanking";
import TrendingCourse from "./TrendingCourse";
import ColllegeAward from "./CollegeAward";
import CollegeRanking from "./CollegeRanking";
import Faculity from "./Faculity";
import NotableAlumni from "./NotableAlumni";
import RecrutingCompanies from "./RecrutingCompanies";
import Scholarship from "./Scholarship";
import Statistics from "./Statistics";
import { getCollegeCourses } from "redux/actions/courses_action";
import {
  Modal,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  ModalHeader,
} from "reactstrap";
import "../../StudentApplication/ViewModal/style.css";

const Index = ({ isEditCollegeDrawerOpen, setIsEditCollegeDrawerOpen }) => {
  const [fragment, setFragment] = React.useState("basicinfo");
  const [isBasicInfoComplete, setIsBasicInfoComplete] = useState(false);
  const [isCourseComplete, setIsCourseComplete] = useState(false);
  const [isDetailInfoComplete, setIsDetailInfoComplete] = useState(false);
  const [isAdmissionComplete, setIsAdmissionComplete] = useState(false);
  const [isFacilitiesComplete, setIsFacilitiesComplete] = useState(false);
  const [isIntCollabComplete, setIsIntCollabComplete] = useState(false);
  const [isTrendCourseComplete, setIsTrendCourseComplete] = useState(false);
  const [isAwardComplete, setIsAwardComplete] = useState(false);
  const [isRankComplete, setIsRankComplete] = useState(false);
  const [isMediasComplete, setIsMediasComplete] = useState(false);
  const [isFacultyComplete, setIsFacultyComplete] = useState(false);
  const [isAlumniComplete, setIsAlumniComplete] = useState(false);
  const [isRecrutingComplete, setIsRecrutingComplete] = useState(false);
  const [isScholarshipComplete, setIsScholarshipComplete] = useState(false);
  const [isGalleryComplete, setIsGalleryComplete] = useState(false);
  const [isUniversityRankComplete, setIsUniversityRankComplete] = useState(false);
  const [isStatisticsComplete, setIsStatisticsComplete] = useState(false);

  const college = useSelector((state) => state.college.college);
  const dispatch = useDispatch();
  const getCourses = async (id) => {
    let courses = await dispatch(getCollegeCourses(id));
    //courses
    if (!courses.length) {
      setIsCourseComplete(false);
    } else {
      setIsCourseComplete(true);
    }
  };

  // console.log(fragment);

  useEffect(() => {
    college._id && getCourses(college._id);
    const {
      name,
      address,
      estd_year,
      total_course,
      total_students,
      email,
      placement_percentage,
      graduation_percentage,
      average_fee,
      institution_type,
      college_board,
      college_logo,
      description,
      highest_package,
      mim_cost_living,
      affliated_by,
      nirf,
      QS_ranking,
      university_ranking,
      overview,
      college_profile,
      addmission_process,
      college_awards,
      college_rankings,
      facilities,
      international_collaboration,
      college_videos,
      video_360,
      college_photos,
      banner,
      alumni,
      faculty,
      scholarships,
      trending_courses,
      top_recruiting_companies,
      statistics,
    } = college;

    //statistics
    if (
      !statistics?.placement ||
      !statistics?.average_salary ||
      !statistics?.total_recruting_companies ||
      !statistics?.highest_package
    ) {
      setIsStatisticsComplete(false);
    } else {
      setIsStatisticsComplete(true);
    }

    //recurting companies
    if (!top_recruiting_companies?.length) {
      setIsRecrutingComplete(false);
    } else {
      setIsRecrutingComplete(true);
    }

    //trending courses
    if (!trending_courses?.length) {
      setIsTrendCourseComplete(false);
    } else {
      setIsTrendCourseComplete(true);
    }

    //scholarship
    if (!scholarships) {
      setIsScholarshipComplete(false);
    } else {
      setIsScholarshipComplete(true);
    }

    //faculity
    if (!faculty?.total_faculity || !faculty?.major_faculty?.length) {
      setIsFacultyComplete(false);
    } else {
      setIsFacultyComplete(true);
    }

    //alumni
    if (!alumni) {
      setIsAlumniComplete(false);
    } else {
      setIsAlumniComplete(true);
    }

    //medias
    if (!college_videos || !video_360 || !college_photos || !banner) {
      setIsMediasComplete(false);
    } else {
      setIsMediasComplete(true);
    }

    //international collage
    if (!international_collaboration?.length) {
      setIsIntCollabComplete(false);
    } else {
      setIsIntCollabComplete(true);
    }
    // basic info
    if (
      name &&
      address &&
      estd_year &&
      total_course &&
      total_students &&
      placement_percentage &&
      graduation_percentage &&
      average_fee &&
      college_logo &&
      email &&
      institution_type &&
      college_board
    ) {
      setIsBasicInfoComplete(true);
    } else {
      setIsBasicInfoComplete(false);
    }

    //detail info
    if (
      nirf &&
      QS_ranking &&
      university_ranking &&
      highest_package &&
      mim_cost_living &&
      affliated_by &&
      overview &&
      college_profile &&
      description
    ) {
      setIsDetailInfoComplete(true);
    } else {
      setIsDetailInfoComplete(false);
    }

    //admission process
    if (!addmission_process) {
      setIsAdmissionComplete(false);
    } else {
      setIsAdmissionComplete(true);
    }

    //college award
    if (!college_awards?.length) {
      setIsAwardComplete(false);
    } else {
      setIsAwardComplete(true);
    }

    //college ranking
    if (!college_rankings?.length) {
      setIsRankComplete(false);
    } else {
      setIsRankComplete(true);
    }

    //facilities
    if (!facilities?.length) {
      setIsFacilitiesComplete(false);
    } else {
      setIsFacilitiesComplete(true);
    }
  }, [college]);
  const loadFragment = () => {
    switch (fragment) {
      case "basicinfo":
        return <BasicInfo setIsBasicInfoComplete={setIsBasicInfoComplete} />;
      case "courses":
        return <Courses setIsCourseComplete={setIsCourseComplete} />;
      case "detailinformation":
        return (
          <DetailInformation
            setIsDetailInfoComplete={setIsDetailInfoComplete}
          />
        );
      case "admissionprocess":
        return (
          <AdmissionProcess setIsAdmissionComplete={setIsAdmissionComplete} />
        );
      case "facilities":
        return <Facilities setIsFacilitiesComplete={setIsFacilitiesComplete} />;
      case "internationalcollaboration":
        return (
          <InternationalCollaboration
            setIsIntCollabComplete={setIsIntCollabComplete}
          />
        );
      case "trendingcourse":
        return (
          <TrendingCourse setIsTrendCourseComplete={setIsTrendCourseComplete} />
        );
      case "collegeaward":
        return <ColllegeAward setIsAwardComplete={setIsAwardComplete} />;
      case "collegeranking":
        return <CollegeRanking setIsRankComplete={setIsRankComplete} />;
      case "statistics":
        return <Statistics setIsStatisticsComplete={setIsStatisticsComplete} />;
      case "medias":
        return <Medias setIsMediasComplete={setIsMediasComplete} />;
      // case "collegenews":
      //   return <CollegeNews />;
      case "faculity":
        return <Faculity setIsFacultyComplete={setIsFacultyComplete} />;
      case "notablealumni":
        return <NotableAlumni setIsAlumniComplete={setIsAlumniComplete} />;
      case "recrutingcompanies":
        return (
          <RecrutingCompanies setIsRecrutingComplete={setIsRecrutingComplete} />
        );
      case "scholarship":
        return (
          <Scholarship setIsScholarshipComplete={setIsScholarshipComplete} />
        );
        case "gallery":
          return(
            <Gallery setIsGalleryComplete={setIsGalleryComplete}/>
          );
          case "universityranking":
            return(
              <UniversityRanking setIsUniversityRankComplete={setIsUniversityRankComplete}/>
            )
          
      default:
    }
  };
  const toggle = () => {
    setIsEditCollegeDrawerOpen(false);
    setFragment("basicinfo");
  };

  return (
    <>
      <Modal isOpen={isEditCollegeDrawerOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Edit College</ModalHeader>
        <Row className="pt-4">
          <Col>
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col>
                  <div className="img-container" style={{ marginTop: "15px" }}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={
                        college?.college_logo
                          ? college.college_logo
                          : "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
                      }
                    />
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0   pb-0 pb-md-4">
                <div className="d-flex justify-content-between"></div>
              </CardHeader>
              <CardBody className="pt-0 ">
                <div className="text-center col  ">
                  <h3>{college?.name}</h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {college?.address}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    ESTD: {college?.estd_year}
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Total Courses: {college?.total_course}
                  </div>
                </div>
                <div>
                  <hr className="my-4" />
                  <Button
                    color={isBasicInfoComplete ? "primary" : "warning"}
                    block
                    outline
                    size="large"
                    className="mt-2 ml-1"
                    onClick={() => setFragment("basicinfo")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "basicinfo"}
                  >
                    Basic Information
                  </Button>
                  <Button
                    color={isDetailInfoComplete ? "primary" : "warning"}
                    outline
                    block
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("detailinformation")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "detailinformation"}
                  >
                    Detail Information
                  </Button>
                  <Button
                    color={isUniversityRankComplete ? "primary" : "warning"}
                    outline
                    block
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("universityranking")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "universityranking"}
                  >
                    University Ranking
                  </Button>
                  <Button
                    color={isCourseComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("courses")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "courses"}
                  >
                    Courses
                  </Button>

                  <Button
                    color={isAdmissionComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("admissionprocess")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "admissionprocess"}
                  >
                    Admission Process
                  </Button>
                  <Button
                    color={isFacilitiesComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("facilities")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "facilities"}
                  >
                    Facilities
                  </Button>
                  <Button
                    color={isIntCollabComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("internationalcollaboration")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "internationalcollaboration"}
                  >
                    International Collaboration
                  </Button>
                  <Button
                    color={isTrendCourseComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("trendingcourse")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "trendingcourse"}
                  >
                    Trending Course
                  </Button>
                  <Button
                    color={isAwardComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("collegeaward")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "collegeaward"}
                  >
                    College Award
                  </Button>
                  <Button
                    color={isRankComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("collegeranking")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "collegeranking"}
                  >
                    College Ranking
                  </Button>
                  <Button
                    color={isStatisticsComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("statistics")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "statistics"}
                  >
                    Statistics
                  </Button>
                  <Button
                    color={isFacultyComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("faculity")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "faculity"}
                  >
                    Faculty
                  </Button>
                  <Button
                    color={isMediasComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("medias")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "medias"}
                  >
                    Medias
                  </Button>
                  <Button
                    color={isAlumniComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("notablealumni")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "notablealumni"}
                  >
                    Notable Alumni
                  </Button>
                  <Button
                    color={isRecrutingComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("recrutingcompanies")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "recrutingcompanies"}
                  >
                    Recruiting Companies
                  </Button>
                  <Button
                    color={isScholarshipComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("scholarship")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "scholarship"}
                  >
                    Scholarship
                  </Button>
                  <Button
                    color={isGalleryComplete ? "primary" : "warning"}
                    block
                    outline
                    className="mt-2"
                    size="large"
                    onClick={() => setFragment("gallery")}
                    style={{ fontWeight: "400", borderRadius: "1px" }}
                    active={fragment === "gallery"}
                  >
                  Gallery
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="" xl="8">
            {loadFragment()}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Index;
