import React from "react";
import AddScholarship from "./AddScholarship";
import AddRecruitingCompanies from "./AddRecruitingCompanies";
import ScholarshipTable from "./ScholarshipTable";
import RecruitCompaniesTable from "./RecruitingTable";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import { Button } from "antd";

// core components
import Layout from "../../layouts/index";

const index = () => {
  return (
    <>
      <Layout>
        <Container className="mt--7" fluid>
          <Row>
            <Col>
              <Card>
                <ScholarshipTable />
              </Card>
            </Col>
            <Col>
              <Card>
                <RecruitCompaniesTable />
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default index;
