import React from "react";
import { db } from "../../firebase";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { EyeOutlined } from "@ant-design/icons";
import ContactQueriesUiCard from "./ContactQueriesUiCard";
import { Table as AntdTable, Button, Input } from "antd";

const ContactQueries = () => {
  const [contactQueries, setContactQueries] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [viewOpenModal, setViewOpenModal] = React.useState(false);
  const [selectedContactData, setSelectedContactData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const pagination = {
    total: 0,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const getFireStoreCounselor = async () => {
    const contact = [];

    await db
      .collection("contact")
      .orderBy("createdAt",'desc')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          contact.push({
            id: doc.id,
            createdAt: data.createdAt,
            name: data.name,
            subject: data.subject,
            email: data.email,
            query: data.query,
          });
        });
      });
    setContactQueries([...contact]);
    setLoading(false);
  };

  React.useEffect(() => {
    getFireStoreCounselor();
  }, []);

  const viewModal = (res, id) => {
    setViewOpenModal(res);

    const filterData = contactQueries.find(
      (contactQueries) => contactQueries.id === id
    );

    setSelectedContactData(filterData);
  };

  const columns = React.useMemo(() => [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Query",
      dataIndex: "query",
      width: "30%",
      render: (qry) => `${qry.substring(0, 20)}...`,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      align: "right",
      render: (contact) => (
        <div
          className="text-right site-button-ghost-wrapper"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => {
              viewModal(true, contact.id);
            }}
          />
        </div>
      ),
    },
  ]);

  const filteredData = contactQueries
    ?.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
    .filter((item) => {
      return (
        item?.name.toLowerCase().includes(searchField.toLowerCase()) ||
        item?.email.toLowerCase().includes(searchField.toLowerCase())
        // item?.subject.toLowerCase().includes(searchField.toLowerCase())
      );
    });
  return (
    <>
      <Container className="mt--7" fluid>
        {viewOpenModal && (
          <ContactQueriesUiCard
            data={selectedContactData}
            viewModal={viewModal}
          />
        )}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <div className="card-header-content">
                  <div className="card-title-content">Contact Queries</div>
                  <div>
                    <Input.Search
                      placeholder="Search by name, email and subject"
                      enterButton
                      value={searchField}
                      onChange={(e) => setSearchField(e.target.value)}
                    />
                  </div>
                </div>
              </CardHeader>

              <AntdTable
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={filteredData}
                pagination={pagination}
                loading={loading}
                size="small"
                sticky
                scroll={{ x: true }}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ContactQueries;
