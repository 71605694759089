import { GET_RECRUIT_TYPES } from "../types";

const initialState = {
  recruitCompanies: [],
  loader: false,
};
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUIT_TYPES.RECRUIT_BEGIN:
      return {
        ...state,
        loader: true,
      };
    case GET_RECRUIT_TYPES.GET_RECRUIT:
      return {
        ...state,
        recruitCompanies: payload,
        loader: false,
      };
    case GET_RECRUIT_TYPES.RECRUIT_END:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};
