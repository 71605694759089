import { GET_FACILITIES_TYPES } from "../types";
import { FacilitiesService } from "redux/api/facilities_api";
import { message } from "antd";
import { storage } from "../../firebase";

const facilitiesService = new FacilitiesService();
export const addFacilities = (data) => async (dispatch) => {
  const res = await facilitiesService.addFacilities(data);
  if (res.isSuccess) {
    message.success("Facilities Added");
  } else {
    message.error(res.errorMessage);
  }
};

export const getFacilities = () => async (dispatch) => {
  dispatch({
    type: GET_FACILITIES_TYPES.FACILITIES_BEGIN,
  });
  const res = await facilitiesService.getFacilities();

  if (res.isSuccess) {
    dispatch({
      type: GET_FACILITIES_TYPES.GET_FACILITIES,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_FACILITIES_TYPES.FACILITIES_END,
    });
    message.error(res.errorMessage);
  }
};

export const deleteFacilities = (id, image) => async (dispatch) => {
  try {
    const res = await facilitiesService.deleteFacilities(id);
    if (res.isSuccess) {
      await storage.refFromURL(image).delete();
      message.success("Rank deleted");
    } else {
      message.error(res.errorMessage);
    }
  } catch (error) {
    message.error("Server error");
  }
};
