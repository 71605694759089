import React from "react";
import { Form, Select, Button } from "antd";
import { addTopColleges, getTopColleges } from "redux/actions/footer_actions";
import { useSelector, useDispatch } from "react-redux";
import { getColleges } from "redux/actions/college_actions";

const AddTopColleges = () => {
  const [loader, setLoader] = React.useState(false);
  //redux state
  const collegeList = useSelector((state) => state.college.colleges);
  const topCollegeList = useSelector((state) => state.footer.topColleges);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  React.useEffect(() => {
    dispatch(getColleges());
    dispatch(getTopColleges());
  }, []);

  React.useEffect(() => {
    if (topCollegeList) {
      form.setFieldsValue({
        top_colleges: topCollegeList.map((college) => college.college_id),
      });
    }
  }, [topCollegeList]);

  const onFinish = async (values) => {
    setLoader(true);
    let top_colleges = [];

    collegeList.forEach((college) => {
      const selectedCollege = values.top_colleges.find(
        (c) => c === college?._id
      );
      if (selectedCollege) {
        top_colleges.push({
          college_id: college?._id,
          college_name: college?.name,
          college_slug: college?.college_slug,
        });
      }
    });

    await dispatch(addTopColleges(top_colleges));
    setLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };

  return (
    <div className="p-4">
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          className="form-control-label"
          label="Add Top Colleges"
          name="top_colleges"
          rules={[
            {
              type: "array",
              required: true,
              message: "Field cannot be empty",
            },
          ]}
        >
          <Select
            mode="multiple"
            label="Add top Colleges"
            style={{ width: "100%" }}
            placeholder="Please select"

            // onChange={handleChange}
          >
            {collegeList &&
              collegeList?.map((college, i) => {
                return (
                  <Select.Option value={college?._id} key={i}>
                    {college?.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>

      <Button type="primary" onClick={onSubmit} loading={loader}>
        Update Top College
      </Button>
    </div>
  );
};

export default AddTopColleges;
