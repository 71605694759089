import { postService, getService } from "../commonServices";

export class SendMailService {
  sendMailToCollege(mail_info) {
    let url = "/sendmail/sendMailToCollege";
    // let body = JSON.stringify(mail_info);

    let body_data = {
      to: mail_info.to,
      overview: mail_info.body,

      user: {
        fullname: mail_info?.selectedApplication?.basicInformation?.fullName,
        gender: mail_info?.selectedApplication?.basicInformation?.gender,
        email: mail_info?.selectedApplication?.basicInformation?.email,

        dob: mail_info?.selectedApplication?.basicInformation?.DOB,
        contact: `${mail_info?.selectedApplication?.basicInformation?.countryCode} - ${mail_info?.selectedApplication?.basicInformation?.phoneNumber}`,
        address:
          mail_info?.selectedApplication?.basicInformation?.guardianState +
          ", " +
          mail_info?.selectedApplication?.basicInformation?.guardianCountry,
      },
      selected_Level: {
        level:
          mail_info?.selectedApplication?.basicInformation?.selectedLevel.label,
        schoolMarks:
          mail_info?.selectedApplication?.academicInformation?.schoolMarks
            .schoolMarks,
        level1Score:
          mail_info?.selectedApplication?.academicInformation?.level1Score,
        level2Score:
          mail_info?.selectedApplication?.academicInformation?.level2Score
            ?.level2Score,
        underGraduate:
          mail_info?.selectedApplication?.academicInformation?.underGraduate
            ?.underGraduate,
        semesterMarks:
          mail_info?.selectedApplication?.academicInformation?.semesterMarks,
        postGraduteScore:
          mail_info?.selectedApplication?.academicInformation?.postGraduteScore
            ?.postGraduteScore,
      },
      score: {
        writing:
          mail_info?.selectedApplication?.academicInformation?.ielts?.subMars
            ?.writing,
        listening:
          mail_info?.selectedApplication?.academicInformation?.ielts?.subMars
            ?.listining,
        reading:
          mail_info?.selectedApplication?.academicInformation?.ielts?.subMars
            ?.reading,
        speaking:
          mail_info?.selectedApplication?.academicInformation?.ielts?.subMars
            ?.speaking,
      },
      documents: [
        {
          link: mail_info?.selectedApplication?.academicInformation?.school,
          title: "school",
        },
        {
          link: mail_info?.selectedApplication?.academicInformation?.highschool,
          title: "highschool",
        },
      ],
    };
    let data = postService(url, body_data);
    console.log("body_data", body_data);
    return data;
  }

  sendOfferLetterMail(mail_info) {
    let url = "/sendmail/send/offerletter";
    let body_data = {
      to: mail_info.selectedApplication.basicInformation.email,
      email: mail_info.selectedApplication.basicInformation.email,
      status: mail_info.status,
      selected_Level: {
        level:
          mail_info?.selectedApplication?.basicInformation?.selectedLevel.label,
      },

      offer_letter: mail_info?.offer_letter,
    };
    let data = postService(url, body_data);
    return data;
  }

  sendMailToStudent(mail_info) {
    let url = "/sendmail/sendMailToStudent";
    // let body = JSON.stringify(mail_info);
    let body = {
      email: mail_info.to,
      body: mail_info.body,
      subject: mail_info.subject,
      counselor:mail_info.counselor,
      studentname:mail_info.studentname,
      appointmentDate:mail_info.appointmentDate

    };
    let data = postService(url, body);
    return data;
  }
  sendMailToSubscribers(mail_info) {
    let url = "/sendmail/subscriber";
    let body = {
      // to: mail_info.to,
      college: mail_info.college,
      offer: mail_info.offer,
      subject: mail_info.subject,
    };
    let data = postService(url, body);
    return data;
  }
}
