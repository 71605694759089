import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import Table from "./Table";
import { useDispatch } from "react-redux";
import { getCourses } from "redux/actions/courses_action";

const CoursesDetail = (props) => {
  const [fetchedCourses, setFetchedCourses] = useState([]); //original data
  console.log('fetchedCourses',fetchedCourses)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchCourses = async () => {
    setLoading(true);
    let data = await dispatch(getCourses());
    setLoading(false);
    setFetchedCourses(data);
  };

  useEffect(() => {
    fetchCourses();
  }, []);
  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Table
              fetchCourses={fetchCourses}
              loading={loading}
              setLoading={setLoading}
              fetchedCourses={fetchedCourses}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CoursesDetail;
