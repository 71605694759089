import { postService, getService } from "../commonServices";

export class RankService {
  getRank() {
    let url = "/rank/getRank";
    let data = getService(url);
    return data;
  }

  addRank(rank_data) {
    let url = "rank/addRank";
    let body = JSON.stringify(rank_data);
    let data = postService(url, body);
    return data;
  }

  deleteRank(id) {
    let url = `/rank/deleteRank/${id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }

  getUniversityRank() {
    let url = "/ranking/getallrankings";
    let data = getService(url);
    return data;
  }
  addUniversityRank(universityrank_data) {
    let url = "/ranking/addranking";
    let body = JSON.stringify(universityrank_data);
    let data = postService(url, body);
    return data;
  }
  deleteUniversityRank(id) {
    let url = `/ranking/deleteranking/${id}`;
    let data = postService(url, undefined, "DELETE");
    return data;
  }
}
