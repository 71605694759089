import { message } from "antd";
import { ScholarshipService } from "redux/api/scholarship_api";
import { GET_SCHOLARSHIP_TYPES } from "../types";
import { storage } from "../../firebase";

const scholarshipService = new ScholarshipService();
export const addScholarship = (data) => async (dispatch) => {
  // const dispatch = useDispatch();
  const res = await scholarshipService.addScholarship(data);

  if (res.isSuccess === true) {
    message.success("Scholarship added");
    return "successfully added";
  } else {
    message.error("Failed to add scholarship");
    return res.data;
  }
};

export const getScholarship = () => async (dispatch) => {
  dispatch({
    type: GET_SCHOLARSHIP_TYPES.SCHOLARSHIP_BEGIN,
  });
  const res = await scholarshipService.getScholarship();
  if (res) {
    dispatch({
      type: GET_SCHOLARSHIP_TYPES.GET_SCHOLARSHIP,
      payload: res.data,
    });
  } else {
    return res.data;
  }
};

export const deleteScholarship = (id, image) => async (dispatch) => {
  try {
    const res = await scholarshipService.deleteScholarship(id);
    await storage.refFromURL(image).delete();

    if (res.isSuccess) {
      dispatch({
        type: GET_SCHOLARSHIP_TYPES.SCHOLARSHIP_END,
      });
      message.success("Scholarship deleted");
    } else {
      dispatch({
        type: GET_SCHOLARSHIP_TYPES.SCHOLARSHIP_END,
      });
      message.error("Failed to delete");
    }
  } catch (error) {
    message.error("Server Error");
  }
};
