import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { mailToCollege } from "redux/actions/sendmail_actions";
import { Form, Select, Input, Button, Checkbox} from "antd";
import "./style.css";

const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;

const SendMailModal = (props) => {
  const children = [];

  const [loading, setLoading] = useState(false);
  const { selectedApplication, setIsMailModal, isMailModal, documents, documentLinks } = props;

  const plainOptions = documents;
  const defaultCheckedList = [];

  const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  
for (let i = 10; i < 36; i++) {
  // children.push(<Select.Option key={i.toString(36) + i}>{i.toString(36) + i}</Select.Option>);
}

const mails=selectedApplication?.selectedChoice?.map((choice, i) => (
  children.push(<Select.Option key={i} value={choice.collegeEmail}>{choice.collegeName}</Select.Option>)
))

  const collegeEmails = useMemo(() => {
    return selectedApplication?.selectedChoice?.map((choice, i) => (
      {
      label: choice.collegeName,
      value: choice.collegeEmail,
      key: i.toString(36) + i,
      id: i.toString(36) + i,
    }));

  }, [selectedApplication]);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const {documents, overview, score, selected_Level, to, user, body} = values;
    // console.log('overview',overview)
    let student_documents = []
    {documents?.length && (
    documents.map((item)=>{
      if (item =="HighSchool"){
        student_documents.push({ title:"High School",link:documentLinks?.HighSchool})
      } else if (item == "School"){
        student_documents.push({ title: "School", link: documentLinks?.HighSchool})
      }
      else if (item == "Under Graduate") {
        student_documents.push({ title: "Under Graduate", link: documentLinks?.under_graduate})
      }
      else if (item == "Other") {
        student_documents.push({ title: "Other", link: documentLinks?.other})
      }
    })
    )}
    let data = { documents:student_documents, overview, score, selected_Level, to, user, message: body};
    setLoading(true);
    let allEmails = [...new Set(_.compact(values?.college_emails))];
    let formData = {
      to: allEmails,
      selectedApplication,
      body: data,
      subject: values.subject,
    };

    await dispatch(mailToCollege(formData));
    form.resetFields();

    setLoading(false);
    setIsMailModal(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  const toggle = () => {
    setIsMailModal(false);
  };

  console.log(collegeEmails);

  return (
    <>
      <Modal isOpen={isMailModal} toggle={toggle} className="popup-modal">
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">Send Mail</div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="subject"
              className="form-control-label"
              label="Subject"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Subject field cannot be empty",
                },
              ]}
            >
              <Input placeholder="Subject" />
            </Form.Item>

            <Form.Item
              name="college_emails"
              className="form-control-label"
              label="Select college"
              rules={[
                {
                  type: "array",
                  required: true,
                  message: "Body field cannot be empty",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                options={collegeEmails}
                placeholder="Select Colleges"
              />
            </Form.Item>
            <Form.Item
              place="Compose a mail"
              className="form-control-label"
              name="body"
              label="Message"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Body field cannot be empty",
                },
              ]}
            >
              <TextArea rows={4} placeholder="Compose email here" />
            </Form.Item>
            {documents && documents.length > 0  && (

            <Form.Item name="documents"
              className="form-control-label"
              label="Documents">
             
              <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
            </Form.Item>
            )}
          </Form>
        </ModalBody>
        <ModalFooter labelAlign="left">
          <Button loading={loading} type="primary" onClick={onSubmit}>
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SendMailModal;
