import { CoursesService } from "../api/courses_api";
import { message } from "antd";

const coursesService = new CoursesService();

export const addProgram = (program) => async (dispatch) => {
  const response = await coursesService.addProgram(program);

  if (response.isSuccess) {
    message.success("Program added");
    return true;
  } else if (!response.isSuccess) {
    message.error("Program failed to add");
    return false;
  }
};
export const addCollegeCourse = (course) => async (dispatch) => {
  const response = await coursesService.addCollegeCourse(course);
  if (response.isSuccess) {
    message.success("Program added");
    return true;
  } else if (!response.isSuccess) {
    message.error("Failed to add the program");
    return false;
  }
};

export const updateCollegeCourse = (course) => async (dispatch) => {
  const response = await coursesService.editCollegeCourse(course);

  if (response.isSuccess) {
    message.success("Program updated");
    return true;
  } else if (!response.isSuccess) {
    message.error("Failed to update");
    return false;
  }
};

export const deleteCollegeCourse = (course_id) => async (dispatch) => {
  const response = await coursesService.deleteCollegeCourse(course_id);

  if (response.isSuccess) {
    message.success("Program deleted");
    return true;
  } else if (!response.isSuccess) {
    message.error("Failed to delete the program");
    return false;
  }
};

export const getCourses = () => async (dispatch) => {
  const response = await coursesService.getPrograms();
  if (response.isSuccess) {
    return response.data;
  } else if (!response.isSuccess) {
    return [];
  }
};

export const getLevels = () => async (dispatch) => {
  const response = await coursesService.getAllLevels();
  console.log('responseLevel',response);
  if (response.isSuccess) {
    // console.log("maked",makeData(response.data))
    return response.data;
  } else if (!response.isSuccess) {
    return [];
  }
};

export const getCollegeCourses = (college_id) => async (dispatch) => {
  const response = await coursesService.getCollegeCourses(college_id);

  if (response.isSuccess) {
    return response.data;
  } else if (!response.isSuccess) {
    return [];
  }
};

export const getStreams = (course_level) => async (dispatch) => {
  const response = await coursesService.getAllStreams(course_level);

  if (response.isSuccess) {
    return response.data;
  } else if (!response.isSuccess) {
    return [];
  }
};
export const getProgram = (id) => async (dispatch) => {
  const response = await coursesService.getProgram(id);

  if (response.isSuccess) {
    return response.data;
  } else if (!response.isSuccess) {
    return null;
  }
};

export const getAllPrograms =
  (course_level, course_stream) => async (dispatch) => {
    const response = await coursesService.getAllPrograms(
      course_level,
      course_stream
    );

    if (response.isSuccess) {
      return response.data;
    } else if (!response.isSuccess) {
      return [];
    }
  };

export const updateProgram = (program) => async (dispatch) => {
  const response = await coursesService.editProgram(program);
  if (response.isSuccess) {
    message.success("Program updated ");
    return true;
  } else if (!response.isSuccess) {
    message.error("Failed to update program");
    return false;
  }
};

export const removeProgram = (id) => async (dispatch) => {
  const response = await coursesService.deleteProgram(id);
  if (response.isSuccess) {
    message.success("Program deleted");
    return true;
  } else if (!response.isSuccess) {
    message.error("Failed to delete program");
    return false;
  }
};
