import React from "react";
import { Table } from "antd";

const ChoiseDetail = ({ selectedApplication }) => {
  const { selectedChoice } = selectedApplication || {};
  // console.log(selectedChoice);

  const getCollegeNameWithImage = () => ({
    render: (selectedChoice) => {
      return (
        <>
          <div style={{ display: "flex" }}>
            <div style={{ width: "60px", minWidth: "60px", height: "60px" }}>
              <img
                alt={selectedChoice.name}
                src={selectedChoice.image}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>

            <div style={{ marginLeft: "5px" }}>
              <div className="mb-0 ">{selectedChoice?.collegeName}</div>
              <div className="mb-0 " style={{ fontSize: "12px" }}>
                {selectedChoice?.collegeEmail}
              </div>
              <div className="mb-0 " style={{ fontSize: "12px" }}>
                {selectedChoice?.address}
              </div>
            </div>
          </div>
        </>
      );
    },
  });

  const columns = [
    {
      title: "College Name",
      dataIndex: "",
      width: "50%",
      ...getCollegeNameWithImage(""),
    },
    {
      title: "Program",
      dataIndex: "collegeProgram",
    },
    {
      title: "Stream",
      dataIndex: "collegeStream",
    },
  ];
  return (
    <div style={{ marginTop: "35px" }}>
      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          paddingBottom: "5px",
          color: "#333333",
          marginBottom: "20px",
        }}
      >
        <b>Selected College</b>
      </div>

      <Table
        dataSource={selectedChoice}
        columns={columns}
        pagination={"false"}
        rowKey={(index) => {
          return index?.id;
        }}
      />
    </div>
  );
};

export default ChoiseDetail;
