import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";
import { Form, Button, Input } from "antd";
import MultiImageUploader from "components/ImageUploader/MultiImageUploader";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { editCollege } from "redux/actions/college_actions";

const Medias = ({}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college.college);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setLoading(true);
    let _college = {};
    _college.id = college._id;
    _college.college_photos = values.college_photos;
    _college.video_360 = values.video_360;
    _college.college_videos = [values.video_1, values.video_2];
    _college.banner = values.banner;
    await dispatch(editCollege(_college, college.college_slug));
    setLoading(false);
  };

  useEffect(() => {
    if (college) {
      form.setFieldsValue({
        video_360: college?.video_360,
        video_1: (college?.college_videos && college?.college_videos[0]) || "",
        video_2: (college?.college_videos && college?.college_videos[1]) || "",
        college_photos: college?.college_photos,
        banner: college?.banner,
      });
    }
  }, [college, form]);

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  return (
    <>
      <Card className="bg-secondary shadow" style={{ height: "100%" }}>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Medias</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    label="College Banner"
                    className="form-control-label"
                    name="banner"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Youtube Link required",
                      },
                    ]}
                  >
                    <ImageUploader
                      form={form}
                      formField="banner"
                      folder="college-banner"
                      url={college?.banner}
                    />
                  </Form.Item>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <Form.Item
                    label="360 Video Youtube Link"
                    className="form-control-label"
                    name="video_360"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Youtube Link required",
                      },
                    ]}
                  >
                    <Input placeholder="Youtube link..." />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    label="Video 1"
                    className="form-control-label"
                    name="video_1"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Youtube video id is required",
                      },
                    ]}
                  >
                    <Input placeholder="Youtube link..." />
                  </Form.Item>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Form.Item
                    label="Video 2"
                    className="form-control-label"
                    name="video_2"
                    rules={[
                      {
                        type: "string",
                        // required: true,
                        message: "Youtube video id is required",
                      },
                    ]}
                  >
                    <Input placeholder="Youtube link..." />
                  </Form.Item>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div className="form-btn-container">
            <Button loading={loading} type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Medias;
