import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getTieUp,
  addTieUp,
  updateTieUp,
  getAllTieUp,
} from "../../redux/actions/tieup_action";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { Form, Input, Button } from "antd";

import ImageUploader from "components/ImageUploader/ImageUploader";

const AddEditTieupModal = (props) => {
  const {
    isAddEditModalOpen,
    handleAddEditModal,
    testimonialId,
    fetchTieUp,
    setTestimonialId,
  } = props;
  const [imageUrl, setImageUrl] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { TextArea } = Input;

  React.useEffect(() => {
    (async () => {
      if (testimonialId) {
        const fetchOneTestimonial = await dispatch(
           getTieUp(testimonialId)
        );

        setImageUrl(fetchOneTestimonial.image_url);
        form.setFieldsValue({
          ...fetchOneTestimonial,
        });
      } else {
        setImageUrl("");
        form.resetFields();
      }
    })();
  }, [testimonialId]);

  const onFinish = async (values) => {
    if (testimonialId) {
      setLoader(true);
     await dispatch(updateTieUp(testimonialId, values));
      setLoader(false);
      handleAddEditModal(false);
      setTestimonialId("");
      await fetchTieUp();
    } else {
      setLoader(true);
      await dispatch(addTieUp(values));
      setLoader(false);
      handleAddEditModal(false);
      await fetchTieUp();
    }
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("failed", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };

  const toggle = () => {
    handleAddEditModal(false);
  };
  return (
    <>
      <Modal
        isOpen={isAddEditModalOpen}
        toggle={toggle}
        className="popup-modal"
      >
        <ModalHeader toggle={toggle} className="modal--header">
          <div className="modal--title">
            {testimonialId ? "Edit TieUp" : "Add TieUp"}{" "}
          </div>
        </ModalHeader>
        <ModalBody>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col lg="6" xs="12">
                <Form.Item
                  label="College Name"
                  className="form-control-label"
                  name="name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "College name cannot be empty",
                    },
                  ]}
                >
                  <Input placeholder="College Name..." />
                </Form.Item>
              </Col>

            

              <Col lg="6" xs="12">
                <Form.Item
                  className="form-control-label"
                  label="Image"
                  name="image_url"
                  rules={[
                    {
                      required: true,
                      message: "Image field cannot be empty",
                    },
                  ]}
                >
                  <ImageUploader
                    form={form}
                    formField="image_url"
                    folder="tieup"
                    name="image_url"
                    url={imageUrl ? imageUrl : null}
                  />
                </Form.Item>
              </Col>
              <Col lg="12" xs="12">
                <Form.Item
                  label="College Url"
                  className="form-control-label"
                  name="college_url"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "College url cannot be empty",
                    },
                  ]}
                >
                  <Input placeholder="College Url..." />
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="primary" onClick={onSubmit} loading={loader}>
            {testimonialId ? (loader ? "Updating" : "Update") : "Add"}
          </Button>
          <Button
            type="danger"
            onClick={() => {
              toggle(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddEditTieupModal;