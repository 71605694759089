import { GET_COLLEGE_BOARD_TYPES } from "../types";
import { CollegeBoardService } from "../api/collegeboard_api";

const collegeBoardService = new CollegeBoardService();

export const addCollegeBoard = (data) => async (dispatch) => {
  const res = await collegeBoardService.addCollegeBoard(data);

  if (res.status === 200) {
    return "successfully added";
  } else {
    return res.data;
  }
};

export const getCollegeBoard = () => async (dispatch) => {
  dispatch({
    type: GET_COLLEGE_BOARD_TYPES.COLLEGE_BOARD_BEGIN,
  });

  const res = await collegeBoardService.getCollegeBoard();

  if (res) {
    dispatch({
      type: GET_COLLEGE_BOARD_TYPES.GET_COLLEGE_BOARD,
      payload: res.data,
    });
  } else {
    return res.data;
  }
};

export const deleteCollegeBoard = (id) => async (dispatch) => {
  dispatch({
    type: GET_COLLEGE_BOARD_TYPES.COLLEGE_BOARD_BEGIN,
  });
  const res = await collegeBoardService.deleteCollegeBoard(id);
  if (res.status === 200) {
    return "deleted succesfully";
  } else {
    return res.data;
  }
};
